import moment from 'moment';

export default {
  methods: {
    /**
     * Manually encode a Moment to a encoded URL string. Expects the Moment to be UTC.
     * @param {moment} timestamp 
     * @returns {string} URL encoded string
     */
    encodeDate(timestamp) {
      return moment.utc(timestamp).toISOString().replace(/:/gi, '%3A');
    }
  }
}