<script>
import { Bar, Line } from "vue-chartjs";
import moment from "moment";
// import Chart from "chart.js";
// import ChartDataLabels from "chartjs-plugin-datalabels";

var _canvasContext = null;

export default {
    extends: Bar,
    props: {
      labels: {
        type: Array,
        required: true
      },
      compliance: {
        /*
        {
          label: string
          values: number[]
        }
         */
        type: Object,
        required: true
      },
      /* #region Y-akse beregninger */
      min: {
        type: Number,
        default: 0
      },
      max: {
        type: Number,
        default: 100
      },
      /* Hvis disse er sat til 0 bliver min og max brugt uden beregning */
      calcmin: {
        type: Number,
        default: -10
      },
      calcmax: {
        type: Number,
        default: 10
      },      
      /* #endregion Y-akse beregninger */
      xs: {
        type: Number,
        default: 4
      },
      s: {
        type: Number,
        default: 6
      },
      md: {
        type: Number,
        default: 8
      },
      lg: {
        type: Number,
        default: 12
      },
      xl: {
        type: Number,
        default: 18
      },
      maximum: {
        type: Number,
        default: 24
      },
      xslabels: {
        type: Array,
        default: () => [ '00:00-06:00', '06:00-12:00','12:00-18:00','18:00-24:00' ]
      },
      slabels: {
        type: Array,
        default: ()=>[ '00:00-04:00', '04:00-08:00','08:00-12:00','12:00-16:00','16:00-20:00','20:00-24:00' ]
      },
      mdlabels: {
        type: Array,
        default: ()=> [ '00:00-03:00', '03:00-06:00', '06:00-09:00', '09:00-12:00', '12:00-15:00','15:00-18:00','18:00-21:00','21:00-24:00' ]
      },
      lglabels: {
        type: Array,
        default: ()=>[ '00:00-02:00', '02:00-04:00', '04:00-06:00', '06:00-08:00', '08:00-10:00','10:00-12:00','12:00-14:00','14:00-16:00','16:00-18:00','18:00-20:00','20:00-22:00','22:00-24:00' ]
      },
      xllabels: {
        type: Array,
        default: ()=>[ '00:00-01:00', '01:00-02:00', '02:00-03:00', '03:00-04:00', '04:00-05:00','05:00-06:00','06:00-07:00','07:00-08:00','08:00-09:00','09:00-10:00','10:00-11:00','11:00-12:00','12:00-13:00', '13:00-14:00', '14:00-15:00', '15:00-16:00', '16:00-17:00','17:00-18:00','18:00-19:00','19:00-20:00','20:00-21:00','21:00-22:00','22:00-23:00','23:00-24:00' ]
      },
      maximumlabels: {
        type: Array,
        default: ()=>[ '00:00-01:00', '01:00-02:00', '02:00-03:00', '03:00-04:00', '04:00-05:00','05:00-06:00','06:00-07:00','07:00-08:00','08:00-09:00','09:00-10:00','10:00-11:00','11:00-12:00','12:00-13:00', '13:00-14:00', '14:00-15:00', '15:00-16:00', '16:00-17:00','17:00-18:00','18:00-19:00','19:00-20:00','20:00-21:00','21:00-22:00','22:00-23:00','23:00-24:00' ]
      },
    },
    mounted() {
        this.addPlugin({
          id: "centertext-plugin",
          beforeDraw: this.centertext
        });
        // this.renderChart(getChartData(this.labels, this.compliance), getChartOptions());

        setTimeout(() =>{
          let chartData = getChartData(this.labels, this.compliance, this);
          let options = getChartOptions(chartData, this);
          this.renderChart(chartData, options);
        }, 0);
    },
    methods: {
      centertext(chart) {
        _canvasContext = chart.chart.ctx;
      }
    }
}

function getChartData(labels, compliance, caller) {
  let min = 100;
  let max = 0;  

  console.log(_canvasContext);
  // console.log(compliance.readings)

  let readingLabels = [];
  let readingDays = [];

  let date =null;

  compliance.values.forEach(element => {
    date = moment(element.complianceDate).format("DD-MM-YYYY") //element.complianceDate.split('T')[0]

    if (readingLabels.indexOf(date)<0) {
      readingLabels.push(date);
      readingDays.push([])
    } 
    readingDays[readingDays.length-1].push(element.value);
  });

  console.log(readingDays);
  var dataSets = [];
  var setLabels = caller.maximumlabels;
  var dataSetCount = caller.maximum;
  var barDataSet = [];

  //#region set number of sets
  if (readingDays.length>5) {
    dataSetCount = caller.xl;
    setLabels = caller.xllabels;
  } 
  if (readingDays.length>8) {
    dataSetCount = caller.lg;
    setLabels = caller.lglabels;
  } 
  if (readingDays.length>10) {
    dataSetCount = caller.md;
    setLabels = caller.mdlabels;
  } 
  if (readingDays.length>12) {
    dataSetCount = caller.s;
    setLabels = caller.slabels;
  } 
  if (readingDays.length>15) {
    dataSetCount = caller.xs;
    setLabels = caller.xslabels;
  } 
  //#endregion

  for (let index = 0; index < dataSetCount; index++) {
    barDataSet.push([]);    
  }

  let count = 0;
  readingDays.forEach(readingDay => {
    count = 0;
    for (let index = 0; index < dataSetCount; index++) {
      let total = 0;
      for (let i2 = 0; i2 < 24/dataSetCount; i2++) {
        total += readingDay[(index+1)*i2]
      }
      let value = Math.round(total/(24/dataSetCount));
      if (value < min) {
        min = value;
      }
      if (value > max) {
        max = value;
      }
      barDataSet[index].push(value);
      // barDataSet[index].push(Math.round(total/24/dataSetCount));
    }    
  });

  barDataSet.forEach(data => {
    dataSets.push({
      id: 'compliance',
      label: setLabels[count],
      data: data,
      pointRadius: 0,
      cubicInterpolationMode: 'monotone',
      bezierCurve: false,
      fill: true,
      tension: 0,
      // backgroundColor:'rgba(0,141,117,0.4)',
      // borderColor: 'rgba(0,141,117,0.2)',
      backgroundColor:'rgb(59, 119, 182)',
      borderColor: 'rgba(0,141,117,0.2)',
      borderWidth: 0
    });
    count++;
  });

  return{
    labels: readingLabels.map(x => getDateString(x)),
    type: 'bar',
    datasets: dataSets,
    //borderWidth: 0,
    min:min, 
    max:max
  }
}

function getChartOptions(chartData, caller) {
  let minAxis = caller.min;
  let maxAxis = caller.max;
  if (caller.calcmin != 0) {
    if ((chartData.min+caller.calcmin)>caller.min) {
      minAxis = (chartData.min+caller.calcmin);
    }
  }
  if (caller.calcmax != 0) {
    if ((chartData.max+caller.calcmax)<caller.max) {
      maxAxis = (chartData.max+caller.calcmax);
    }
  }

  return {
    legend: {
      display: false,
    },
    // layout: {
    //   padding: {
    //     left: -10,
    //     top: -10,
    //     right: -10
    //   }
    // },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: "#FFFFFF",
      },
    },
    scales: {
         yAxes: [{
           min:0,

                gridLines: {
            display: true
          },
          ticks: {
           min: minAxis,           
           max: maxAxis,
           //padding: -9,
          }
        }],
        // xAxes: [{
        //   ticks: {
        //    padding: -9,
        //   }
        // }],

      y:{
        min:0,
        max:100
      },
    }
  }
}

function getDateString(date) {
  return `${date}`
  // return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
}

</script>