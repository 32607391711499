<template>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="mt-n1"
        @click.native.prevent
        fab
        x-small
        :color="fleetFilter.length ? 'secondary' : 'transparent'"
        elevation="0"
      >
        <v-icon>
          mdi-filter-variant
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar class="mb-4" color="secondary" flat dark>
        <v-toolbar-title>
          <h2>Fleet filter</h2>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn @click="dialog = false" icon>
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-form>
          <v-row>
            <v-col>
              <v-slide-x-transition class="d-flex justify-space-around" group>
                <v-card class="pa-3 flex-grow-1" key="total">
                  <v-card-text class="text-center">
                    <h3 class="mb-2">
                      Total ships
                    </h3>
                    <v-progress-circular v-if="!facilities.length" size="25" indeterminate></v-progress-circular>
                    <h1 v-else>
                      {{ facilities.length }}
                    </h1>
                  </v-card-text>
                </v-card>
                <template v-if="$store.getters.hasAnyRole('SystemAdmin') && !filterIsEmpty && filter.facilities.options.length">
                  <v-icon class="mx-4" key="toFilter">
                    mdi-arrow-right
                  </v-icon>
                  <v-card class="pa-3 flex-grow-1" key="afterFilter">
                    <v-card-text class="text-center">
                      <h3 class="mb-2">
                        After applying filter
                      </h3>
                      <h1>
                        {{ filter.facilities.options.length }}
                      </h1>
                    </v-card-text>
                  </v-card>
                </template>
                <template v-if="filter.facilities.options.length && filter.facilities.selected.length">
                  <v-icon class="mx-4" key="toSelectedFacilities">
                    mdi-arrow-right
                  </v-icon>
                  <v-card class="pa-3 flex-grow-1" key="afterSelectedFacilities">
                    <v-card-text class="text-center">
                      <h3 class="mb-2">
                        After ship selection
                      </h3>
                      <h1>
                        {{ remainingFacilities.length }}
                      </h1>
                    </v-card-text>
                  </v-card>
                </template>
              </v-slide-x-transition>
            </v-col>
          </v-row>

          <v-row v-if="$store.getters.hasAnyRole('SystemAdmin')">
            <v-col>
              <h2 class="mb-2">
                Organization
              </h2>
              <v-autocomplete
                v-model="filter.organizations.selected"
                :items.sync="filter.organizations.options"
                :label="`${filter.organizations.options.length} possibilities`"
                placeholder="Search for organization"
                @input="filter.organizations.search = null; filterFacilities('organizations')"
                :search-input.sync="filter.organizations.search"
                no-data-text="No filter options available"
                multiple
                hide-details
                chips
                deletable-chips
                clearable
                solo
              >
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row v-if="$store.getters.hasAnyRole('SystemAdmin')">
            <v-col>
              <h2>
                Scrubber system type
              </h2>
              <v-chip-group
                v-model="filter.scrubberSystemTypes.selected"
                @change="filterFacilities('scrubberSystemTypes')"
                column
                multiple
              >
                <v-chip
                  v-for="option in filter.scrubberSystemTypes.options"
                  :key="option"
                  :value="option"
                  filter
                >
                  {{ option }}
                </v-chip>
              </v-chip-group>

              <span v-if="!filter.scrubberSystemTypes.options.length">
                No filter options available
              </span>

              <div v-if="!filter.scrubberSystemTypes.options.length" class="d-flex">
                <v-skeleton-loader v-for="n in 2" :key="n" class="mr-2" type="chip"></v-skeleton-loader>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="$store.getters.hasAnyRole('SystemAdmin')">
            <v-col>
              <h2>
                Number of scrubbers
              </h2>
              <v-chip-group
                v-model="filter.numberOfScrubbers.selected"
                @change="filterFacilities('numberOfScrubbers')"
                column
                multiple
              >
                <v-chip
                  v-for="option in filter.numberOfScrubbers.options"
                  :key="option"
                  :value="option"
                  filter
                >
                  {{ option }}
                </v-chip>
              </v-chip-group>

              <span v-if="!filter.numberOfScrubbers.options.length">
                No filter options available
              </span>

              <div v-if="!filter.numberOfScrubbers.options.length" class="d-flex">
                <v-skeleton-loader v-for="n in 4" :key="n" class="mr-2" type="chip"></v-skeleton-loader>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="$store.getters.hasAnyRole('SystemAdmin')">
            <v-col>
              <h2>
                System manufacturer
              </h2>
              <v-chip-group
                v-model="filter.systemManufacturers.selected"
                @change="filterFacilities('systemManufacturers')"
                column
                multiple
              >
                <v-chip
                  v-for="option in filter.systemManufacturers.options"
                  :key="option"
                  :value="option"
                  filter
                >
                  {{ option }}
                </v-chip>

              </v-chip-group>

              <span v-if="!filter.systemManufacturers.options.length">
                No filter options available
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div class="mb-2 d-flex align-center">
                <h2 class="mr-auto">
                  Ship
                </h2>

                <v-btn-toggle
                  v-if="$store.getters.hasAnyRole('SystemAdmin')"
                  v-model="filter.facilities.mode"
                  color="primary"
                  dense
                  mandatory
                  @change="filter.facilities.selected.length ? filterFacilities('facilities') : () => undefined"
                >
                  <v-btn value="include" small>
                    Include
                  </v-btn>
                  <v-btn value="exclude" small>
                    Exclude
                  </v-btn>
                </v-btn-toggle>
              </div>

              <v-autocomplete
                v-model="filter.facilities.selected"
                :items.sync="filter.facilities.options"
                :label="`${filter.facilities.options.length} possibilities`"
                placeholder="Search for ships"
                @input="filter.facilities.search = null; filterFacilities('facilities')"
                :search-input.sync="filter.facilities.search"
                hint="Select specific ships to further refine your fleet."
                no-data-text="No filter options available"
                persistent-hint
                multiple
                chips
                deletable-chips
                clearable
                solo
              >
                <template v-if="!$store.getters.hasAnyRole('SystemAdmin')" v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="toggleAllFacilities"
                  >
                    <v-list-item-action>
                      <v-icon :color="filter.facilities.selected.length > 0 ? 'primary' : ''">
                        {{ facilitiesSelectAllIcon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select all
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="clearFilter" text>
          <v-icon left>
            mdi-filter-variant-remove
          </v-icon>
          Clear
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn @click="dialog = false" text>
          Close
        </v-btn>

        <v-btn :disabled="!remainingFacilities.length" @click="applyFilter" color="primary">
          <v-icon left>
            mdi-filter-variant
          </v-icon>
          Apply ({{ this.remainingFacilities.length }} ships)
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'FleetFilter',
  data () {
    return {
      dialog: false,
      applying: false,
      filter: this.initFilter(),
      remainingFacilities: []
    }
  },
  computed: {
    ...mapState([ 'facilities', 'fleetFilter' ]),
    ...mapGetters([ 'hasAnyRole' ]),
    filterIsEmpty () {
      return Object.keys(this.filter).every(key => this.filter[key].selected.length === 0);
    },
    allFacilitiesSelected () {
      return this.filter.facilities.selected.length === this.filter.facilities.options.length;
    },
    someFacilitiesSelected () {
      return this.filter.facilities.selected.length > 0 && !this.allFacilitiesSelected;
    },
    facilitiesSelectAllIcon () {
      if (this.allFacilitiesSelected) {
        return 'mdi-close-box';
      }

      if (this.someFacilitiesSelected) {
        return 'mdi-minus-box';
      }

      return 'mdi-checkbox-blank-outline'
    }
  },
  methods: {
    /**
     * Called when user makes changes to any filter.
     * When a filter changes, the remaining facilities are updated and based on the result, the remaining options on all other filters than the one that changed are updated.
     * @param {string} filterChanged - Name of the filter that changed.
     */
    filterFacilities (filterChanged) {
      let result = this.facilities;

      const { organizations, facilities, scrubberSystemTypes, numberOfScrubbers, systemManufacturers } = this.filter;

      if (!this.filterIsEmpty) {
        if (organizations.selected.length) {
          result = result.filter(facility => organizations.selected.some(value => value === facility.primaryOrganizationId));
        }
  
        if (scrubberSystemTypes.selected.length) {
          result = result.filter(facility => scrubberSystemTypes.selected.some(value => value === facility.scrubberSystemType));
        }
  
        if (numberOfScrubbers.selected.length) {
          result = result.filter(facility => numberOfScrubbers.selected.some(value => value === facility.numberOfScrubbers));
        }
  
        if (systemManufacturers.selected.length) {
          result = result.filter(facility => systemManufacturers.selected.some(value => value === facility.systemManufacturer));
        }
      }

      // Options for facilities need to be updated BEFORE filtering any selected facilities.
      const mappedFacilities = result.map(facility => {
        return {
          value: facility.id,
          text: facility.name
        }
      });

      facilities.options = [ ...new Set(mappedFacilities) ];

      // Then apply the filter to facilities

      const selectedFacilities = facilities.selected;

      if (selectedFacilities.length) {
        result = result.filter(facility => {
          if (facilities.mode === 'include') {
            return selectedFacilities.some(value => value === facility.id)
          }

          else {
            return !selectedFacilities.some(value => value === facility.id)
          }
        });
      }

      // After remaining facilities have been updated, update options for all filters except the one that changed.

      if (filterChanged !== 'organizations') {
          const orgs = this.facilities.map(facility => {
          return {
            value: facility.primaryOrganizationId,
            text: facility.primaryOrganizationName
          }
        });

        orgs.sort((a, b) => {
          return String(a.text).localeCompare(b.text);
        });

        this.filter.organizations.options = [ ...new DeepSet(orgs) ];
      }

      if (filterChanged !== 'scrubberSystemTypes') {
        const types = result.map(facility => facility.scrubberSystemType).filter(type => type);
        this.filter.scrubberSystemTypes.options = [ ...new Set(types) ];
      }

      if (filterChanged !== 'numberOfScrubbers') {
        const numbers = result.map(facility => facility.numberOfScrubbers).sort();
        this.filter.numberOfScrubbers.options = [ ...new Set(numbers) ];
      }

      if (filterChanged !== 'systemManufacturers') {
        const manufacturers = result.map(facility => facility.systemManufacturer).filter(type => type);
        this.filter.systemManufacturers.options = [ ...new Set(manufacturers) ];
      }

      this.remainingFacilities = result;
    },
    async applyFilter () {
      this.applying = true;

      let filterSettings = {};

      Object.keys(this.filter).forEach(option => {
        if (this.filter[option].selected.length) {
          filterSettings[option] = {};

          filterSettings[option].selected = this.filter[option].selected;

          if (this.filter[option].mode) {
            filterSettings[option].mode = this.filter[option].mode;
          }
        }
      });

      this.$store.dispatch('saveFleetFilter', filterSettings);
      this.$store.commit('setFilteredFacilities', this.remainingFacilities);

      // If the current route is a facility that has just been filtered out, redirect the user to Fleet
      if (this.$route.name === 'Ship' && !this.remainingFacilities.map(x => x.id).includes(this.$route.params.id)) {
        this.$router.push('/');
      }

      this.dialog = false;
      this.applying = false;
    },
    initFilter () {
      return {
        organizations: {
          search: null,
          selected: [],
          options: []
        },
        facilities: {
          search: null,
          mode: 'include',
          selected: [],
          options: []
        },
        scrubberSystemTypes: {
          selected: [],
          options: []
        },
        numberOfScrubbers: {
          selected: [],
          options: []
        },
        systemManufacturers: {
          selected: [],
          options: []
        }
      };
    },
    clearFilter () {
      this.filter = this.initFilter();
      this.filterFacilities();
    },
    toggleAllFacilities () {
      if (this.filter.facilities.selected.length === this.filter.facilities.options.length) {
        this.filter.facilities.selected = [];
      }

      else {
        this.filter.facilities.selected = this.filter.facilities.options.map(x => x.value);
      }
    }
  },
  watch: {
    facilities: {
      handler: function () {
        this.filterFacilities();
        this.$store.commit('setFilteredFacilities', this.remainingFacilities);
      },
      immediate: false
    },
    filterIsEmpty (value) {
      if (value) {
        this.filterFacilities();
      }
    }
  },
  created () {
    this.initFilter();

    const savedFilter = JSON.parse(localStorage.getItem('pt-fleetFilter'));

    if (savedFilter) {
      Object.keys(savedFilter).forEach(option => {
        this.filter[option].selected = savedFilter[option].selected;
        this.filter[option].mode = savedFilter[option].mode
      });

      this.$store.commit('setFleetFilter', savedFilter);
    }
  }
}

/**
 * Makes it possible to make unique Sets of objects
 */
class DeepSet extends Set {
  add (o) {
    for (let i of this)
      if (this.deepCompare(o, i))
        return this;
    super.add.call(this, o);
    return this;
  }

  deepCompare(o, i) {
    return JSON.stringify(o) === JSON.stringify(i)
  }
}
</script>