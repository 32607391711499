export default class UserService {
  baseUrl = 'users';

  constructor(api, store) {
    this._api = api;
    this._store = store;
  }

  async getById(userId) {
    // const response = await this._api(this.baseUrl);
    const response = {
      data: {
        id: 1,
        name: 'Bergen',
        linkKey: '12345678',
        linkedOrganizations: [
          {
            id: 2,
            name: 'BW',
            facilityIds: ['foo']
          }
        ]
      }
    };

    return response.data;
  }

  async create(organizationId, data) {
    const response = await this._api.post(`organizations/${organizationId}/users`, data);

    return response.data;
  }

  async update(userId, data) {
    const response = await this._api.put(`${this.baseUrl}/${userId}`, data);

    return response.data;
  }

  async remove(userId) {
    const response = await this._api.delete(`${this.baseUrl}/${userId}`);

    return response.data;
  }

  async changePassword(userId, currentPassword, newPassword) {
    const data = { currentPassword, newPassword };
    const response = await this._api.patch(`${this.baseUrl}/${userId}/change-password`, data);

    return response;
  }

  logVisit(userId) {
    this._api.put(`${this.baseUrl}/${userId}/visit`, { section: "FacilityDocuments" });
  }
}
