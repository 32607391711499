import ApiUrlFactory from './ApiUrlFactory';

export default class FacilityService {
    constructor(api, store) {
        this._api = api;
        this._store = store;
    }

    async create(data) {
        const response = await this._api.post('facilities', data);
        return response.data;
    }

    async getFacilities(organizationId, asAdmin = false) {
        const userId = this._store.getters.currentUserId;
        const response = await this._api(`facilities?asAdmin=${asAdmin}${organizationId ? `&organizationId=${organizationId}` : ''}&userId=${userId}`);

        const facilities = response.data.facilities.sort((a, b) => {
            return String(a.name).localeCompare(b.name);
        });

        return facilities;
    }

    async getAllByOrganizationId(organizationId) {
        const response = {
            data: [
                {
                    id: '1',
                    name: 'Tai Shan',
                    imo: 12345
                },
                {
                    id: '2',
                    name: 'Mulhacen',
                    imo: 64173
                },
                {
                    id: '3',
                    name: 'Accomplisher',
                    imo: 85671
                }
            ]
        }

        return response.data;
    }

    async getFacility(facilityId) {
        const response = await this._api(ApiUrlFactory.getFacilityUrl(facilityId));
        return response.data;
    }

    async downloadFacilityDocument(data) {
        // Set responseType as 'blob' to make file download properly.
        const response = await this._api.post(`/facilities/documents/download`, data, { responseType: 'blob' });
        return response.data;
    }

    async updateFacility(facilityId, data) {
        const response = await this._api.put(ApiUrlFactory.getFacilityUrl(facilityId), data);
        return response.data;
    }

    async getDataSources(facilityId) {
        const response = await this._api(`algorithm/facility/${facilityId}/datasource`);
        return response.data.list;
    }

    async createDataSource(facilityId, data) {
        const response = await this._api.post(`algorithm/facility/${facilityId}/datasource`, data);
        return response.data;
    }

    async updateDataSource(facilityId, dataSourceId, data) {
        const response = await this._api.put(`algorithm/facility/${facilityId}/datasource/${dataSourceId}`, data);
        return response.data;
    }

    async deleteDataSource(facilityId, dataSourceId) {
        const response = await this._api.delete(`algorithm/facility/${facilityId}/datasource/${dataSourceId}`);
        return response.data;
    }

    async deleteSampleFile(facilityId, dataSourceId) {
        const response = await this._api.delete(`algorithm/facility/${facilityId}/datasource/${dataSourceId}/samplefileandsamplechannels`);
        return response.data;
    }

    async getDataFiles(dataSourceId) {
        const response = await this._api(`algorithm/facilitydatasource/${dataSourceId}/file`);
        return response.data.list;
    }

    async uploadDataFiles(dataSourceId, file) {
        let formData = new FormData();
        const headers = {
            'Content-Type': 'multipart/form-data'
        };

        // for (const file of files) {
        //     formData.append('files', file, file.name);
        // }

        formData.append('file', file, file.name);

        const response = await this._api.post(`algorithm/facilitydatasource/${dataSourceId}/file`, formData, headers);
        return response.data;
    }

    /**
     * Delete a data source file.
     * @param {string} dataSourceId
     * @returns {*} Response data
     */
    async deleteDataFile(dataSourceId, fileId) {
        const response = await this._api.delete(`algorithm/facilitydatasource/${dataSourceId}/file/${fileId}`);
        return response.data;
    }

    async uploadSampleFile(facilityId, dataSourceId, file) {
        let formData = new FormData();
        const headers = {
            'Content-Type': 'multipart/form-data'
        };

        formData.append('file', file, file.name);

        const response = await this._api.put(`algorithm/facility/${facilityId}/datasource/${dataSourceId}/SampleFile`, formData, headers);
        return response.data;
    }

    async importConfiguration(facilityId, sourceFacilityId) {
        const response = await this._api.put(`algorithm/facilities/${facilityId}/configurations`, { sourceFacilityId });
        return response.data;
    }
}
