export default class MessageService {
  baseUrl = 'messages';

  constructor(api, store) {
    this._api = api;
    this._store = store;
  }

  async get (isActive = true) {
    const response = await this._api(`${this.baseUrl}?isActive=${isActive}`);
    return response.data.messages;
  }

  async create (data) {
    const response = await this._api.post(this.baseUrl, data);

    return response.data;
  }

  async remove (messageId) {
    if (!messageId) {
      throw 'No message ID specified';
    }

    return await this._api.delete(`${this.baseUrl}/${messageId}`);
  }

  async markAsRead (messageId, userId) {
    return await this._api.post(`${this.baseUrl}/${messageId}/mark-as-read`);
  }
}
