<template>
  <v-dialog v-model="dialog" min-width="800" max-width="80vw" fullscreen>
    <template v-slot:activator="{ attrs, on }">
      <v-btn v-bind="attrs" v-on="on" color="primary">
        <v-icon left>
          mdi-file-import-outline
        </v-icon>
        Import configuration
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Import ship configuration
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <h1 class="mb-4">
              Import to
            </h1>

            <ShipInfo type="fleet" :facility="destFacility" />
          </v-col>
        </v-row>

        <v-row class="mb-6">
          <v-col class="text-center">
            <v-icon style="font-size: 100px">
              mdi-arrow-up-bold
            </v-icon>
          </v-col>
        </v-row>

        <v-select v-model="sourceFacility" :items="configuredFacilities" label="Select ship to import from" item-text="name" item-value="id" :dark="!sourceFacility" :background-color="!sourceFacility ? 'primary' : ''" solo return-object></v-select>

        <v-row v-if="sourceFacility">
          <v-col>
            <h1 class="mb-4">
              Import from
            </h1>

            <ShipInfo type="fleet" :facility="sourceFacility" />
          </v-col>
        </v-row>

        <v-row v-if="sourceFacility">
          <v-col>
            <v-card>
              <v-card-title>
                Data sources
              </v-card-title>

              <v-card-text>
                <v-list v-if="!loading && dataSources.length">
                  <v-list-item v-for="item of dataSources" :key="item.id">
                    <v-row>
                      <v-col cols="6">
                        {{ item.name }}
                      </v-col>

                      <v-col>
                        <v-chip class="ml-2" color="secondary" small label>
                          {{ item.dataSourceReaderName }}
                        </v-chip>
                      </v-col>

                      <v-col class="text-right">
                        {{ item.channels ? item.channels.length : 0 }} channels
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list>

                <v-skeleton-loader v-else type="list-item@3"></v-skeleton-loader>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col>
            <v-card>
              <v-card-title>
                {{ measurementFields.length }} measurement fields
              </v-card-title>

              <v-card-text class="d-flex align-center">
                <div class="d-flex align-center mr-8">
                  <v-icon color="success">
                    mdi-check-circle
                  </v-icon>

                  <span class="d-inline-block text-center ml-1" style="width: 30px;">
                    <v-progress-circular v-if="loading" size="16" width="2" indeterminate></v-progress-circular>
                    <span v-else>
                      {{ approvedMeasurementFields }}
                    </span>
                  </span>

                  <span>configured</span>
                </div>

                <div class="d-flex align-center">
                  <v-icon>
                    mdi-file-document-outline
                  </v-icon>

                  <span class="d-inline-block text-center ml-1" style="width: 30px;">
                    <v-progress-circular v-if="loading" size="16" width="2" indeterminate></v-progress-circular>
                    <span v-else>
                      {{ measurementFields.length - approvedMeasurementFields }}
                    </span>
                  </span>

                  <span>not configured</span>
                </div>
              </v-card-text>

              <v-card-title>
                {{ calculationCount }} calculations
              </v-card-title>

              <v-card-text class="d-flex align-center">
                <div class="d-flex align-center mr-8">
                  <v-icon color="success">
                    mdi-check-circle
                  </v-icon>

                  <span class="d-inline-block text-center ml-1" style="width: 30px;">
                    <v-progress-circular v-if="loading" size="16" width="2" indeterminate></v-progress-circular>
                    <span v-else>
                      {{ approvedCalculations }}
                    </span>
                  </span>

                  <span>approved</span>
                </div>

                <div class="d-flex align-center">
                  <v-icon>
                    mdi-file-document-outline
                  </v-icon>

                  <span class="d-inline-block text-center ml-1" style="width: 30px;">
                    <v-progress-circular v-if="loading" size="16" width="2" indeterminate></v-progress-circular>
                    <span v-else>
                      {{ calculationCount - approvedCalculations }}
                    </span>
                  </span>

                  <span>
                    not approved
                  </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn @click="close" text>
          Cancel
        </v-btn>

        <v-btn :disabled="!canBeImported" :loading="saving" color="primary" @click="importConfig">
          <v-icon left>
            mdi-file-import-outline
          </v-icon>

          Import
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ShipInfo from '@/components/ShipInfo.vue'

export default {
  name: 'ImportConfigDialog',
  components: {
    ShipInfo
  },
  data() {
    return {
      dialog: false,
      destFacility: this.$store.state.currentFacility,
      sourceFacility: null,
      dataSources: [],
      measurementFields: [],
      facilities: this.$store.state.facilities,
      loading: false,
      saving: false
    }
  },
  computed: {
    configuredFacilities() {
      return this.facilities.filter(item => item.dataSourceCount !== 0);
    },
    canBeImported() {
      return this.sourceFacility && this.dataSources.length && !this.loading;
    },
    approvedMeasurementFields() {
      return this.measurementFields.filter(item => item.approvedCalculations !== 0).length;
    },
    calculationCount() {
      return this.measurementFields.reduce((accumulator, currentValue) => accumulator + currentValue.calculations, 0);
    },
    approvedCalculations() {
      return this.measurementFields.reduce((accumulator, currentValue) => accumulator + currentValue.approvedCalculations, 0);
    }
  },
  methods: {
    close() {
      this.sourceFacility = null;
      this.dataSources = [];
      this.dialog = false;
    },
    importConfig: async function() {
      try {
        this.saving = true;

        await this.$Services.facilities.importConfiguration(this.destFacility.id, this.sourceFacility.id);

        this.close();
        this.$emit('import');
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.saving = false;
      }
    }
  },
  watch: {
    sourceFacility: async function(value) {
      if (!value) {
        return;
      }

      try {
        this.loading = true;

        this.measurementFields = await this.$Services.measurementFields.getAllForFacility(value.id);

        const sources = await this.$Services.facilities.getDataSources(value.id);

        // Add channels to each data source
        for await (const source of sources) {
          source.channels = await this.$Services.channels.getMapped(source.id);
        }

        this.dataSources = sources;
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>