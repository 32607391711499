import ApiUrlFactory from './ApiUrlFactory';

export default class ReportService {
  baseUrl = 'algorithm/reports';

  constructor(api, store) {
    this._api = api;
    this._store = store;
  }

  async getReports() {
    const response = await this._api(this.baseUrl);
    return response.data;
  }

  async getReport(id) {
    const response = await this._api(`${this.baseUrl}/${id}`);
    return response.data;
  }

  async getReportField(reportId, fieldId) {
    const response = await this._api(`${this.baseUrl}/${reportId}/fields/${fieldId}`);
    return response.data;
  }

  async getReportFieldCalculation(reportId, fieldId, calculationId) {
    const response = await this._api(`${this.baseUrl}/${reportId}/fields/${fieldId}/calculations/${calculationId}`);
    return response.data;
  }

  async getReportFieldCalculations(reportId, fieldId) {
    const response = await this._api(`${this.baseUrl}/${reportId}/fields/${fieldId}/calculations`);
    return response.data;
  }

  async update(reportId, data) {
    const response = await this._api.put(`${this.baseUrl}/${reportId}/update-report`, data);
    return response.data;
  }

  async saveReportFieldCalculation(reportId, fieldId, calculationId, recalculate, data) {
    const response = await this._api.put(`${this.baseUrl}/${reportId}/fields/${fieldId}/calculations/${calculationId}?recalculate=${recalculate}`, data);
    return response;
  }

  async approveReportFieldCalculation(reportId, fieldId, calculationId, data) {
    const response = await this._api.put(`${this.baseUrl}/${reportId}/fields/${fieldId}/calculations/${calculationId}/approve`, data);
    return response;
  }

  async getReportValues(reportCode, from, to, facilityId = null, duration = null) {
    const fleetFacilityIds = this._store.state.facilities.length !== this._store.state.filteredFacilities.length ? this._store.getters.fleetFacilityIds : [];

    const response = await this._api({
      method: fleetFacilityIds.length ? 'post' : 'get',
      url: `reports/${reportCode}/data`,
      params: {
        from: from.toISOString(),
        to: to.toISOString(),
        facilityId,
        duration
      },
      data: fleetFacilityIds
    });

    return response.data;
  }

  async deleteValues(facilityId, from, to) {
    const response = await this._api.delete(`${this.baseUrl}/values?facilityId=${facilityId}&from=${from}&to=${to}`);
    return response.data;
  }
}
