<template>
  <v-card>
    <v-card-title>
      {{ source ? 'Edit' : 'Add' }} data source
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-select v-model="dataSourceReader" :items="readers" item-text="name" item-value="id" label="Parser provider" @input="updateReaderConfig" solo required></v-select>

        <div v-show="dataSourceReader">
          <h3>
            Fill out details
          </h3>

          <v-file-input ref="fileInput" v-model="file" label="Choose sample file..." clearable @change="fileChanged = true" @click:clear="blurFileInput"></v-file-input>

          <v-text-field v-model="name" label="Name" :rules="rules.name" hint="Allowed characters: Letters, numbers and '_'. No spaces." placeholder="E.g.: Datasource_1" required></v-text-field>

          <v-text-field v-model="note" label="Note"></v-text-field>

          <v-row>
            <v-col>
              <v-text-field v-model.number="measureFrequencyInSeconds" label="Measure frequency (in seconds)" :rules="rules.measureFrequencyInSeconds"></v-text-field>
            </v-col>

            <v-col>
              <v-text-field v-model.number="utcTimezoneOffsetInMinutes" type="number" label="UTC timezone offset (in minutes)" :rules="rules.utcTimezoneOffsetInMinutes" hint="If data is already in UTC, mark it 0" required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox v-model="isPrimary" label="Primary data source for the facility"></v-checkbox>
            </v-col>
            <v-col></v-col>
          </v-row>
        </div>
      </v-form>
    </v-card-text>

    <v-card-title v-show="dataSourceReader">
      Parser config (JSON)
    </v-card-title>

    <v-card-text v-show="dataSourceReader">
      <EditorWindow ref="config" lang="json" :content="readerConfig" />
    </v-card-text>

    <v-card-actions>
      <v-dialog v-model="confirmDialog" max-width="500">
        <template v-slot:activator="{ attrs, on }">
          <v-btn v-show="source" v-bind="attrs" v-on="on" color="warning" :loading="deleting" text>
            <v-icon left>
              mdi-delete-outline
            </v-icon>
            Delete
          </v-btn>
        </template>

        <v-card>
          <v-card-title>
            Delete data source?
          </v-card-title>

          <v-card-text>
            <v-alert class="text-center" color="error" border="left" text>
              <v-row>
                <v-col>
                  <v-icon color="error" left>
                    mdi-skull-crossbones
                  </v-icon>
                  <b>
                    DANGER ZONE
                  </b>
                  <v-icon color="error" right>
                    mdi-skull-crossbones
                  </v-icon>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  The data will be permenantly deleted from the system!
                </v-col>
              </v-row>
            </v-alert>

            <div class="text-body-1">Are you sure you want to delete the data source: <b>{{ this.name }}?</b></div>

          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn @click="confirmDialog = false" text>
              No
            </v-btn>

            <v-btn @click="remove" color="warning" text>
              Yes, delete data source
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-spacer></v-spacer>

      <v-btn @click="close" text>
        Cancel
      </v-btn>

      <v-btn v-show="!source" color="primary" :disabled="!valid" :loading="saving" @click="upload">
        <v-icon left>
          mdi-plus-circle-outline
        </v-icon>
        Add
      </v-btn>

      <v-btn v-show="source" color="primary" :disabled="!valid" :loading="saving" @click="save">
        <v-icon left>
          mdi-content-save-outline
        </v-icon>
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import EditorWindow from './EditorWindow.vue';

export default {
  name: 'DatasourceDialog',
  components: {
    EditorWindow
  },
  props: {
    source: Object
  },
  data() {
    return {
      valid: false,
      saving: false,
      deleting: false,
      name: '',
      note: '',
      isPrimary: false,
      file: null,
      fileChanged: false,
      readerConfig: '',
      dataSourceReader: '',
      measureFrequencyInSeconds: null,
      utcTimezoneOffsetInMinutes: '',
      rules: {
        file: [
          value => !!value || 'Select the file to upload'
        ],
        name: [
          value => !!value || 'Name is required',
          value => !!value && !!value.match(/^[A-Za-z0-9_]*$/g) || 'Name has forbidden characters'
        ],
        utcTimezoneOffsetInMinutes: [
          value => (!!value || value === 0) || 'Timezone offset is required',
          value => typeof value === 'number' || 'Must be a whole number'
        ],
        measureFrequencyInSeconds: [
          value => value !== 0 || 'Frequency must be more than 0',
          value => this.measureFrequencyValidator(value) || 'Must be a whole number'
        ],
        readerConfig: [
          value => !!value || 'Provide a parser configuration'
        ]
      },
      readers: [],
      confirmDialog: false
    }
  },
  methods: {
    close() {
      this.$emit('close');
      this.$refs.form.reset();
      this.fileChanged = false;
    },
    async upload() {
      this.saving = true;
      const facilityId = this.$route.params.facilityId;
      const data = {
        name: this.name,
        dataSourceReaderId: this.dataSourceReader,
        dataSourceReaderConfiguration: this.$refs.config.$refs.editor.getValue(),
        measureFrequencyInSeconds: this.measureFrequencyInSeconds,
        utcTimezoneOffsetInMinutes: this.utcTimezoneOffsetInMinutes,
        note: this.note,
        isPrimary: this.isPrimary
      };

      try {
        const dataSource = await this.$Services.facilities.createDataSource(facilityId, data);

        if (this.file) {
          try {
            await this.$Services.facilities.uploadSampleFile(facilityId, dataSource.id, this.file);
          } catch (error) {
            // Delete the newly created data source in case of an error
            await this.$Services.facilities.deleteDataSource(facilityId, dataSource.id);
            throw new Error('Error uploading file!');
          }
        }

        this.close();
        this.$emit('success');
        this.$store.dispatch('showDefaultSuccess');
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.saving = false;
      }
    },
    async save() {
      this.saving = true;
      const facilityId = this.$route.params.facilityId;
      const data = {
        name: this.name,
        dataSourceReaderId: this.dataSourceReader,
        dataSourceReaderConfiguration: this.$refs.config.$refs.editor.getValue(),
        measureFrequencyInSeconds: this.measureFrequencyInSeconds,
        utcTimezoneOffsetInMinutes: this.utcTimezoneOffsetInMinutes,
        note: this.note,
        isPrimary: this.isPrimary
      };

      try {
        await this.$Services.facilities.updateDataSource(facilityId, this.source.id, data);

        // If the file input has changed, delete the existing file.
        if (this.fileChanged) {
          try {
            await this.$Services.facilities.deleteSampleFile(facilityId, this.source.id);

            // If a new file has been specified, upload it.
            if (this.file) {
              try {
                await this.$Services.facilities.uploadSampleFile(facilityId, this.source.id, this.file);
              } catch (error) {
                throw 'Error uploading file!';
              }
            }
          } catch (error) {
            throw 'Error delete file';
          }
        }

        this.close();
        this.$emit('success');
        this.$store.dispatch('showDefaultSuccess');
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.saving = false;
      }
    },
    async remove() {
      this.deleting = true;
      const facilityId = this.$route.params.facilityId;

      try {
        await this.$Services.facilities.deleteDataSource(facilityId, this.source.id);
        this.close();
        this.$emit('success');
        this.$store.dispatch('showDefaultSuccess');
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.deleting = false;
      }
    },
    measureFrequencyValidator(value) {
      return value ? Number.isInteger(value) : true;
    },
    updateReaderConfig($event) {
      if ($event) {
        if (this.source && $event === this.source.readerId) {
          this.readerConfig = this.source.readerConfiguration;
        } else {
          const reader = this.readers.find(x => x.id === $event);
          this.readerConfig = reader.defaultConfiguration || '';
        }
      }
    },
    blurFileInput() {
      this.$refs.fileInput.blur();
    }
  },
  async created() {
    try {
      this.readers = await this.$Services.readers.getAll();
    } catch (error) {
      console.error(error);
      this.$store.dispatch('showDefaultError');
    }
  },
  watch: {
    source: {
      handler(newValue) {
        // Populate fields if a data source is passed in
        if (newValue) {
          const { name, note, isPrimary, dataSourceReaderConfiguration, dataSourceReaderId, measureFrequencyInSeconds, utcTimezoneOffsetInMinutes, sampleFileName } = newValue;
  
          this.name = name;
          this.note = note;
          this.isPrimary = isPrimary;
          this.dataSourceReader = dataSourceReaderId;
          this.readerConfig = dataSourceReaderConfiguration;
          this.measureFrequencyInSeconds = measureFrequencyInSeconds;
          this.utcTimezoneOffsetInMinutes = utcTimezoneOffsetInMinutes;
          this.file = sampleFileName ? { name: sampleFileName } : null;
        }
      },
      immediate: true
    }
  }
}
</script>