import jwt_decode from "jwt-decode";
import moment from 'moment';

export default {
  dataIsLoaded: state => {
    if (!state.facilities.length) {
      return false;
    }

    else if (state.fleetFilter.length && !state.filteredFacilities.length) {
      return false;
    }

    else {
      return true
    }
  },
  currentUser: state => {
    return state.user
  },
  currentUserId: state => {
    return state.user.profile.sub;
  },
  userOrganizationId: state => {
    return state.user.profile?.organizationId;
  },
  currentFacilityId: state => {
    return state.currentFacility ? state.currentFacility.id : null;
  },
  facilityById: state => id => {
    return state.facilities.find(facility => facility.id === id);
  },
  fleetFacilityIds: state => {
    return state.filteredFacilities.map(facility => facility.id);
  },
  organizations: state => {
    if (!state.organizations.length) return [];

    return state.organizations.sort((a, b) => {
      return String(a.name).localeCompare(b.name);
    });
  },
  organizationById: state => id => {
    return state.organizations.find(organization => organization.id === id);
  },
  measurementFields: state => {
    return state.measurementFields;
  },
  measurementFieldsByIds: (state) => (ids) => {
    if (ids.length) {
      const fields = state.measurementFields.map(field => field.id);

      return ids.map(id => {
        const index = fields.indexOf(id);

        return fields[index];
      });
    } else {
      return [];
    }
  },
  rolesFromToken: state => {
    if (state.user?.access_token) {
      const decoded = jwt_decode(state.user.access_token);

      return decoded.role;
    }
  },
  hasAnyRole: state => (roles) => {
    if (state.user && state.user.access_token) {
      const decoded = jwt_decode(state.user.access_token);

      // Force roles to be array because JWT
      if (decoded.role && !Array.isArray(decoded.role)) {
        decoded.role = [ decoded.role ];
      }

      return decoded.role && decoded.role.some(role => roles.indexOf(role) >= 0);
    }
    return false;
  },
  roles: state => {
    return state.currentOrganization?.roles || [];
  },
  periodDifference: state => {
    return state.period.to.diff(state.period.from, 'days');
  },
  environment: () => {
    switch (window.settings.stageDisplayName) {
      case 'Local':
        return { name: 'Local development', color: 'default' };

      case 'Dev site':
        return { name: 'Development', color: 'accent' }

      case 'Test site':
        return { name: 'Testing', color: 'accent' }

      case 'Demo site':
        return { name: 'Demo', color: 'success' }

      default:
        break;
    }
  },
  activeMessages: state => {
    return state.messages.filter(message => moment.utc().isBetween(message.showFrom, message.showTo));
  },
  systemManufacturers: state => {
    const manufacturers = state.facilities.map(facility => facility.systemManufacturer).filter(name => name !== null);

    return manufacturers.length ? [...new Set(manufacturers)] : [];
  }
}