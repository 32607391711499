<script>
import { Bar, Line } from "vue-chartjs";

var _canvasContext = null;

export default {
    extends: Bar,
    name: 'SFOCImpactChart',
    props: {
      ships: {
        type: Array,
        required: true,
        default: function() {
          return [];
        }
      }
    },
    computed: {
      chartData() {
        return {
          labels: this.ships.map(ship => ship.name),
          datasets: [
            {
              type: 'line',
              label: 'Best performing in fleet',
              data: Array(this.ships.length).fill(this.bestPerformingValue),
              borderColor: '#2b055d',
              pointStyle: 'line',
              pointBorderWidth: 2,
              fill: false,
            },
            {
              type: 'bar',
              label: 'SFOC impact percent',
              data: this.ships.map(ship => ship.value),
              backgroundColor:'#926EC2',
              borderColor: '#6B3EA8',
              borderWidth: 2,
              fill: false
            }
          ]
        }
      },
      bestPerformingValue() {
        return Math.min(...this.ships.map(ship => ship.value));
      }
    },

    mounted() {
        this.addPlugin({
          id: "centertext-plugin",
          beforeDraw: this.centertext
        });

        this.setWidth();

        this.renderChart(this.chartData, getChartOptions());
    },
    methods: {
      centertext(chart) {
        _canvasContext = chart.chart.ctx;
      },
      setWidth() {
        // Find the chart element and set width according to amount of elements
        const wrapper = document.getElementById('sfoc-chart-wrapper');
        const el = document.getElementById('sfoc-chart');
        const width = this.ships.length * 40;

        if (this.ships.length > 25) {
          wrapper.style.overflowX = 'scroll';
          el.style.width = `${width}px`;
        } else {
          wrapper.style.overflowX = 'hidden';
        }
      }
    },
    watch: {
      ships() {
        this.setWidth();
        this.renderChart(this.chartData, getChartOptions());
      }
    }
}

function getChartOptions() {

  return {
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        stacked: true,
        // categoryPercentage: 0.5,
        // barPercentage: 1
      }],
      yAxes: [{
        stacked: true,
        scaleLabel: {
          display: true,
          labelString: 'SFOC %'
        },
        ticks: {
          beginAtZero: true,
          stepSize: 0.5,
          min: 0,
          suggestedMax: 4
        }
      }]
    }
  }
}

</script>