<template>
  <v-btn
    :loading="loading"
    :disabled="!facilityName"
    text
    color="primary"
    @click="getPackage"
  >
    <v-icon left>
      mdi-robot-industrial
    </v-icon>

    Provision
  </v-btn>
</template>

<script>
export default {
  name: 'ProvisionPSDU',
  props: {
    facilityName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialog: false,
      loading: false
    }
  },
  methods: {
    async getPackage() {
      this.loading = true;

      try {
        const response = await this.$Services.scripts.getPSDU(this.$route.params.facilityId);
        const fileNameSuffix = this.facilityName.replace(' ', '_').toLowerCase();

        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `psdu_${fileNameSuffix}.zip`);

        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError', 'An error occurred compiling a PSDU package for this ship. Please try again.');
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>
