<template>
  <v-dialog v-model="dialog" max-width="800">
    <template v-slot:activator="{ attrs, on }">
      <v-slide-x-reverse-transition>
        <v-btn
          v-if="showButton"
          v-bind="attrs"
          v-on="on"
          outlined
          text
          small
          tile
        >
          <h1 class="text-lowercase">
            i
          </h1>
        </v-btn>
      </v-slide-x-reverse-transition>
    </template>

    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>

      <v-card-text>
        <div v-html="content"></div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" text @click="dialog = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'WidgetInfo',
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      showButton: false
    }
  },
  mounted() {
    setTimeout(() => {
      this.showButton = true;
    }, 50)
  }
}
</script>
