<script>
import { Line } from "vue-chartjs";
import moment from "moment";
// import Chart from "chart.js";
// import ChartDataLabels from "chartjs-plugin-datalabels";

var _canvasContext = null;

export default {
    extends: Line,
    props: {
      labels: {
        type: Array,
        required: true
      },
      target: {
        /*
        {
          label: string
          values: number[]
        }
         */
        type: Object,
        reqired: true
      },
      series: {
        /*
        [{
          label: string
          values: number[]
        }]
        */
        type: Array,
        required: true
      }
    },
    // data() {
    //   return {
    // //     options: {
    // //       legend: {
    // //         display: false,
    // //       },
    // //       responsive: true,
    // //       maintainAspectRatio: false,
    // //       onClick: this.handle,
    // //       plugins: {
    // //         // Change options for ALL labels of THIS CHART
    // //         datalabels: {
    // //           color: "#FFFFFF",
    // //         },
    // //       },
    // //       scales: {
    // //         yAxes: [{
    // //           // id: 'target',
    // //           // stacked: true,
    // //           ticks: {
    // //             beginAtZero: true
    // //           }
    // //         // },{
    // //         //   id: 'stacked',
    // //         //   stacked: true,
    // //         //   ticks: {
    // //         //     beginAtZero: true
    // //         //   }
    // //         }]
    // //       }
    // //     }
    //   };
    // },
    mounted() {
        this.addPlugin({
          id: "centertext-plugin",
          beforeDraw: this.centertext
        });
        this.renderChart(getChartData(this.labels, this.target, this.series), getChartOptions());

        setTimeout(() =>{
          this.renderChart(getChartData(this.labels, this.target, this.series), getChartOptions());
        }, 0);
    },
    methods: {
      centertext(chart) {
        _canvasContext = chart.chart.ctx;
      }
    }
}

function getChartData(labels, target, series) {
  console.log(_canvasContext);

  const dataSets = series.map((serie, serieIx) => {
    let gradient = _canvasContext ? _canvasContext.createLinearGradient(200, 0, 700, 0) : null;
    if (gradient) {
      let isAboveTarget = false;
      if (serie.values[0] > target[0]) {
        isAboveTarget = true;
        setGradientColor(gradient, 0, true);
      }

      serie.values.forEach((value, valueIx) => {
        if ((!isAboveTarget && value > target.values[valueIx]) ||
          (isAboveTarget && value <= target.values[valueIx])) {
          isAboveTarget = !isAboveTarget;
          setGradientColor(gradient, valueIx / serie.values.length, isAboveTarget);
        }
      });
    }

    return {
      label: serie.label,
      data: serie.values,
      fill: `${series.length}`,
      pointRadius: 4,
      cubicInterpolationMode: 'monotone',
    
    //tension: 0,
      borderColor: ['#60afdd', '#4892cb'][serieIx],
      backgroundColor: gradient
    };
  });
  dataSets.push({
    id: 'target',
    label: target.label,
    data: target.values,
    pointRadius: 0,
    fill: true,
    cubicInterpolationMode: 'monotone',
    bezierCurve: false,
    tension: 0,
    backgroundColor:'rgba(0,141,117,0.1)',
    borderColor: 'rgba(0,141,117,0.2)',
    borderWidth: 1
  })

  return{
    labels: labels.map(x => getDateString(x)),
    type: 'line',
    datasets: dataSets
  }
}

function setGradientColor(gradient, ratio, isAboveTarget) {
  // const transparent = 'rgba(0, 255, 0, 1)';
  // const filled = 'rgba(255, 0, 0, 1)';
  const transparent = 'transparent';
  // const filled = 'rgba(255, 0, 0, 0.2)';
  const filled = 'transparent';
  // set two stops in same place to create a hard break between colors
  gradient.addColorStop(ratio, isAboveTarget ? transparent : filled);
  gradient.addColorStop(ratio, isAboveTarget ? filled : transparent);
}

function getChartOptions() {
  return {
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: "#FFFFFF",
      },
    },
    scales: {
      yAxes: {
        stacked: false,
        ticks: {
          beginAtZero: true,
        }
      }
    }
  }
}

function getDateString(date) {
  return `${moment(date).format("DD-MM-YYYY")}`;
  // return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
}

</script>