<template>
  <v-card
    elevation="2"
    :loading="loading ? '#2374a3' : false"
  >
    <v-card-title class="text-uppercase">
      <div>Marpol Compliance</div>
      <v-spacer></v-spacer>
      <div class="d-flex align-center justify-space-between" style="gap: .5rem">
        <ExportShortcut
          reportCode="FacilityGroupMarpolComplianceLarge"
        />
        <WidgetInfo
          v-if="report.information"
          title="Marpol compliance"
          :content="report.information"
        />
      </div>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col align="center" cols="4" xs="12" sm="12" md="12" lg="5" class="align-self-end text-carbon">
          <div style="height: 235px; width:100%; ">
            <vue-speedometer
              :forceRender="true"
              :width="400"
              :height="250"
              valueTextFontSize="30px"
              :minValue="80"
              :maxValue="100"
              :segments="3"
              :customSegmentStops='[80,90,95,100]'
              :segmentColors='["#60afdd", "#2d95d2", "#2374a3"]'
              needleColor="#9E9E9E"
              :value="speedometer.needle"
              :currentValueText="loading ? ' ' : `${speedometer.actual ? `${speedometer.actual}%` : 'No data'}`"
              />
          </div>
        </v-col>

        <v-col cols="8" xs="12" sm="12" md="12" lg="7">
          <div id="marpol-chart-wrapper">
            <MarpolComplianceChart id="marpol-chart" :ships="chartData" />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4" xs="12" sm="12" md="12" lg="5">
          <p class="text-center">
            Average compliance %
          </p>
        </v-col>
        <v-col cols="8" xs="12" sm="12" md="12" lg="7">
          <p class="text-center">
            Compliance % for each vessel
            <br>
            <v-icon color="#2374a3">
              mdi-window-minimize
            </v-icon>

            Average compliance % for fleet
          </p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import VueSpeedometer from 'vue-speedometer';
import WidgetBanner from '@/components/WidgetBanner.vue';
import MarpolComplianceChart from '@/components/MarpolComplianceChart.vue';
import WidgetInfo from '@/components/WidgetInfo.vue';
import ExportShortcut from '../components/ExportShortcut.vue';

export default {
  name: 'FleetMarpolComplianceLarge',
  components: {
    VueSpeedometer,
    WidgetBanner,
    MarpolComplianceChart,
    WidgetInfo,
    ExportShortcut
  },
  props: {
    period: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      report: {}
    }
  },
  computed: {
    speedometer() {
      const value = this.report?.fields?.ActualAverage?.value;
      const format = this.report?.fields?.ActualAverage?.format || 'Percent1Digit';

      const data = { actual: 0, needle: 0 };

      if (!this.loading && value && format) {
        data.actual = Number(this.$options.filters.formatKPI(value, format));

        // Set needle value to cap overflow
        data.needle = data.actual < 80 ? 80 : data.actual;
      }

      return data;
    },
    chartData() {
      let ships = [];

      const format = this.report?.fields?.Facilities?.format || 'Percent1Digits';
      let values = this.report?.fields?.Facilities?.values || [];

      ships = values.map(x => {
        return {
          value: Number(this.$options.filters.formatKPI(x.value, format)),
          name: this.$store.getters.facilityById(x.facilityId).name
        };
      });

      /**
       * Uncomment this to generate test data
       */
      // ships = this.$store.state.filteredFacilities.map(x => {
      //   return {
      //     value: Math.floor(Math.random() * 100),
      //     name: x.name
      //   }
      // });

      ships.sort((a, b) => {
        return String(a.name).localeCompare(b.name);
      });

      return ships;
    },
    ...mapState([ 'filteredFacilities' ])
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.report = {};

      try {
        this.report = await this.$Services.reports.getReportValues('FacilityGroupMarpolComplianceLarge', this.period.from, this.period.to);
        
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    period() {
      this.fetchData();
    },
    filteredFacilities() {
      this.fetchData();
    }
  }
}
</script>
