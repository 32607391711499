<template>
  <div class="d-flex flex-column justify-center align-center" style="min-height: 50vh">
    <v-progress-circular indeterminate size="50" color="primary"></v-progress-circular>

      <div class="mt-4 text-caption font-weight-bold grey--text text--darken-3">
        {{ message }}
      </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  data() {
    return {
      message: 'Getting data'
    };
  },
  methods: {
    updateMessage(message) {
      this.message = message;
    }
  },
  mounted() {
    setTimeout(() => { this.updateMessage('Oof, it\'s taking a little longer than expected. Hang on..') }, 5000);
    setTimeout(() => { this.updateMessage('Wow! This is taking a while. Thank you for your patience 🙏') }, 10000);
    setTimeout(() => { this.updateMessage('Okay. Maybe grab a cup of coffee ☕') }, 20000);
  }
};
</script>