<template>
  <v-snackbar
    :value="show"
    :color="data.color || 'primary'"
    timeout="-1"
    bottom
    right
    :vertical="data.vertical"
  >
    <v-icon v-if="data.icon" left>
      mdi-{{ data.icon }}
    </v-icon>

    {{ data.text }}

    <template v-slot:action="{ attrs }">
      <v-btn v-if="!data.hideDefaultButton" v-bind="attrs" @click="dismiss" text>
        {{ data.buttonText || 'OK' }}
      </v-btn>

      <v-btn v-if="data.to" v-bind="attrs" :to="data.to" text>
        Go there
      </v-btn>

      <v-btn v-if="data.method && data.methodButtonText" v-bind="attrs" @click="data.method" text>
        {{ data.methodButtonText }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Notification',
  computed: {
    show() {
      return this.$store.state.showNotification;
    },
    data() {
      return this.$store.state.notification;
    }
  },
  methods: {
    dismiss() {
      this.$store.commit('hideNotification');
    }
  }
}
</script>