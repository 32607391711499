<template>
  <v-bottom-sheet :value="true" width="800" inset persistent>
    <v-card class="pb-2" max-width="800">
      <v-card-title>
        Yes, it's the cookie thing..
      </v-card-title>
      <v-card-text>
        <p>
          We use cookies to make PureteQ Pure-SPOT work, but also to remember stuff like:
        </p>

        <p>
          <ul>
            <li>You, so you don't have to log in every time</li>
            <li>Some of your personal preferences to make everything more enjoyable</li>
            <li>That's it!</li>
          </ul>
        </p>

        <p>
          We do not use any third-party cookies nor do we share any information with third-parties.
        </p>

        <p>
          To be able to use PureteQ Pure-SPOT you must accept the use of cookies.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="accept" block large>
          I accept
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: 'CookieDialog',
  methods: {
    accept() {
      localStorage.setItem('hasAcceptedCookies', JSON.stringify(true));
      this.$Services.auth.login();
    }
  }
}
</script>