<template>
  <v-dialog v-model="dialog" max-width="800">
    <template v-slot:activator="{ attrs, on }">
      <v-btn class="ml-4" v-bind="attrs" v-on="on" :disabled="!organization" color="primary" text>
        <v-icon left>
          mdi-domain-plus
        </v-icon>
        Link organization
      </v-btn>
    </template>

    <v-card v-if="organization">
      <v-card-title>
        Link organization
      </v-card-title>
      <v-card-text>
        <div class="text-body-1 mb-3">
          Here's how to <b>get</b> access to another organization's ships:
        </div>

        <ol class="mb-3">
          <li>Contact the organization and give them your link key below.</li>

          <li>Next, they need to add your organization and assign access to one or more ships.</li>
          <li>After this, these ships will appear in your ship list.</li>
          <li>You then have to assign access to your users.</li>
        </ol>

        <i>
          Your users will then have access to the ships
        </i>

        <p class="text-body-1 mt-3">
          Your link key:
        </p>

        <v-sheet class="pa-4 text-body-1 d-flex align-center justify-center" color="grey lighten-4" :width="organization.linkKey ? 300 : 180" rounded>
          <span id="organization-code">
            {{ organization.linkKey || 'XXXXXXX' }}
          </span>

          <v-btn class="ml-5" v-if="organization.linkKey" color="primary" @click="copyLinkKey" text>
            <v-icon left>
              mdi-content-copy
            </v-icon>

            Copy
          </v-btn>
        </v-sheet>

        <v-divider class="mt-6"></v-divider>

        <div class="text-body-1 mt-6 mb-3">
          Here's how to <b>give</b> another organization access to one or more ships.
        </div>

        <ol class="mb-3">
          <li>Contact the organization and request their link key.</li>
          <li>Enter the link key below.</li>
          <li>The organization will then appear on your user list.</li>
          <li>You then have to assign access to one or more ships.</li>
          <li>Remember to let the organization know that they now have access.</li>
        </ol>

        <i>
          The ships will then appear on their ship list and they can assign access to their users.
        </i>

        <v-form class="mt-6" ref="form" v-model="valid">
          <v-text-field v-model="key" label="Link key" :rules="keyRules" outlined required></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close" text>
          Close
        </v-btn>
        <v-btn color="primary" :disabled="!valid" :loading="loading" @click="requestLink" text>
          <v-icon>
            mdi-check
          </v-icon>
          Confirm link
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LinkOrganizationDialog',
  data() {
    return {
      dialog: false,
      valid: false,
      key: '',
      keyRules: [
        value => !!value || 'You have to specify a link key',
        value => !!value && !!value.match(/^[a-zA-Z\d]+$/g) || 'Only letters and numbers are allowed',
        value => !!value && value.length === 7 || 'Key must be 7 characters'
      ],
      loading: false
    }
  },
  computed: {
    organization() {
      return this.$store.state.currentOrganization;
    }
  },
  methods: {
    close() {
      this.$refs.form.reset();
      this.dialog = false;
    },
    async requestLink() {
      try {
        this.loading = true;

        await this.$Services.organizations.link(this.organization.id, this.key);

        this.$store.dispatch('showDefaultSuccess');
        this.$emit('update');
        this.close();
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.loading = false;
      }
    },
    copyLinkKey() {
      navigator.clipboard.writeText(this.organization.linkKey);
    }
  }
}
</script>

<style scoped>
#organization-code {
  font-size: 1.5rem;
  line-height: 1.5rem;
  letter-spacing: 5px;
  user-select: none;
}
</style>