<template>
  <v-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ attrs, on }">
      <v-btn v-bind="attrs" v-on="on" color="secondary" text>
        <v-icon left>
          mdi-plus
        </v-icon>

        Add channel
      </v-btn>  
    </template>

    <v-card>
      <v-card-title>
        {{ id ? 'Edit' : 'Add' }} custom channel
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field v-model="source" label="Source" :rules="rules.source" required></v-text-field>

          <v-text-field v-model="name" label="Name" :rules="rules.name" required></v-text-field>

          <v-select v-model="dataType" :items="$store.state.channelDataTypes" label="Data type" :rules="rules.dataType" required></v-select>

          <v-text-field v-model="unit" label="Unit"></v-text-field>

          <v-text-field v-model="description" label="Description"></v-text-field>

          <v-row align="center">
            <v-col class="text-body-1">
              Quality score
            </v-col>

            <v-col class="text-right">
              <v-rating v-model="qualityScore" color="primary" background-color="secondary"></v-rating>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-dialog v-model="confirmDialog" max-width="500">
          <template v-slot:activator="{ attrs, on }">
            <v-btn v-show="id" v-bind="attrs" v-on="on" color="warning" :loading="deleting" text>
              <v-icon left>
                mdi-delete-outline
              </v-icon>
              Delete
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              Delete channel?
            </v-card-title>

            <v-card-text>
              Are you sure you want to delete this channel?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn @click="confirmDialog = false" text>
                No
              </v-btn>

              <v-btn @click="deleteChannel" color="warning" text>
                Yes, delete channel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-spacer></v-spacer>

        <v-btn @click="close" text>
          Close
        </v-btn>

        <v-btn v-if="!id" :disabled="!valid" :loading="saving" color="primary" @click="create" text>
          <v-icon left>
            mdi-plus
          </v-icon>

          Add
        </v-btn>

        <v-btn v-else :disabled="!valid" :loading="saving" color="primary" @click="update" text>
          <v-icon left>
            mdi-content-save
          </v-icon>

          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ChannelDialog',
  inject: [ 'getChannels' ],
  props: {
    dataSourceId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      confirmDialog: false,
      valid: false,
      saving: false,
      deleting: false,
      id: '',
      source: '',
      name: '',
      dataType: '',
      unit: '',
      description: '',
      qualityScore: null,
      rules: {
        source: [
          value => !!value || 'Source is required'
        ],
        name: [
          value => !!value || 'Name is required',
          value => !!value?.match(/^[A-Za-z0-9_]*$/g) || 'Name has forbidden characters'
        ],
        dataType: [
          value => !!value || 'You have to select a data type'
        ]
      }
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$emit('close');
    },
    async create() {
      try {
        this.saving = true;

        const data = {
          source: this.source,
          name: this.name,
          dataType: this.dataType,
          description: this.description,
          unit: this.unit,
          qualityScore: this.qualityScore
        }

        await this.$Services.channels.create(this.dataSourceId, data);

        this.close();
        this.$store.dispatch('showDefaultSuccess');
        this.getChannels();
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.saving = false;
      }
    },
    async update() {
      try {
        this.saving = true;

        const data = {
          source: this.source,
          name: this.name,
          dataType: this.dataType,
          description: this.description,
          unit: this.unit,
          qualityScore: this.qualityScore
        }

        await this.$Services.channels.update(this.dataSourceId, this.id, data);

        this.close();
        this.$store.dispatch('showDefaultSuccess');
        this.getChannels();
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.saving = false;
      }
    },
    async deleteChannel() {
      try {
        this.deleting = true;

        await this.$Services.channels.delete(this.dataSourceId, this.id);

        this.close();
        this.$store.dispatch('showDefaultSuccess');
        this.getChannels();
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.deleting = false;
      }
    },
    setChannel(channel) {
      this.id = channel.id;
      this.source = channel.source;
      this.name = channel.name;
      this.dataType = channel.dataType;
      this.unit = channel.unit;
      this.description = channel.description;
      this.qualityScore = channel.qualityScore;
    }
  }
}
</script>