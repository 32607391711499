import axios from 'axios'
import authService from './AuthService';
import FacilityService from './FacilityService';
import TempDataService from './TempDataService';
import ReportService from './ReportService';
import CalculationService from './CalculationService';
import MeasurementFieldsService from './MeasurementFieldsService';
import ChannelService from './ChannelService';
import DataSourceReaderService from './DataSourceReaderService';
import MeasurementFieldCalculationService from './MeasurementFieldCalculationService';
import OrganizationService from './OrganizationService';
import store from '@/store/index';
import UserService from './UserService';
import MessageService from './MessageService';
import ScriptsService from './ScriptsService';
import ExportService from './ExportService';
import router from '@/router';

let _store = store;
const _api = axios.create({
    baseURL: window.settings.api.baseUrl
});

/*
For at tilføje auth header til axios se interceptors løsningen her:
https://stackoverflow.com/a/45581882/2007420
*/

_api.interceptors.request.use(config => {
    if (_store) {
        const user = _store.getters.currentUser;

        if (user && user.access_token) {
            config.headers.common['Authorization'] = `Bearer ${user.access_token}`;
        }
    }
    return config;
});

/**
 * If any request returns an 401 Unauthorized or 403 Forbidden, an attempt
 * is made to refresh the access token. If the refresh is successful, the request is retried.
 * If the refresh fails the user is redirected to login and the current route is stored to be able to return.
 */
_api.interceptors.response.use((response) => {
    if (_store.state.offlineMode) {
        _store.commit('setOfflineMode', false);
    }

    return response;
}, (error) => {
    if (typeof error.response === 'undefined') {
        console.log('A network error occurred.');
        _store.commit('setOfflineMode', true);
    }

    const status = error.response?.status;

    if (status === 401 || status === 403) {
        localStorage.setItem('pt-redirectAfterLoginPath', router.currentRoute.fullPath);
        authService.login();
    }

    return Promise.reject(error);
});

export default {
    set store(store) { _store = store; },
    get api() { return _api },
    get auth() { return authService; },
    get export() { return new ExportService(_api) },
    get facilities() { return new FacilityService(_api, _store); },
    get tempData() { return new TempDataService(_api); },
    get reports() { return new ReportService(_api, _store) },
    get calculations() { return new CalculationService(_api, _store) },
    get measurementFields() { return new MeasurementFieldsService(_api, _store) },
    get measurementFieldCalculations() { return new MeasurementFieldCalculationService(_api, _store) },
    get channels() { return new ChannelService(_api, _store) },
    get readers() { return new DataSourceReaderService(_api, _store) },
    get organizations() { return new OrganizationService(_api, _store) },
    get users() { return new UserService(_api, _store) },
    get messages() { return new MessageService(_api, _store) },
    get scripts() { return new ScriptsService(_api) }
};
