import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

// const opts = { 
// theme: {
//     themes: {
//       light: {
//         primary: '#3f51b5',
//         secondary: '#b0bec5',
//         accent: '#8c9eff',
//         error: '#b71c1c',
//       },
//     },
//   }
// }

export default new Vuetify({
     theme: {
          //     dark: true,
          themes: {
               light: {
                    primary: '#003893',
                    secondary: '#60afdd',
                    accent: '#F0B80F',
                    //   'primary--text': '#ff0000;',
                    //   'secondary--text': '#00ff00;'
                    anchor: '#003893',
                    error: '#DE5A00',
                    info: '#4892cb',
                    success: '#00B697',
                    warning: '#DEB200',
                    purple: '#926EC2',
                    darkpurple: '#6B3EA8',
                    darkgreen: '#008D75',
                    teal: '#3873A0'
               },
          }
     }
})