<script>
import { Bar, Line } from "vue-chartjs";

var _canvasContext = null;

export default {
  name: 'MarpolComplianceChart',
  extends: Bar,
  props: {
    ships: {
      type: Array,
      required: true,
      default: () => {
        return [];
      }
    }
  },
  computed: {
    average() {
      if (!this.ships.length) return;

      const avg = this.ships.map(x => x.value).reduce((a, b) => a + b) / this.ships.length;

      return avg >= 99.5 && avg < 100 ? avg.toFixedNoRound(1) : avg.toFixed(1);
    },
    chartData() {
      return {
        // labels: this.data.ships.map(ship => ship.name),
        labels: this.ships.map(ship => ship.name),
        datasets: [
          {
            type: 'line',
            label: 'Fleet average',
            // data: Array(this.data.ships.length).fill(this.average),
            data: Array(this.ships.length).fill(this.average),
            borderColor: '#2374a3',
            pointStyle: 'line',
            pointBorderWidth: 2,
            fill: false
          },
          {
            type: 'bar',
            label: 'Marpol compliance %',
            data: this.ships.map(ship => ship.value),
            backgroundColor:'#60afdd',
            fill: false
          }
        ]
      }
    }
  },

  mounted() {
      this.addPlugin({
        id: "centertext-plugin",
        beforeDraw: this.centertext
      });

      this.setWidth();

      this.renderChart(this.chartData, getChartOptions());
  },
  methods: {
    centertext(chart) {
      _canvasContext = chart.chart.ctx;
    },
    setWidth() {
      // Find the chart element and set width according to amount of elements
      const wrapper = document.getElementById('marpol-chart-wrapper');
      const el = document.getElementById('marpol-chart');
      const width = this.ships.length * 40;

      if (this.ships.length > 25) {
        wrapper.style.overflowX = 'scroll';
        el.style.width = `${width}px`;
      } else {
        wrapper.style.overflowX = 'hidden';
      }
    }
  },
  watch: {
    ships() {
      this.setWidth();
      this.renderChart(this.chartData, getChartOptions());
    }
  }
}

function getChartOptions() {

  return {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Marpol compliance %'
        },
        ticks: {
          beginAtZero: false,
          stepSize: 5,
          suggestedMin: 80,
          suggestedMax: 100
        }
      }]
    }
  }
}

</script>