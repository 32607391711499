<template>
  <div>
    <v-toolbar color="secondary" extended dark>
      <v-toolbar-title>
        <v-icon left>
          mdi-account-cog-outline
        </v-icon>

        Administration {{ organization ? `- ${organization.name}` : '' }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-toolbar-items v-if="$store.getters.hasAnyRole(['SystemAdmin'])">
        <v-btn to="/admin/messages" text>
          Messages
        </v-btn>

        <v-btn to="/admin/organizations" text>
          Organizations
        </v-btn>

        <v-btn :disabled="!organization" :to="`/admin/${organization ? organization.id : ''}/manage`" text>
          Access management
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <transition name="slide-right" mode="out-in">
      <v-container class="mt-n16" fluid>
        <router-view v-if="organizations.length"></router-view>

        <v-card v-else>
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-card>
      </v-container>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Admin',
  computed: {
    organizations() {
      return this.$store.getters.organizations;
    },
    organization() {
      return this.$store.state.currentOrganization;
    }
  },
  mounted() {
    this.$store.dispatch('getOrganizations');
  }
}
</script>