import Vue from 'vue'
import App from './App.vue'
import NotAvailable from './views/NotAvailable.vue'
import router from './router'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import services from '@/services/ServiceFactory';
import store from './store';
import Updater from '@ipainnovation/vue-updater';
import DatetimePicker from 'vuetify-datetime-picker';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

// Importing the global css file
import "@/assets/global.css"

Vue.config.productionTip = false

import moment, { utc } from 'moment'
Vue.prototype.moment = moment

Vue.filter('formatTime', function (value) {
  if (value) {
    // return moment(value).format('DD-MM-YYYY HH:mm');
    return moment(value).utc().format('YYYY.MM.DD HH:mm');
  }
});

Vue.filter('formatDateTime', function (value) {
  if (value) {
    // return moment(value).format('DD-MM-YYYY HH:mm');
    return moment(value).utc().format('YYYY.MM.DD HH:mm');
  }
});

Vue.filter('formatDate', function (value, mode) {
  if (value) {
    switch (mode) {
      case 'month':
        return moment(value).utc().format('MMM YYYY');
      default:
        return moment(value).utc().format('YYYY.MM.DD');
    }
  }
});

Vue.filter('formatFromNow', function (value) {
  if (value) {
    return moment(value).utc().fromNow();
  }
})

Vue.filter('formatKPI', function (value, format) {
  if (typeof value === 'undefined') return null;

  if (format.includes('Digit')) {
    let decimals = format.charAt(format.indexOf('Digit') - 1); // This is not sustainable, but works as long as the format doesn't change.

    if (typeof decimals !== 'number') {
      decimals = 1;
    }

    if (format.includes('Percent') && value >= 99.9) {
      return value.toFixedNoRound(decimals);
    }

    else if (format.includes('Ton') && value < 1.0) {
      return value;
    }

    else {
      return value.toFixed(decimals);
    }
  }

  else {
    return value;
  }
});

Number.prototype.toFixedNoRound = function (precision = 1) {
  const factor = Math.pow(10, precision);
  return Math.floor(this * factor) / factor;
}

/**
 * Sentry setup
 */
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://60afba193be3409093f3722fdcf35bac@o876503.ingest.sentry.io/5850830',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [ 'localhost', 'api.pure-spot.dk', /^\// ]
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,
    release: 'pureteq-portal@' + window.settings.version ?? 'no-version',
    environment: window.settings.stageDisplayName ?? 'Unknown environment'
  });
}

import AppService from '@/services/AppService'
import './registerServiceWorker'
Vue.prototype.$AppService = new AppService(App);
Vue.prototype.$Services = services;

Vue.use(Updater);
Vue.use(DatetimePicker);

/**
 * Check for maintenance mode
 */
if (window.settings.maintenance === true) {
  new Vue({
    store,
    render: h => h(NotAvailable)
  }).$mount('#app');
}

else {
  new Vue({
    router,
    vuetify,
    store,
    services,
    render: h => h(App)
  }).$mount('#app')
}