<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        Select a measurement field

        <v-spacer></v-spacer>

        <ApproveAllDialog :fields="approvableFields" @done:changed="fetchData" />
      </v-card-title>

      <v-card-text>
        <v-list v-if="!loading">
          <v-list-item dense>
            <v-row class=" text-center text--secondary">
              <v-col class="text-left">
                Name
              </v-col>

              <v-col>
                Data type
              </v-col>

              <v-col>
                Calculation rating
              </v-col>

              <v-col>
                Status
              </v-col>

              <v-col cols="4">
                <div class="d-flex justify-space-between align-center">
                  <span>Comment</span>

                  <v-btn small @click="expandComments = !expandComments" color="primary" text>
                    <!-- <v-icon left small>
                      mdi-arrow-{{ expandComments ? 'collapse' : 'expand' }}
                    </v-icon> -->
                    Show {{ expandComments ? 'less' : 'more' }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-list-item>

          <v-list-item v-for="(field, i) in fields" :key="i" :to="`detail?id=${field.id}`" replace>
            <v-row class="text-center" align="center">
              <v-col class="text-left">
                <div>
                  {{ field.name }}
                </div>
              </v-col>

              <v-col>
                <div class="text-caption">
                  {{ field.dataType }}
                </div>
              </v-col>

              <v-col>
                <v-rating v-model="field.activeCalculationRating" v-if="field.activeCalculationRating" color="primary" background-color="secondary" readonly small dense></v-rating>
              </v-col>

              <v-col>
                <v-chip :color="getStatusColor(field.status)" label small>
                  {{ field.status }}
                </v-chip>
              </v-col>

              <v-col id="comment" :class="{ 'text-truncate': !expandComments }" cols="4">
                {{ field.activeCalculationComment }}
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>

        <v-skeleton-loader v-else type="list-item@15"></v-skeleton-loader>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ApproveAllDialog from '@/components/ApproveAllDialog.vue';

export default {
  name: 'MeasurementFields',
  components: {
    ApproveAllDialog
  },
  data() {
    return {
      loading: false,
      fields: [],
      expandComments: false
    }
  },
  computed: {
    approvableFields() {
      return this.fields.filter(field => field.calculations !== 0 && field.approvedCalculations === 0);
    }
  },
  mounted: async function () {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;

        this.fields = await this.$Services.measurementFields.getAllForFacility(this.$route.params.facilityId);
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.loading = false;
      }
    },
    getRandomInt() {
      const min = Math.ceil(0);
      const max = Math.floor(4);
      return Math.floor(Math.random() * (max - min) + min);
    },
    getProgress(report) {
      return (report.approvedFieldsCount / report.totalFieldsCount) * 100;
    },
    sortByTarget(a, b) {
      if (a.target < b.target) {
        return 1;
      }

      if (a.target > b.target) {
        return -1;
      }

      else {
        return a.name < b.name ? -1 : 1;
      }
    },
    getStatusColor(status) {
      switch (status) {
        case 'Waiting':
          return 'grey lighten-3';
        case 'In progress':
          return 'secondary';
        case 'Approved':
          return 'primary';
        case 'Active':
          return 'success';
        default:
          return '';
      }
    }
  }
}
</script>

<style scoped>
#comment {
  width: 500px;
  text-align: left;
  margin: 12px 0;
}
</style>