<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-scale-transition>
          <v-card min-height="200">
            <div class="d-flex align-center pt-4 px-4">
              <div class="grow mr-4">
                <v-text-field v-model="search" label="Search channels" hide-details clearable solo></v-text-field>
              </div>

              <div class="shrink">
                <v-dialog v-model="dialog" max-width="800">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" color="primary" text>
                      <v-icon left>
                        mdi-plus
                      </v-icon>
                      Add data source
                    </v-btn>
                  </template>
                  <DatasourceDialog @close="closeDataSourceDialog" :source="selectedDataSource" @success="getChannels" />
                </v-dialog>
              </div>
            </div>

            <v-card-text>
              <v-row v-if="!isLoadingChannels && !datasources.length">
                <v-col>
                  <v-sheet class="d-flex flex-column justify-center align-center" height="300">
                    <div class="text-h5 text--disabled">
                      No datasources added yet
                    </div>

                    <p class="text--disabled">
                      You can import a configuration from another ship to make setup much faster!
                    </p>

                    <ImportConfigDialog v-on:import="getChannels" />
                  </v-sheet>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-treeview ref="tree" v-if="items.length && !isLoadingChannels" :items="items" :search="search" open-on-click :open-all="openAllNodes" activatable :active.sync="activeChannels" return-object transition>
                    <template v-slot:prepend="{ item }">
                      <v-icon v-if="item.children">
                        mdi-file-table-outline
                      </v-icon>

                      <v-icon v-else :color="item.mapping ? 'success darken-1' : ''">
                        mdi-pound
                      </v-icon>
                    </template>

                    <template v-slot:label="{ item }">
                      <v-row no-gutters align="center">
                        <v-col class="d-flex align-center" cols="4">
                          <span :class="item.children ? 'font-weight-medium' : ''">
                            {{ item.name }}
                          </span>

                          <span class="ml-2">
                            <v-chip v-if="item.children" color="primary lighten-2" x-small label>
                              {{ item.reader }}
                            </v-chip>

                            <span v-else-if="item.mapping || item.type === 'custom'">
                              <v-icon left small>
                                mdi-arrow-right
                              </v-icon>

                              <v-chip color="secondary" small label>
                                {{ item.mapping ? item.mapping.name : item.data.name }}
                              </v-chip>
                            </span>
                            <v-tooltip right>
                              <template v-slot:activator="{ attrs, on }">
                                <span v-bind="attrs" v-on="on" class="ml-2">
                                  <v-chip v-if="item.isPrimary" color="success" x-small label>
                                    PRIMARY
                                </v-chip>
                                </span>
                              </template>
                              Measurement field values are calculated for timestamps in this data source
                            </v-tooltip>
                          </span>
                        </v-col>

                        <v-col>
                          <v-fade-transition v-if="!item.children && showChannelMeta" hide-on-leave>
                            <v-row class="text-caption text--secondary" justify="start">
                              <v-col v-if="item.data.minSampleValue">
                                Min: {{ item.data.minSampleValue }}
                              </v-col>

                              <v-col v-if="item.data.maxSampleValue">
                                Max: {{ item.data.maxSampleValue }}
                              </v-col>

                              <v-col v-else cols="6">
                                <v-chip label small>
                                  Custom channel - Not matched
                                </v-chip>
                              </v-col>

                              <v-col v-if="item.data && item.data !== ''">
                                Type: {{ item.data.dataType }}
                              </v-col>

                              <v-col v-if="item.data && item.data !== ''">
                                Unit: {{ item.data.unit }}
                              </v-col>
                            </v-row>
                          </v-fade-transition>
                        </v-col>
                      </v-row>
                    </template>

                    <template v-slot:append="{ item }">
                      <span class="d-flex align-center" v-if="item.children">
                        <span class="mr-4">
                          {{ item.mappedCount }} of {{ item.children.length }} channels mapped
                          <v-progress-linear :value="(item.mappedCount / item.children.length) * 100" color="secondary"></v-progress-linear>
                        </span>

                        <ChannelDialog ref="channelDialog" :dataSourceId="item.id" @close="closeActiveChannel" />

                        <v-btn v-if="!activeChannels.length" color="secondary" @click.stop="editDataSource(item.id)" text>
                          <v-icon left>
                            mdi-settings-outline
                          </v-icon>
                          Edit
                        </v-btn>
                      </span>
                    </template>
                  </v-treeview>

                  <v-skeleton-loader v-if="isLoadingChannels" type="table"></v-skeleton-loader>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-scale-transition>
      </v-col>

      <v-slide-x-reverse-transition>
        <v-col v-if="showMappingPanel">
          <div v-for="channel in activeChannels" :key="channel.name">
            <v-row>
              <v-col>
                <v-card>
                  <v-card-title>
                    Data sample
                  </v-card-title>
                  <v-card-subtitle v-if="channel.fileName">
                    From file "{{ channel.fileName }}"
                  </v-card-subtitle>
                  <v-card-text>
                    <v-list dense>
                      <v-list-item>
                        <v-row>
                          <v-col cols="3">
                            Channel
                          </v-col>
                          <v-col class="text-right">
                            {{ channel.data.channel }}
                          </v-col>
                        </v-row>
                      </v-list-item>
                      <v-list-item>
                        <v-row>
                          <v-col cols="3">
                            Description
                          </v-col>
                          <v-col class="text-right">
                            {{ channel.data.description }}
                          </v-col>
                        </v-row>
                      </v-list-item>
                      <v-list-item>
                        <v-row>
                          <v-col cols="3">
                            Data type
                          </v-col>
                          <v-col class="text-right">
                            {{ channel.data.dataType }}
                          </v-col>
                        </v-row>
                      </v-list-item>
                      <v-list-item>
                        <v-row>
                          <v-col cols="3">
                            Unit
                          </v-col>
                          <v-col class="text-right">
                            {{ channel.data.unit || '' }}
                          </v-col>
                        </v-row>
                      </v-list-item>
                      <v-list-item>
                        <v-row>
                          <v-col cols="3">
                            Decimals
                          </v-col>
                          <v-col class="text-right">
                            {{ channel.data.decimals || '' }}
                          </v-col>
                        </v-row>
                      </v-list-item>
                      <v-list-item>
                        <v-row>
                          <v-col cols="3">
                            Minimum
                          </v-col>
                          <v-col class="text-right">
                            {{ channel.data.minSampleValue }}
                          </v-col>
                        </v-row>
                      </v-list-item>
                      <v-list-item>
                        <v-row>
                          <v-col cols="3">
                            Maximum
                          </v-col>
                          <v-col class="text-right">
                            {{ channel.data.maxSampleValue }}
                          </v-col>
                        </v-row>
                      </v-list-item>
                      <v-list-item>
                        <v-row>
                          <v-col cols="3">
                            Minimum scale
                          </v-col>
                          <v-col class="text-right">
                            {{ channel.data.minscale || '-' }}
                          </v-col>
                        </v-row>
                      </v-list-item>
                      <v-list-item>
                        <v-row>
                          <v-col cols="3">
                            Maximum scale
                          </v-col>
                          <v-col class="text-right">
                            {{ channel.data.maxscale || '-' }}
                          </v-col>
                        </v-row>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-card>
                  <v-card-title>
                    Channel mapping
                  </v-card-title>
                  <v-card-text>
                    <v-form ref="form" v-model="valid">
                      <v-text-field v-model="mapping.name" label="Name" :rules="rules.name" required></v-text-field>
                      <v-select v-model="mapping.dataType" :items="$store.state.channelDataTypes" label="Data type" :rules="rules.dataType" required></v-select>
                      <v-text-field v-model="mapping.unit" label="Unit"></v-text-field>
                      <v-text-field v-model="mapping.description" label="Description"></v-text-field>

                      <v-row align="center">
                        <v-col class="text-body-1">
                          Quality score
                        </v-col>

                        <v-col class="text-right">
                          <v-rating v-model="mapping.qualityScore" color="primary" background-color="secondary"></v-rating>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn v-if="channel.mapping" color="error" :loading="deleting" @click="deleteChannel(channel, mapping.id)" text>
                      <v-icon left>
                        mdi-delete-outline
                      </v-icon>
                      Remove mapping
                    </v-btn>

                    <v-spacer></v-spacer>

                    <v-btn @click="closeActiveChannel" text>
                      Close
                    </v-btn>

                    <v-btn v-if="channel.mapping" color="primary" :loading="saving" :disabled="!valid" @click="updateChannel(channel, mapping.id)" text>
                      <v-icon left>
                        mdi-content-save-outline
                      </v-icon>
                      Save
                    </v-btn>

                    <v-btn v-if="!channel.mapping" color="primary" :loading="saving" :disabled="!valid" @click="createChannel(channel)" text>
                      <v-icon left>
                        mdi-content-save-outline
                      </v-icon>
                      Create
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-slide-x-reverse-transition>
    </v-row>
  </v-container>
</template>

<script>
import DatasourceDialog from '@/components/DatasourceDialog.vue'
import EditorWindow from '@/components/EditorWindow.vue'
import ImportConfigDialog from '@/components/ImportConfigDialog.vue'
import ChannelDialog from '@/components/ChannelDialog.vue'

export default {
  name: 'ChannelList',
  components: {
    DatasourceDialog,
    EditorWindow,
    ImportConfigDialog,
    ChannelDialog
  },
  data() {
    return {
      dialog: false,
      saving: false,
      deleting: false,
      isLoadingChannels: false,
      search: null,
      facility: {},
      datasources: [],
      selectedDataSource: null,
      items: [],
      activeChannels: [],
      mappedChannels: [],
      orphanChannels: [],
      showChannelMeta: true,
      valid: false,
      mapping: this.initMapping(),
      rules: {
        name: [
          value => !!value || 'Name is required',
          value => !!value.match(/^[A-Za-z0-9_]*$/g) || 'Name has forbidden characters'
        ],
        dataType: [
          value => !!value || 'You have to select a data type'
        ]
      }
    }
  },
  computed: {
    openAllNodes() {
      return !!this.search;
    },
    showMappingPanel() {
      return this.activeChannels.length ? this.activeChannels[0]?.type !== 'custom' : false;
    }
  },
  methods: {
    closeActiveChannel() {
      this.activeChannels = [];
    },
    editDataSource(sourceId) {
      this.selectedDataSource = this.datasources.find(x => x.id === sourceId);
      delete this.selectedDataSource.channels;
      this.dialog = true;
    },
    closeDataSourceDialog() {
      this.dialog = false;
      this.selectedDataSource = null;
    },
    getMapping(channelName) {
      return this.mappedChannels.find(x => x.source === channelName);
    },
    getOrphans(channels, mappedChannels) {
      let orphans = [];
      const channelSourceNames = channels.map(item => item.channel);

      for (const channel of mappedChannels) {
        if (!channelSourceNames.includes(channel.source)) {
          orphans.push(channel);
        }
      }

      return orphans;
    },
    initMapping() {
      return {
        name: '',
        unit: '',
        description: '',
        dataType: '',
        qualityScore: null
      }
    },
    async getChannels() {
      this.isLoadingChannels = true;

      try {
        const facilityId = this.$route.params.facilityId;
        this.mappedChannels = [];

        const sources = await this.$Services.facilities.getDataSources(facilityId);

        for (const source of sources) {
          const channels = await this.$Services.channels.getAll(source.id);
          const mappedChannels = await this.$Services.channels.getMapped(source.id);

          source.channels = channels;
          source.mappedCount = mappedChannels.length;
          source.orphans = this.getOrphans(channels, mappedChannels);
          this.mappedChannels.push(...mappedChannels);
        }

        this.datasources = sources;

        // Populate items for treeview

        this.items = this.datasources.map(source => ({
          id: source.id,
          name: source.name,
          isPrimary: source.isPrimary,
          reader: String(source.dataSourceReaderName).toUpperCase(),
          mappedCount: source.mappedCount,
          orphans: source.orphans,
          type: 'datasource',
          children: source.orphans.concat(source.channels).map(channel => ({
            id: channel.channel ??  channel.source,
            name: channel.channel ?? channel.source,
            sourceId: source.id,
            fileName: source.sampleFileName,
            type: channel.channel ? 'channel' : 'custom',
            data: channel,
            mapping: channel.channel ? this.getMapping(channel.channel) : null
          }))
        }));

      } catch (error) {
        this.$store.commit('showNotification', { text: 'Something went wrong loading datasources. Please contact us so we can fix the issue.', color: 'error' });
        console.error(error);
      } finally {
        this.isLoadingChannels = false;
      }
    },
    async createChannel(channel) {
      this.saving = true;
      const data = {
        source: channel.name,
        ...this.mapping
      };

      try {
        await this.$Services.channels.create(channel.sourceId, data);
        this.closeActiveChannel();
        this.getChannels();
        this.$store.dispatch('showDefaultSuccess');
      } catch (error) {
        this.$store.dispatch('showDefaultError');
        console.error(error);
      } finally {
        this.saving = false;
      }
    },
    async updateChannel(channel, mappedChannelId) {
      this.saving = true;
      const data = {
        source: channel.name,
        ...this.mapping
      };

      try {
        await this.$Services.channels.update(channel.sourceId, mappedChannelId, data);
        this.closeActiveChannel();
        this.getChannels();
        this.$store.dispatch('showDefaultSuccess');
      } catch (error) {
        this.$store.dispatch('showDefaultError');
        console.error(error);
      } finally {
        this.saving = false;
      }
    },
    async deleteChannel(channel, mappedChannelId) {
      this.deleting = true;

      try {
        await this.$Services.channels.delete(channel.sourceId, mappedChannelId);
        this.closeActiveChannel();
        this.getChannels();
        this.$store.dispatch('showDefaultSuccess');
      } catch (error) {
        this.$store.dispatch('showDefaultError');
        console.error(error);
      } finally {
        this.deleting = false;
      }
    }
  },
  async mounted() {
    this.getChannels();
  },
  watch: {
    activeChannels: {
      handler(newValue) {
        if (newValue.length) {
          const channel = newValue[0];

          this.showChannelMeta = false;

          // If the selected channel has a mapping, open the mapping panel. Else, it must be an orphan
          if (channel.mapping) {
            this.mapping = channel.mapping;
            scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          } else if (channel.type === 'custom') {
            this.$refs.channelDialog.dialog = true;
            this.$refs.channelDialog.setChannel(channel.data);
          }
        } else {
          this.mapping = this.initMapping();
          setTimeout(() => {
            this.showChannelMeta = true;
          }, 200);
        }
      },
      deep: true
    },
    search(newValue) {
      if (newValue) {
        this.$refs.tree.updateAll(true);
      } else {
        this.$refs.tree.updateAll(false);
      }
    }
  },
  provide() {
    return {
      getChannels: this.getChannels
    }
  }
}
</script>

<style scoped>
.v-treeview {
  max-height: 75vh;
  overflow-y: scroll;
}
</style>