<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title class="text-carbon">
              Edit ship
            </v-toolbar-title>

            <v-spacer />

            <v-btn
              :loading="saving"
              :disabled="!valid"
              text
              color="primary"
              @click="save"
            >
              <v-icon left>
                mdi-content-save-outline
              </v-icon>
              Save
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col>
                <v-form v-model="valid" lazy-validation>
                  <v-text-field
                    v-model="facility.name"
                    label="Name"
                    :rules="[ v => !!v || 'Name is required' ]"
                  />
                  <v-text-field
                    v-model="facility.imo"
                    label="IMO"
                  />
                  <v-select
                    v-model="facility.scrubberSystemType"
                    :items="$store.state.scrubberSystemTypes"
                    label="Scrubber system type"
                  />
                  <v-text-field
                    v-model="facility.numberOfScrubbers"
                    label="Number of scrubbers"
                    type="number"
                  />
                  <v-text-field
                    v-model="facility.totalEffectKW"
                    label="Installed power (kW)"
                    type="number"
                  />
                  <v-combobox
                    v-model="facility.systemManufacturer"
                    :items="$store.getters.systemManufacturers"
                    :search-input.sync="search"
                    hide-selected
                    label="System manufacturer"
                    clearable
                    small-chips
                  >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              No results matching "<strong>{{ search }}</strong>". Pres <kbd>enter</kbd> to create a new one.
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                  </v-combobox>
                  <div
                    class="d-flex justify-space-between"
                    style="gap: 2rem"
                  >
                    <v-menu
                      v-model="startDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDate"
                          label="Start date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        @input="startDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                    <v-menu
                      v-model="endDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endDate"
                          label="End date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        @input="endDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </v-form>
              </v-col>
              <v-col cols="4" offset="1">
                <v-file-input
                  label="Upload new image"
                  accept="image/*"
                  hint="Maximum 500 kB"
                  :rules="imageRules"
                  @change="onFileChange($event)"
                  class="mb-3"
                  persistent-hint
                ></v-file-input>
                <v-card class="pa-4">
                  <v-img
                    v-if="facility.imageBase64"
                    :src="facility.imageBase64"
                    :alt="facility.name"
                    :title="facility.name+' - '+ facility.imo"
                    height="300"
                    cover
                  ></v-img>
                  <v-sheet
                    v-else
                    class="d-flex align-center justify-center"
                    color="grey lighten-4"
                    height="300"
                  >
                    <v-icon color="grey lighten-1" x-large>
                      mdi-camera-off
                    </v-icon>
                  </v-sheet>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Transfer ship
          </v-card-title>
          <v-card-text>
            You can transfer this ship to a different organization.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <TransferFacilityDialog />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Automate file upload
          </v-card-title>
          <v-card-text>
            Pure-SPOT Data Uploader (PSDU) is a Powershell-based client capable of automating upload of files from this ship to Pure-SPOT by provisining a preconfigured package, ready to install on the ship.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <ProvisionPSDU :facilityName="facility.name" />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TransferFacilityDialog from '@/components/TransferFacilityDialog.vue'
import ProvisionPSDU from '@/components/ProvisionPSDU.vue'

export default {
  name: 'FacilityEdit',
  components: {
    TransferFacilityDialog,
    ProvisionPSDU
  },
  data() {
    return {
      facility: {},
      loading: false,
      saving: false,
      search: '',
      valid: false,
      imageRules: [
        value => !value || value.size < 500000 || 'Ship image must be less than 500 kB!'
      ],
      startDateMenu: false,
      endDateMenu: false
    }
  },
  computed: {
    startDate: {
      get() {
        if (!this.facility.startDate) return '';

        return this.moment.utc(this.facility.startDate).format('YYYY-MM-DD');
      },
      set(value) {
        this.facility.startDate = this.moment.utc(value).startOf('day').toISOString()
      }
    },
    endDate: {
      get() {
        if (!this.facility.endDate) return '';

        return this.moment.utc(this.facility.endDate).format('YYYY-MM-DD');
      },
      set(value) {
        this.facility.endDate = this.moment.utc(value).startOf('day').toISOString()
      }
    }
  },
  methods: {
    fetchData: async function() {
      try {
        this.loading = true;

        this.facility = await this.$Services.facilities.getFacility(this.$route.params.facilityId);
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.loading = false;
      }
    },
    onFileChange: async function(file) {
      if (!file) return;

      if (file.size > (500 * 1000)) {
        console.debug('File is too big!', file.size);
        return;
      }

      let reader = new FileReader();
      reader.onloadend = () => {
        this.facility.imageBase64 = reader.result;
      };

      reader.readAsDataURL(file);
    },
    save: async function() {
      try {
        this.saving = true;

        // Instead of saving system manufacturer as an empty string, save it as null
        if (this.facility.systemManufacturer === '') {
          this.facility.systemManufacturer = null;
        }

        await this.$Services.facilities.updateFacility(this.$route.params.facilityId, this.facility);

        this.$store.dispatch('getFacilities');
        this.$store.dispatch('showDefaultSuccess');
        this.fetchData();
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.saving = false;
      }
    }
  },
  mounted() {
    this.fetchData();
  }
}
</script>