<template>
  <v-dialog v-model="dialog" max-width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="primary" text>
        <v-icon left>
          mdi-ferry
        </v-icon>

        <v-icon left>
          mdi-arrow-right
        </v-icon>

        Transfer ship
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Transfer ship
      </v-card-title>

      <v-card-text>
        <v-alert type="warning">
          This is a irreversible action. Once you transfer the ship, you will lose access to it.
        </v-alert>

        <v-alert v-if="hasSecondaries" type="info" text>
          This ship is shared with other organizations. These organizations will lose access after the transfer.
        </v-alert>

        <v-select v-model="targetOrganization" :items="organizations" label="Target organization" item-text="name" item-value="id" return-object solo></v-select>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn @click="close" text>
          Cancel
        </v-btn>

        <v-btn color="primary" :loading="saving" :disabled="!targetOrganization" @click="transfer" text>
          Confirm transfer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'TransferFacilityDialog',
  data() {
    return {
      dialog: false,
      saving: false,
      targetOrganization: null,
      hasSecondaries: false
    }
  },
  computed: {
    organizations() {
      const allOrgs = this.$store.getters.organizations;
      const facilityId = this.$route.params.facilityId;

      if (!allOrgs.length) return;

      const ownerIndex = allOrgs.findIndex(org => org.facilities.some(facility => facility.id === facilityId));

      allOrgs.splice(ownerIndex, 1);

      return allOrgs;
    }
  },
  methods: {
    close() {
      this.dialog = false;
    },
    async transfer() {
      this.saving = true;

      try {
        await this.$Services.organizations.migrateFacility(this.targetOrganization.id, this.$route.params.facilityId);

        this.$store.dispatch('showDefaultSuccess', `Ship was transferred to ${this.targetOrganization.name}`);
        this.close();
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.saving = false;
      }
    }
  },
  async mounted() {
    this.$store.dispatch('getOrganizations');

    this.hasSecondaries = await this.$Services.organizations.checkSecondaryOrganizations(this.$route.params.facilityId);
  }
}
</script>