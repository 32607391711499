<template>
  <div id="app">
    <v-app>
      <v-app-bar app dark color="primary" v-if="currentUser">
        <v-toolbar-title>
          <template v-if="currentUser.profile">
            <h2>{{ currentUser.profile.organizationName }}</h2>
          </template>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <!-- Active messages -->
        <MessageCenter />

        <v-toolbar-items>
          <template v-for="(item, i) in navigationItems">
            <v-menu v-if="item.subItems.length" :key="i" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text>
                  {{ item.title }}
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="subItem in item.subItems"
                  :key="subItem.route"
                  :to="subItem.route"
                >
                  {{ subItem.title }}
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn v-else-if="!item.disabled" :to="item.route" :key="i" text>
              {{ item.title }}
            </v-btn>
          </template>
        </v-toolbar-items>
        <v-menu :offset-y="true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="hidden-sm-and-down" text v-bind="attrs" v-on="on">
              <v-icon>mdi-account</v-icon>
              <template
                v-if="
                  currentUser &&
                  currentUser.profile &&
                  currentUser.profile.username
                "
              >
                {{ currentUser.profile.username }}
              </template>
            </v-btn>
          </template>
          <v-list>
            <!-- <v-list-item v-if="this.$store.getters.hasAnyRole(['SystemAdmin'])" to="/settings">
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item> -->
            <v-list-item v-if="isAdmin" :to="`/admin/${$store.getters.hasAnyRole(['SystemAdmin']) ? 'organizations' : $store.getters.userOrganizationId + '/manage'}`">
              <v-list-item-title>Administration</v-list-item-title>
            </v-list-item>
            <v-list-item to="/help">
              <v-list-item-title>Help</v-list-item-title>
            </v-list-item>
            <v-list-item to="/legal">
              <v-list-item-title>Legal</v-list-item-title>
            </v-list-item>
            <v-list-item to="/profile">
              <v-list-item-title>My profile</v-list-item-title>
            </v-list-item>
            <v-list-item v-on:click="logout">
              <v-list-item-title>Log out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-navigation-drawer
        v-if="currentUser"
        app
        dark
        :src="require('@/assets/pq_element-06_nav.png')"
        permanent
      >
        <v-list dense nav class="purebar">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title">
                <a href="/"
                  ><img
                    alt="PureteQ Pure-SPOT"
                    style="height: 35px"
                    src="./assets/logo.png"
                /></a>
              </v-list-item-title>

              <v-menu v-if="$store.getters.environment" open-on-hover :disabled="!$store.getters.environment.html" nudge-bottom="30" max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip class="mt-1 font-weight-bold" v-bind="attrs" v-on="on" :color="$store.getters.environment.color || 'secondary'" label small style="flex: unset; letter-spacing: 1px;">
                    {{ $store.getters.environment.name }}
                  </v-chip>
                </template>

                <v-card>
                  <v-card-title>
                    Disclaimer
                  </v-card-title>
                  <v-card-text v-html="$store.getters.environment.html" />
                </v-card>
              </v-menu>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-content>
              <ReportPeriodPicker />
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item link :to="'/'">
            <v-list-item-icon>
              <v-icon>mdi-chart-donut</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>My fleet</v-list-item-title>
            </v-list-item-content>

            <v-list-item-icon>
              <FleetFilter />
            </v-list-item-icon>
          </v-list-item>

          <v-divider></v-divider>

          <template v-if="$store.state.filteredFacilities.length">
            <v-list-item
              v-for="item in $store.state.filteredFacilities"
              :key="item.id"
              link
              :to="`/ship/${item.id}`"
            >
              <v-list-item-icon>
                <v-icon>mdi-ferry</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle v-if="item.dataTimeStampMax">
                  Latest data:
                  {{ item.dataTimeStampMax | formatDate }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  No data
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-else>
            <v-list-item v-for="i in 10" :key="i">
              <v-list-item-icon>
                <v-icon>mdi-ferry</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-skeleton-loader type="sentences"></v-skeleton-loader>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>

      <v-main>
        <CookieDialog v-if="showCookieConsent" key="cookie" />
        <Loading v-else-if="loading" key="loading" />
        <h1 v-else-if="!currentUser" style="visibility:hidden;" key="noUser">YOU CANNOT PASS!</h1>
        <router-view v-else :key="$route.fullPath" />

        <notification :show="$store.state.showNotification" :text="$store.state.notificationText"></notification>
      </v-main>

      <!-- footer -->
      <v-footer color="#e7e8e8">
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <div class="text-carbon">PureteQ A/S | +45 62 21 27 87</div>
        <v-spacer></v-spacer>
        <div class="text-caption">
          Powered by IPA innovation | Version {{ appVersion }} - {{ appBuildNumber }}   
        </div>
      </v-footer>

      <UpdateNotification />
      <Offline />
    </v-app>
  </div>
</template>

<script>
import Notification from './components/Notification.vue';
import ReportPeriodPicker from './components/ReportPeriodPicker.vue';
import FleetFilter from '@/components/FleetFilter.vue';
import Loading from './components/Loading.vue';
import CookieDialog from './components/CookieDialog.vue';
import * as Sentry from "@sentry/vue";
import MessageCenter from '@/components/MessageCenter.vue';
import Offline from '@/components/Offline.vue';

export default {
  name: 'App',
  components: {
    Notification,
    ReportPeriodPicker,
    FleetFilter,
    Loading,
    CookieDialog,
    MessageCenter,
    Offline
  },
  data() {
    return {
      appDeployStage: window.settings.stageDisplayName,
      appSourceVersion: window.settings.sourceVersion,
      appSourceBranch: window.settings.sourceBranch,
      appBuildNumber: window.settings.buildNumber,
      appVersion: window.settings.version,
      currentUser: null,
      loading: false,
      profileNavigationItems: [
        {
          title: 'Settings',
          route: '/settings',
          disabled: true,
          subItems: [],
        },
        {
          title: 'Administration',
          route: '/admin',
          disabled: false,
          subItems: []
        },
        {
          title: 'Help',
          route: '/help',
          disabled: false,
          subItems: [],
        },
        {
          title: 'Log out',
          route: '/logoff',
          disabled: false,
          subItems: [],
        },
      ],
      ships: [],
      measurementFieldInterval: null,
      executionSync: null,
      showCookieConsent: false
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters.hasAnyRole(['SystemAdmin', 'UserAdmin']);
    },
    navigationItems() {
      return [
        {
          title: 'Service',
          route: '/service',
          disabled: false,
          subItems: [],
        },
        {
          title: 'Ships',
          route: '/facilities',
          disabled: false,
          subItems: []
        },
        {
          title: 'Report Configurator',
          route: '/report-config',
          disabled: !this.$store.getters.hasAnyRole('SystemAdmin'),
          subItems: [],
        }
      ]
    }
  },
  async created() {
    this.loading = true;

    try {
      const user = await this.$Services.auth.getUser();
      const hasAcceptedCookies = localStorage.getItem('hasAcceptedCookies');

      if (!JSON.parse(hasAcceptedCookies)) {
        this.showCookieConsent = true;
        return;
      }

      if (!user) {
        this.$Services.auth.login();
        return;
      }

      /**
       * Check that the token is not expired.
       * If it is, trigger a login.
       */
      if (user.expires_at && this.isExpired(user.expires_at)) {
        console.log('User is expired. Trying to sign in.')
        this.$Services.auth.login();
        return;
      }

      await this.init(user);
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    async init(user) {
      this.$store.commit('setUser', user);
      Sentry.setUser({ email: user.profile?.username });
      Sentry.setTag('priority_organization', user.profile?.organizationPriority);
      this.currentUser = user;

      this.ships = await this.$store.dispatch('getFacilities');
      this.$store.commit('setCurrentFacility', this.ships[0]);

      this.$store.dispatch('getMessages');

      // If the app is initialized after a login, check if there is a redirect path after login
      const redirectPath = localStorage.getItem('pt-redirectAfterLoginPath');

      if (redirectPath) {
        this.$router.replace(redirectPath);
        localStorage.removeItem('pt-redirectAfterLoginPath');
      }

      this.startSync();
    },
    logout() {
      clearInterval(this.measurementFieldSync);
      clearInterval(this.executionSync);
      this.$Services.auth.logout();
    },
    startSync() {
      // Maintenance mode
      setInterval(() => {
        this.$store.dispatch('checkMaintenanceMode');
      }, 300 * 1000);
    },
    isExpired(userExpiresAt = 0) {
      const now = Math.floor(new Date().getTime() / 1000); // Get epoch in seconds without moment

      return userExpiresAt < now;
    }
  }
};
</script>