<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          Pending {{ modeDisplay }} recalculations
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn :disabled="!canRunActionsOnUnqueued" @click="removeCalculations" text>
          <v-icon left>
            mdi-delete-outline
          </v-icon>

          Remove from queue
        </v-btn>

        <v-btn :disabled="!canRunActionsOnQueued" @click="cancelCalculations" text>
          <v-icon left>
            mdi-close-circle-outline
          </v-icon>

          Cancel execution
        </v-btn>

        <v-btn color="primary" :disabled="!canRunActionsOnUnqueued" :loading="executing" @click="executeCalculations">
          <v-icon left>
            mdi-run
          </v-icon>

          Execute
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-data-table v-model="selected" :headers="headers" :items="items" :loading="loading" item-key="id" sort-by="recalculationRequestedOn" no-data-text="No pending recalculations" show-select>
          <template v-slot:[`item.version`]="{ item }">
            {{ item.version }}.{{ item.revision }}
          </template>

          <template v-slot:[`item.recalculationRequestedOn`]="{ item }">
            {{ item.recalculationRequestedOn | formatDateTime }}
          </template>

          <template v-slot:[`item.recalculationQueuedOn`]="{ item }">
            {{ item.recalculationQueuedOn | formatDateTime }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'CalculationQueue',
  props: {
    mode: String
  },
  data() {
    return {
      selected: [],
      loading: false,
      removing: false,
      cancelling: false,
      executing: false,
      headers: [],
      items: []
    }
  },
  computed: {
    modeDisplay() {
      switch (this.mode) {
        case 'reportField':
          return 'report field';

        case 'measurementField':
          return 'measurement field';
        
        default:
          return '';
      }
    },
    canRunActionsOnUnqueued() {
      return this.selected.length > 0 && !this.selected.some(x => !!x.recalculationQueuedOn);
    },
    canRunActionsOnQueued() {
      return this.selected.length > 0 && !this.selected.some(x => !x.recalculationQueuedOn);
    }
  },
  methods: {
    async fetchData() {
      this.loading = true;

      try {
        if (this.mode === 'reportField') {
          const calculations = await this.$Services.calculations.getRequestedReportFieldCalculations();

          this.items = calculations.list;
        }

        else if (this.mode === 'measurementField') {
          const calculations = await this.$Services.calculations.getRequestedMeasurementFieldCalculations(this.$route.params.facilityId);

          this.items = calculations.list;
        }

        else {
          throw ('Prop mode must bt either \'reportField\' or \'measurementField\'!');
        }

      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.loading = false;
      }
    },
    async removeCalculations() {
      this.removing = true;

      const ids = this.selected.map(calculation => calculation.id);

      try {
        if (this.mode === 'reportField') {
          await this.$Services.calculations.removeRequestedReportFieldCalculation(ids);
        }

        else if (this.mode === 'measurementField') {
          await this.$Services.calculations.removeRequestedMeasurementFieldCalculation(ids);
        }

        else {
          throw ('Prop mode must bt either \'reportField\' or \'measurementField\'!');
        }

        this.$store.dispatch('showDefaultSuccess', `${ids.length} recalculation${ids.length > 1 ? 's' : ''} removed from queue`);
        this.selected = [];
        this.fetchData();
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.removing = false;
      }
    },
    async cancelCalculations() {
      this.cancelling = true;

      const ids = this.selected.map(calculation => calculation.id);

      try {
        if (this.mode === 'reportField') {
          await this.$Services.calculations.cancelRequestedReportFieldCalculation(ids);
        }

        else if (this.mode === 'measurementField') {
          await this.$Services.calculations.cancelRequestedMeasurementFieldCalculation(ids);
        }

        else {
          throw ('Prop mode must bt either \'reportField\' or \'measurementField\'!');
        }

        this.$store.dispatch('showDefaultSuccess', `${ids.length} recalculation${ids.length > 1 ? 's' : ''} cancelled in queue`);
        this.selected = [];
        this.fetchData();
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.cancelling = false;
      }
    },
    async executeCalculations() {
      this.executing = true;

      const mode = this.mode === 'reportField' ? 'report-fields' : 'measurement-fields'
      const ids = this.selected.map(calculation => calculation.id);

      try {
        await this.$Services.calculations.executeRecalculation(mode, ids);

        this.$store.dispatch('showDefaultSuccess', `${ids.length} recalculation${ids.length > 1 ? 's' : ''} queued for execution`);
        this.selected = [];
        this.fetchData();
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.executing = false;
      }
    }
  },
  mounted() {
    this.headers = [
      {
        text: 'Measurement field',
        value: 'fieldName'
      },
      {
        text: 'Calculation version',
        value: 'version'
      },
      {
        text: 'Requested on',
        value: 'recalculationRequestedOn'
      },
      {
        text: 'Queued on',
        value: 'recalculationQueuedOn',
        align: 'end'
      }
    ];

    if (this.mode === 'reportField') {
      this.headers[0] = { text: 'Report', value: 'reportName' };
      this.headers.splice(1, 0, { text: 'Report field', value: 'fieldName' });
    }

    this.fetchData();
  }
}
</script>