import ApiUrlFactory from './ApiUrlFactory';

export default class MeasurementFieldsService {
    baseUrl = 'algorithm/measurement-fields';

    constructor(api, store) {
        this._api = api;
        this._store = store;
    }

    async getAll() {
        const response = await this._api(this.baseUrl);
        return response.data.list;
    }

    async getAllForFacility(facilityId) {
        const response = await this._api(`${this.baseUrl}/facility/${facilityId}`);
        return response.data.list;
    }

    async getById(id) {
        const response = await this._api(`${this.baseUrl}/${id}`);
        return response.data;
    }

    async deleteValues(facilityId, from, to) {
        const response = await this._api.delete(`${this.baseUrl}/values?facilityId=${facilityId}&from=${from}&to=${to}`);
        return response.data;
    }
}
