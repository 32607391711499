<template>
  <v-container class="mt-n16" fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-banner single-line>
            <v-row no-gutters align="center">
              <v-col>
                <div class="d-flex align-center">
                  {{ selectedFiles.length === 1 ? '1 file' : `${selectedFiles.length} files` }} selected
                  <v-btn class="ml-2" v-show="selectedFiles.length" @click="selectedFiles = []" small icon>
                    <v-icon small>
                      mdi-close-circle-outline
                    </v-icon>
                  </v-btn>
                </div>
              </v-col>

              <v-col>
                <v-fade-transition>
                  <div class="d-flex align-center text-body-2" v-if="fromDate && toDate">
                    <div class="mr-2">
                      Execution period:
                    </div>

                    <v-chip label small outlined>
                      {{ fromDate }} {{ fromTime }} <v-icon class="mx-1" small>mdi-arrow-right</v-icon> {{ toDate }} {{ toTime }}
                    </v-chip>

                    <v-menu v-model="editPeriod" :close-on-content-click="false" offset-y nudge-left="550" nudge-bottom="10">
                      <template v-slot:activator="{ attrs, on }">
                        <v-btn class="ml-2" v-bind="attrs" v-on="on" small icon>
                          <v-icon small>
                            mdi-pencil
                          </v-icon>
                        </v-btn>
                      </template>

                      <v-card width="820">
                        <v-card-text>
                          <p>
                            Modify the execution period
                          </p>
                          <v-row align="center">
                            <v-col>
                              <v-menu v-model="showFromDate" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field v-model="fromDate" label="Date" prepend-icon="mdi-calendar" :rules="[value => !!value || 'Not filled out']" readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="fromDate" @input="showFromDate = false" no-title :max="toDate"></v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col>
                              <v-text-field v-model="fromTime" label="Time" prepend-icon="mdi-clock-outline" type="time"></v-text-field>
                            </v-col>
                            <v-col class="text-center" cols="1">
                              <v-icon>
                                mdi-arrow-right
                              </v-icon>
                            </v-col>
                            <v-col>
                              <v-menu v-model="showToDate" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field v-model="toDate" label="Date" prepend-icon="mdi-calendar" :rules="[value => !!value || 'Not filled out']" readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="toDate" @input="showToDate = false" :min="fromDate"></v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col>
                              <v-text-field v-model="toTime" label="Time" prepend-icon="mdi-clock-outline" type="time" :min="fromDate === toDate ? fromTime : ''"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn @click="editPeriod = false" text>
                            Done
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </div>
                </v-fade-transition>
              </v-col>
            </v-row>

            <template v-slot:actions>
              <v-dialog v-model="confirmDeleteDialog" width="500">
                <template v-slot:activator="{ attrs, on }">
                  <v-btn v-bind="attrs" v-on="on" :disabled="!selectedFiles.length" text>
                    <v-icon left>
                      mdi-delete-outline
                    </v-icon>

                    Delete
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>
                    Are you sure?
                  </v-card-title>

                  <v-card-text>
                    <div class="text-body-1">
                      You are about to delete <b>{{ selectedFiles.length }}</b> files.
                    </div>

                    <v-checkbox class="mt-3" v-model="alsoDeleteData" label="Also delete associated data" dense></v-checkbox>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn @click="confirmDeleteDialog = false; alsoDeleteData = false;" text>
                      Cancel
                    </v-btn>

                    <v-btn color="warning" :loading="deleting" @click="deleteFiles" text>
                      <template v-slot:loader>
                        <div class="d-flex flex-column">
                          <span>
                            Deleting {{ currentDeleteIndex + 1 }} of {{ selectedFiles.length }}
                          </span>
                          <v-progress-linear :value="deleteProgress" color="primary"></v-progress-linear>
                        </div>
                      </template>

                      <v-icon left>
                        mdi-delete-outline
                      </v-icon>

                      Yes, delete
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-btn color="primary" :disabled="!canExecute" :loading="executing" @click="execute" text>
                <v-icon left>
                  mdi-run
                </v-icon>

                Run
              </v-btn>
            </template>
          </v-banner>

          <v-card-text>
            <v-row>
              <v-col>
                <v-treeview ref="fileTree" v-model="selectedFiles" v-if="dataSources.length" :items="dataSources" :loading="loading" item-children="files" item-disabled="disabled" return-object selectable open-on-click hoverable>
                  <template v-slot:label="{ item, leaf }">
                    <v-list-item :two-line="leaf">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.name }}
                        </v-list-item-title>

                        <v-list-item-subtitle class="d-flex align-center" v-if="leaf">
                          {{ item.timeStampMin | formatDateTime }}

                          <v-icon x-small left right>
                            mdi-arrow-right
                          </v-icon>

                          {{ item.timeStampMax | formatDateTime }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <template v-slot:append="{ item }">
                    <v-btn v-if="item.files" color="primary" @click.stop="openAddFileDialog(item)" text>
                      <v-icon left>
                        mdi-plus
                      </v-icon>
                      Add file
                    </v-btn>

                    <v-chip v-if="item.importedOn" color="success" small>
                      <v-icon left small>
                        mdi-check
                      </v-icon>

                      Imported {{ item.importedOn ? `on ${$options.filters.formatDateTime(item.importedOn)}` : '' }}
                    </v-chip>
                  </template>
                </v-treeview>

                <div class="d-flex align-center justify-space-between" v-else-if="!loading && !dataSources.length">
                  <div class="text-body-1">
                    No data sources added.
                  </div>

                  <div
                    v-if="$store.getters.hasAnyRole('SystemAdmin')"
                  >
                    <v-btn color="primary" to="config/channels" text>
                      Go to data sources
                      <v-icon right>
                        mdi-arrow-right
                      </v-icon>
                    </v-btn>
                  </div>
                </div>

                <v-skeleton-loader v-else type="table"></v-skeleton-loader>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-alert v-model="showExecuteSuccess" type="success" dismissible transition="fade" elevation="2">
          <v-row>
            <v-col class="font-weight-bold">
              Execution successful!
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              It's running in the background, so it might take a little time before the results are visible.
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-card-title>
          Upload file
        </v-card-title>

        <v-card-text>
          <p>
            You can either upload a single file or a .zip archive.
          </p>

          <v-file-input class="mt-4" v-model="file" :accept="dataSource.dataSourceReaderAcceptedFileExtensionsByComma || '*'" :label="`Choose file (${acceptDisplayString})`" solo></v-file-input>

          <v-scroll-y-transition>
            <v-alert v-if="successCount" :type="successCount ? 'success' : 'info'">
              {{ successCount }} {{ successCount === 1 ? 'file' : 'files' }} imported
            </v-alert>
          </v-scroll-y-transition>

          <v-scroll-y-transition group>
            <v-alert v-for="(error, index) of errors" :key="index" type="error" text>
              {{ error }}
            </v-alert>
          </v-scroll-y-transition>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn @click="closeAddFileDialog" text>
            Close
          </v-btn>
          <v-btn color="primary" @click="upload" :loading="uploading" text>
            <v-icon left>
              mdi-upload-outline
            </v-icon>
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'
import urlEncode from '@/mixins'

export default {
  name: 'FacilityFiles',
  data() {
    return {
      valid: false,
      loading: false,
      deleting: false,
      uploading: false,
      executing: false,
      showExecuteSuccess: false,
      editPeriod: false,
      fromDate: '',
      fromTime: '',
      toDate: '',
      toTime: '',
      showFromDate: null,
      showFromTime: null,
      showToDate: null,
      showToTime: null,
      dialog: false,
      confirmDeleteDialog: false,
      alsoDeleteData: false,
      currentDeleteIndex: 0,
      file: null,
      selectedFiles: [],
      dataSource: '',
      dataSources: [],
      headers: [{ text: 'Name', value: 'name' }],
      successCount: null,
      errors: []
    };
  },
  mixins: [
    urlEncode
  ],
  computed: {
    acceptDisplayString() {
      return this.dataSource?.dataSourceReaderAcceptedFileExtensionsByComma?.replace(',', ', ');
    },
    canExecute() {
      return this.fromDate && this.fromTime && this.toDate && this.toTime && this.selectedFiles.length;
    },
    deleteProgress() {
      return this.selectedFiles.length ? (100 / this.selectedFiles.length) * this.currentDeleteIndex : 0;
    }
  },
  methods: {
    openAddFileDialog(dataSource) {
      this.dataSource = dataSource;
      this.dialog = true;
    },
    closeAddFileDialog() {
      this.dialog = false;
      this.successCount = null;
      this.errors = [];
      this.file = null;
    },
    async upload() {
      this.uploading = true;
      this.successCount = null;
      this.errors = [];

      try {
        const response = await this.$Services.facilities.uploadDataFiles(this.dataSource.id, this.file);

        if (response.ids?.length) {
          this.successCount = response.ids.length;
        }

        if (response.errorMessages?.length) {
          this.errors = response.errorMessages;
        } else {
          this.closeAddFileDialog();
          this.$store.dispatch('showDefaultSuccess');
          this.getDataSources();
        }
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.uploading = false;
      }
    },
    async execute() {
      // Validate first
      if (!this.canExecute) return;

      this.executing = true;
      const facilityId = this.$route.params.facilityId;
      const from = `${this.fromDate}T${this.fromTime}:00.000Z`;
      const to = `${this.toDate}T${this.toTime}:59.999Z`;
      const fileIds = this.selectedFiles.map(file => file.id);

      try {
        this.$Services.calculations.executeMeasurementField(facilityId, from, to, fileIds);

        this.selectedFiles = [];
        this.$refs.fileTree.updateAll(false);
        this.showExecuteSuccess = true;
        this.$refs.periodForm?.reset();
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.executing = false;
      }
    },
    async getDataSources() {
      try {
        this.loading = true;
        const facilityId = this.$route.params.facilityId;

        const sources = await this.$Services.facilities.getDataSources(facilityId);

        for (const source of sources) {
          source.files = await this.$Services.facilities.getDataFiles(source.id);
          source.disabled = !source.files.length;

          // Hack to make treeview work
          for (const file of source.files) {
            file.name = file.fileName;
          }
        }

        this.dataSources = sources;
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.loading = false;
      }
    },
    /**
     * Delete the selected files.
     * If the user also wants to delete data, do that first and then delete the files if it was successful.
     */
    async deleteFiles() {
      const facilityId = this.$route.params.facilityId;
      this.deleting = true;

      try {
        for (let index = 0; index < this.selectedFiles.length; index++) {
          const file = this.selectedFiles[index];

          if (this.alsoDeleteData) {
            const from = this.encodeDate(file.timeStampMin);
            const to = this.encodeDate(file.timeStampMax);

            // Delete measurement field values
            await this.$Services.measurementFields.deleteValues(facilityId, from, to);

            // Delete report field values
            await this.$Services.reports.deleteValues(facilityId, from, to);
          }

          await this.$Services.facilities.deleteDataFile(facilityId, file.id);

          this.currentDeleteIndex++;
        }

        this.confirmDeleteDialog = false;
        this.getDataSources();

        this.$store.dispatch('showDefaultSuccess', 'Files were deleted successfully!');
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.currentDeleteIndex = 0;
        this.deleting = false;
      }
    }
  },
  created: async function () {
    this.getDataSources();
  },
  watch: {
    selectedFiles(files) {
      if (files.length) {
        // Update period to min and max of selected files
        const minimums = files.map(file => moment(file.timeStampMin));
        const maximums = files.map(file => moment(file.timeStampMax));

        const from = moment.min(minimums);
        const to = moment.max(maximums);

        this.fromDate = from.utc().format('YYYY-MM-DD');
        this.fromTime = from.utc().format('HH:mm');
        this.toDate = to.utc().format('YYYY-MM-DD');
        this.toTime = to.utc().format('HH:mm');
      } else {
        this.fromDate = null, this.fromTime = null, this.toDate = null, this.toTime = null;
        this.$refs.periodForm?.resetValidation();
      }
    }
  }
};
</script>