<template>
  <v-btn
    v-if="$store.getters.hasAnyRole(['SystemAdmin'])"
    tile
    outlined
    text
    small
    @click="navigateToExport"
  >
    <v-icon small>
      mdi-download
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'ExportShortcut',
  props: {
    facilityId: {
      type: String,
      default: ''
    },
    reportCode: {
      type: String,
      default: ''
    }
  },
  methods: {
    navigateToExport() {
      let query = {
        reportCode: this.reportCode
      };

      if (this.facilityId) {
        query = Object.defineProperty(query, 'facilityId', {
          value: this.facilityId,
          enumerable: true
        });
      }

      this.$router.push({
        path: '/report-config/data-export',
        query
      })
    }
  }
}
</script>