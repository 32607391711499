import ApiUrlFactory from './ApiUrlFactory';

export default class CalculationService {
    baseUrl = 'algorithm/calculations';

    constructor(api, store) {
      this._api = api;
      this._store = store;
    }

    /**
     * Validation response
     * @typedef {Object} ValidationResponse
     * @property {number} code - Response code
     * @property {string[]} errors - If the code is not valid, an array of errors are included
     */

    /**
     * Validate the syntax of a report field calculation
     * @param {string} code - The code to be validated
     * @param {string[]} inputIds - IDs of measurement fields used in the algorithm
     * @return {ValidationResponse} - The response
     */

    async validateReportField(code, inputIds) {
      const response = await this._api.post(`${this.baseUrl}/report-field/validate-syntax`, { transformAlgorithm: code, inputIds });
      return response.data;
    }

    /**
     * Test an algoritm with test data and get real results back
     * @param {string} transformAlgorithm - The code to be validated
     * @param {Object} inputData 
     * @returns {Response}
     */

    async testReportField(data) {
      const response = await this._api.post(`${this.baseUrl}/report-field/validate-logic`, data);
      return response.data;
    }

    /**
     * Validate the syntax of a measurement field calculation
     * @param {string} code - The code to be validated
     * @param {string[]} inputIds - IDs of measurement fields used in the algorithm
     * @return {ValidationResponse} - The response
     */

    async validateMeasurementField(data) {
      const response = await this._api.post(`${this.baseUrl}/measurement-field/validate-syntax`, data);
      return response.data;
    }

    /**
     * Test a calculation with test data and get real results back
     * @param {string} transformAlgorithm - The code to be validated
     * @param {Object} inputData
     * @returns {Response}
     */

    async testMeasurementField(data) {
      const response = await this._api.post(`${this.baseUrl}/measurement-field/validate-logic`, data);
      return response.data;
    }

    async executeMeasurementField(facilityId, from, to, facilityDataSourceFileIds) {
      const response = await this._api.post(`${this.baseUrl}/measurement-field/execute`, { facilityId, from, to, facilityDataSourceFileIds } );
      return response.data;
    }

    /**
     * Get the calculation status for each facility that has a calculation being executed
     */

    async getCalculationStatus() {
      const response = await this._api.get(this.baseUrl);
      return response.data;
    }

    async getRequestedMeasurementFieldCalculations(facilityId) {
      const response = await this._api(`${this.baseUrl}/measurement-fields?recalculationRequested=true&facilityId=${facilityId}`);
      return response.data;
    }

    async getRequestedReportFieldCalculations() {
      const response = await this._api(`${this.baseUrl}/report-fields?recalculationRequested=true`);
      return response.data;
    }

    async removeRequestedMeasurementFieldCalculation(ids) {
      const response = await this._api({
        method: 'delete',
        url: `${this.baseUrl}/measurement-fields/recalculation`,
        data: {
          ids
        }
      });

      return response.data;
    }

    async cancelRequestedMeasurementFieldCalculation(ids) {
      const response = await this._api({
        method: 'post',
        url: `${this.baseUrl}/measurement-fields/recalculation/cancel`,
        data: {
          ids
        }
      });

      return response.data;
    }

    async removeRequestedReportFieldCalculation(ids) {
      const response = await this._api({
        method: 'delete',
        url: `${this.baseUrl}/report-fields/recalculation`,
        data: {
          ids
        }
      });

      return response.data;
    }

    async cancelRequestedReportFieldCalculation(ids) {
      const response = await this._api({
        method: 'post',
        url: `${this.baseUrl}/report-fields/recalculation/cancel`,
        data: {
          ids
        }
      });

      return response.data;
    }

    async executeRecalculation(mode, calculationIds) {
      if (!mode) {
        throw ('You have to specify a mode (\'measurement-field\' or \'report-field\')!');
      }

      const response = await this._api.post(`${this.baseUrl}/${mode}/recalculation/execute`, {
        ids: calculationIds
      });

      return response.data;
    }
}
