var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"elevation":"2","loading":_vm.loading ? '#757575' : false}},[_c('v-card-title',[_c('div',[_vm._v(" SO"),_c('sub',{staticClass:"pr-2"},[_vm._v("2")]),_vm._v(" / CO"),_c('sub',{staticClass:"pr-2"},[_vm._v("2")]),_vm._v(" RATIO RELATIVE TO LIMITS ")]),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center justify-space-between",staticStyle:{"gap":".5rem"}},[_c('ExportShortcut',{attrs:{"reportCode":"FacilityGroupSO2CO2Ratio"}}),(_vm.report.information)?_c('WidgetInfo',{attrs:{"title":"SO2/CO2 ratio relative to limits","content":_vm.report.information}}):_vm._e()],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"align-self-end text-carbon",attrs:{"align":"center","cols":"4","xs":"12","sm":"12","md":"12","lg":"5"}},[_c('div',{staticStyle:{"height":"235px","width":"100%","margin-bottom":"10px"}},[_c('vue-speedometer',{staticClass:"so2co2-speedo",attrs:{"forceRender":true,"width":400,"height":250,"paddingVertical":4,"paddingHorizontal":6,"labelFontSize":"16px","valueTextFontSize":"30px","minValue":100,"maxValue":-30,"segments":4,"customSegmentStops":[100,50,10,0,-30],"segmentColors":["#757575","#00B697","#F2BF0D", "#f00"],"customSegmentLabels":[
              {
                text: 'Over-scrubbing',
                position: 'OUTSIDE',
                color: '#000000de',
              },
              {
                text: 'Optimal',
                position: 'OUTSIDE',
                color: '#000000de',
              },
              {
                text: 'Risk',
                position: 'OUTSIDE',
                color: '#000000de',
              },
              {
                text: 'Under-scrubbing',
                position: 'OUTSIDE',
                color: '#000000de',
              },
            ],"needleColor":"#9E9E9E","value":_vm.speedometer.needle,"currentValueText":_vm.loading ? ' ' : `${_vm.speedometer.actual ? `${_vm.speedometer.actual}%` : 'No data'}`}})],1)]),_c('v-col',{attrs:{"cols":"8","xs":"12","sm":"12","md":"12","lg":"7"}},[_c('FleetSO2CO2RatioChart',{attrs:{"ships":_vm.chartData}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4","xs":"12","sm":"12","md":"12","lg":"5"}},[_c('p',{staticClass:"text-center"},[_vm._v(" Average SO"),_c('sub',[_vm._v("2")]),_vm._v("/CO"),_c('sub',[_vm._v("2")]),_vm._v(" ratio relative to limits ")])]),_c('v-col',{attrs:{"cols":"8","xs":"12","sm":"12","md":"12","lg":"7"}},[_c('p',{staticClass:"text-center"},[_vm._v(" SO"),_c('sub',[_vm._v("2")]),_vm._v("/CO"),_c('sub',[_vm._v("2")]),_vm._v(" ratio relative to applicable limits (4.3 or 21.7) for each vessel. ")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }