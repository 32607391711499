<template>
  <v-card class="mb-4" flat outlined>
    <v-card-title>
      {{ title }}
      
      <v-spacer></v-spacer>

      <!-- <v-btn @click="$emit('edit')" text>
        <v-icon left>mdi-pencil-outline</v-icon>
        Edit
      </v-btn> -->

      <v-dialog v-model="confirmDeleteDialog" width="500">
        <template v-slot:activator="{ attrs, on }">
          <v-btn v-bind="attrs" v-on="on" @click="$emit('delete')" text>
            <v-icon left>mdi-delete-outline</v-icon>
            Delete
          </v-btn>
        </template>

        <v-card>
          <v-card-title>
            Are you sure?
          </v-card-title>

          <v-card-text>
            Users will no longer be able to see it and records of who has seen it will be deleted as well.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn @click="confirmDeleteDialog = false" text>
              No, keep it
            </v-btn>

            <v-btn :loading="deleting" color="warning" @click="deleteMessage" text>
              Yes, delete message
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-title>

    <v-card-subtitle>
      {{ when }}
    </v-card-subtitle>

    <v-card-text>
      {{ text }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'MessageListItem',
  props: {
    id: String,
    title: String,
    showFrom: String,
    showTo: String,
    text: String,
    type: {
      required: true,
      validator: (value) => {
        return [ 'active', 'upcoming', 'past' ].indexOf(value) !== -1
      }
    }
  },
  data () {
    return {
      confirmDeleteDialog: false,
      deleting: false
    }
  },
  computed: {
    when () {
      let context = '';

      switch (this.type) {
        case 'active':
          context = `ends ${this.moment.utc(this.showTo).fromNow()}`;
          break;

        case 'upcoming':
          context = `starts ${this.moment.utc(this.showFrom).fromNow()}`;
          break;

        case 'past':
          context = `ended ${this.moment.utc(this.showTo).toNow(true)} ago`
          break;

        default:
          break;
      }

      return `${this.$options.filters.formatDateTime(this.showFrom)} - ${this.$options.filters.formatDateTime(this.showTo)} (${context})`;
    }
  },
  methods: {
    async deleteMessage () {
      this.deleting = true;

      await this.$store.dispatch('deleteMessage', this.id);

      this.deleting = false;
    }
  }
}
</script>