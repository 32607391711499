export default class TempDataService {

    constructor(api) {
        this._api = api;
        this._url = 'tempdata';
    }

    async getFleetJson() {
        const response = await this._api(`${this._url}?fileName=fleet.json`);
        return response.data;
    }

    async getFleetInformation() {
        const response = await this._api(`${this._url}?fileName=fleetinformation.json`);
        return response.data;
    }

    async getFleetReports() {
        const response = await this._api(`${this._url}?fileName=fleetreports.json`);
        return response.data;
    }

    async getShipReports(shipId) {
        const response = await this._api(`${this._url}?fileName=ship${shipId}reports.json`);
        return response.data;
    }

    async getShipDocumentation(shipId) {
        const response = await this._api(`${this._url}?fileName=ship${shipId}documentation.json`);
        return response.data;
    }

    async getShip(shipId) {
        const response = await this._api(`${this._url}?fileName=ship${shipId}.json`);
        return response.data;
    }

    async getDatasources() {
        const response = await this._api(`${this._url}?fileName=channel_mapper_first.json`);
        return response.data.dataSources;
    }
}
