import ApiUrlFactory from './ApiUrlFactory';

export default class MeasurementFieldCalculationService {
    baseUrl = 'algorithm/facility';

    constructor(api, store) {
        this._api = api;
        this._store = store;
    }

    async getAll(facilityId, measurementFieldId) {
        const response = await this._api(`${this.baseUrl}/${facilityId}/measurement-field/${measurementFieldId}/calculations`);
        return response.data;
    }

    async getById(facilityId, measurementFieldId, calculationId) {
        const response = await this._api(`${this.baseUrl}/${facilityId}/measurement-field/${measurementFieldId}/calculations/${calculationId}`);
        return response.data;
    }

    async create(facilityId, measurementFieldId, data) {
        const response = await this._api.post(`${this.baseUrl}/${facilityId}/measurement-field/${measurementFieldId}/calculations`, data);
        return response.data;
    }

    async update(facilityId, measurementFieldId, calculationId, recalculate, data) {
        const response = await this._api.put(`${this.baseUrl}/${facilityId}/measurement-field/${measurementFieldId}/calculations/${calculationId}?recalculate=${recalculate}`, data);
        return response.data;
    }

    /**
     * Approve a measurement field calculation
     * @param {string} facilityId 
     * @param {string} measurementFieldId 
     * @param {string} calculationId 
     * @param {object} data - { validFrom: Date, validTo: Date }
     * @returns 
     */
    async approve(facilityId, measurementFieldId, calculationId, data) {
        const response = await this._api.put(`${this.baseUrl}/${facilityId}/measurement-field/${measurementFieldId}/calculations/${calculationId}/approve`, data);
        return response.data;
    }

    async delete(facilityId, measurementFieldId, calculationId) {
        const response = await this._api.delete(`${this.baseUrl}/${facilityId}/measurement-field/${measurementFieldId}/calculations/${calculationId}`);
        return response.data;
    }
}
