<template>
  <v-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ attrs, on }">
      <v-btn class="text-button" v-bind="attrs" v-on="on" :disabled="!fields.length" text>
        <v-icon left>
          mdi-check-all
        </v-icon>
        Approve all
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Approve {{ fields.length }} measurement field calculations
      </v-card-title>

      <v-card-text>
        <p>
          Specify the period in which the calculations are valid.
        </p>

        <p>
          If no end date is set, the calculations will be valid forever unless other calculations are approved.
        </p>

        <p>
          Only measurement fields with a calculation will be approved.
        </p>

        <v-form ref="form" v-model="valid">
          <v-menu ref="validFromMenu" v-model="validFromMenu" transition="scale-transition" offset-y min-width="auto" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="validFrom" label="Valid from" prepend-icon="mdi-calendar-outline" :rules="rules.validFrom" v-bind="attrs" v-on="on" clearable></v-text-field>
            </template>
            <v-date-picker v-model="validFrom" no-title @input="validFromMenu = false"></v-date-picker>
          </v-menu>

          <v-menu ref="validToMenu" v-model="validToMenu" transition="scale-transition" offset-y min-width="auto" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="validTo" label="Valid to (optional)" prepend-icon="mdi-calendar-outline" v-bind="attrs" v-on="on" clearable></v-text-field>
            </template>
            <v-date-picker v-model="validTo" no-title @input="validToMenu = false" :min="validFrom"></v-date-picker>
          </v-menu>
        </v-form>

        <v-row>
          <v-col>
            <v-scroll-y-transition>
              <v-progress-linear v-if="progress" :value="progress" color="secondary" height="30" dark rounded>
                {{ currentIndex + 1 }} / {{ fields.length }}
              </v-progress-linear>
            </v-scroll-y-transition>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-scroll-y-transition>
              <div v-if="showResult">
                <v-chip-group>
                  <v-chip color="success" label>
                    <v-icon left>
                      mdi-check-circle
                    </v-icon>
                    {{ successCount }} completed
                  </v-chip>

                  <v-chip color="error" label>
                    <v-icon left>
                      mdi-alert-circle
                    </v-icon>
                    {{ failCount }} failed
                  </v-chip>

                  <v-tooltip right>
                    <template v-slot:activator="{ attrs, on }">
                      <v-chip v-bind="attrs" v-on="on" label>
                        <v-icon left>
                          mdi-debug-step-over
                        </v-icon>
                        {{ skippedCount }} skipped
                      </v-chip>
                    </template>

                    Measurement fields are skipped if no calculations are found
                  </v-tooltip>
                </v-chip-group>
              </div>
            </v-scroll-y-transition>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn :color="showResult ? 'primary' : ''" @click="close" :text="showResult ? false : true">
          {{ showResult ? 'Done' : 'Close' }}
        </v-btn>

        <v-btn color="primary" :disabled="!valid || showResult" :loading="saving" @click="onConfirm" text>
          <v-icon left>
            mdi-check
          </v-icon>

          Approve
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ApproveAllDialog',
  props: {
    fields: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      valid: false,
      saving: false,
      progress: 0,
      currentIndex: 0,
      successCount: 0,
      failCount: 0,
      skippedCount: 0,
      showResult: false,
      changesMade: false,
      validFrom: null,
      validTo: null,
      validFromMenu: false,
      validToMenu: false,
      rules: {
        input1: [
          value => !!value || 'Input1 is required'
        ],
        validFrom: [
          value => !!value || 'Valid from date must be filled out'
        ]
      }
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.$refs.form.reset();
      this.progress = 0;
      this.currentIndex = 0;
      this.successCount = 0;
      this.failCount = 0;
      this.skippedCount = 0;
      this.showResult = false;

      if (this.changesMade) {
        this.$emit('done:changed');
        this.changesMade = false;
      }
    },
    async onConfirm() {
      this.changesMade = true;
      this.saving = true;

      try {
        const facilityId = this.$route.params.facilityId;
        const data = {
          validFrom: this.validFrom,
          validTo: this.validTo
        };

        // Approve every measurement field calculation in turn.
        for await (const field of this.fields) {
          // Get the calculations for the field
          const calculations = await this.$Services.measurementFieldCalculations.getAll(facilityId, field.id);

          if (calculations.list.length) {
            try {
              await this.$Services.measurementFieldCalculations.approve(facilityId, field.id, calculations.list[0].id, data);

              this.successCount++;
            } catch (error) {
              console.error(error);
              this.failCount++;
            }
          } else {
            this.skippedCount++;
          }

          // Add to progress value
          this.progress += 100 / this.fields.length;
          this.currentIndex = this.currentIndex + 1 !== this.fields.length ? this.currentIndex + 1 : this.currentIndex;
        }

        this.showResult = true;
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.saving = false;
      }
    }
  }
}
</script>