import services from '@/services/ServiceFactory';

const sortByTarget = (a, b) => {
  if (a.target < b.target) {
    return 1;
  }

  if (a.target > b.target) {
    return -1;
  }

  else {
    return a.name < b.name ? -1 : 1;
  }
}

export default {
  getExecutions: async function ({ commit, state }) {
    try {
      const response = await services.calculations.getCalculationStatus();

      commit('updateExecutions', response.items);
    } catch (error) {
      console.error(error);
    }
  },

  showDefaultError: function ({ commit }, message = 'Something went wrong! Please try again') {
    commit('showNotification', { text: message, color: 'error', icon: 'alert-circle-outline' });
  },

  showDefaultSuccess: function ({ commit }, message = 'Changes were saved') {
    commit('showNotification', { text: message, color: 'success', icon: 'checkbox-marked-circle-outline' });
  },

  getFacilities: async function ({ commit }) {
    const response = await services.facilities.getFacilities();

    commit('updateFacilities', response);

    return response;
  },

  getOrganizations: async function ({ commit, getters }) {
    let response = {};

    if (getters.hasAnyRole('SystemAdmin')) {
      response = await services.organizations.getAll();
    } else {
      response = [ await services.organizations.getById(getters.userOrganizationId) ];
    }

    commit('updateOrganizations', response);
  },

  checkMaintenanceMode: async function ({ commit, state }) {
    try {
      const response = await fetch('/settings.js', { cache: 'no-store' });
      const text = await response.text();
      const hasChanged = Boolean(state.maintenanceMode) !== text.includes('maintenance: true');

      if (hasChanged) {
        commit('setMaintenanceMode', text.includes('maintenance: true'));
        window.location.reload(true);
      }
    } catch (error) {
      console.error('Unable to check maintenance mode.', error);
    }
  },

  saveFleetFilter ({ commit, state }, filter) {
    localStorage.setItem('pt-fleetFilter', JSON.stringify(filter));

    commit('setFleetFilter', filter);
  },

  markMessageAsRead: async function ({ dispatch, state, getters }, messageId) {
    try {
      await services.messages.markAsRead(messageId, getters.currentUserId);
      
      // Find the message locally and update it
      state.messages.find(x => x.id === messageId).isRead = true;
    } catch (error) {
      dispatch('showDefaultError', 'Error marking message as read.');
    }
  },

  getMessages: async function ({ commit }, isActive = true) {
    const messages = await services.messages.get(isActive);

    commit('setMessages', messages);
  },

  getMeasurementFields: async function ({ commit }) {
    const response = await services.measurementFields.getAll();

    commit('updateMeasurementFields', response);
  },

  getMeasurementFieldsByFacilityId: async function ({ dispatch }, id) {
    try {
      const response = await services.measurementFields.getAllForFacility(id);

      return response;
    } catch (error) {
      console.error(error);
      dispatch('showDefaultError');
      throw error;
    }
  },

  getReports: async function({ dispatch }) {
    try {
      const response = await services.reports.getReports();

      return response.reports.sort(sortByTarget);
    } catch (error) {
      console.error(error);
      dispatch('showDefaultError');
      throw error;
    }
  },

  addMessage: async function ({ dispatch }, message) {
    try {
      await services.messages.create(message);
      dispatch('getMessages');
    } catch (error) {
      console.error(error);
      dispatch('showDefaultError', 'Error creating message');
    }
  },

  deleteMessage: async function ({ dispatch }, messageId) {
    try {
      await services.messages.remove(messageId);
      dispatch('getMessages');
    } catch (error) {
      console.error(error);
      dispatch('showDefaultError', 'Error deleting message. Please try again.');
    }
  }
}
