<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card v-if="report">
          <v-card-title>
            {{ report.name || 'No report selected' }}
            
            <v-chip class="ml-4 text-caption" outlined>
              {{ report.target || 'No target specified for this report' }}
            </v-chip>

            <v-spacer></v-spacer>

            <EditReportDialog :report="report" @update="fetchData" />
          </v-card-title>

          <v-card-text>
            <div v-if="report.documentation">
              {{ report.documentation }}
            </div>

            <div v-if="report.information">
              <v-card-subtitle>
                Information
              </v-card-subtitle>

              <v-sheet class="pa-4" elevation="1">
                <div id="information" v-html="report.information"></div>
              </v-sheet>
            </div>

            <v-card-subtitle class="mt-4">
              <v-row align="baseline">
                <v-col>
                  Report fields
                </v-col>

                <v-col class="text-right text-caption text--secondary">
                  Approval status
                </v-col>
              </v-row>
            </v-card-subtitle>

            <v-list dense elevation="1">
              <v-list-item v-for="field in report.fields" :key="field.name" @click="selectedField = field">
                <v-list-item-content>
                  {{ field.name }}
                </v-list-item-content>

                <v-list-item-icon class="rounded-circle" :class="field.isApproved ? 'success' : 'warning'">
                  <v-icon class="ml-1" color="white" small>
                    mdi-{{ field.isApproved ? 'check' : 'minus' }}
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>

        <v-card v-else>
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-card>
      </v-col>

      <v-col>
        <ReportField :id="selectedField.id" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <FieldEditor :id="selectedField.id" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FieldEditor from '@/components/FieldEditor';
import ReportField from '@/components/ReportField';
import EditReportDialog from '@/components/EditReportDialog';

export default {
  components: {
    FieldEditor,
    ReportField,
    EditReportDialog
  },
  data: function() {
    return {
      id: '',
      report: null,
      selectedField: {},
      selectedRevision: {}
    };
  },
  methods: {
    fetchData: async function() {
      // Try to get an ID either from route or store, otherwise set nothing.
      if (this.$route.params.id) {
        this.id = this.$route.params.id;
        this.$store.commit('setReportId', this.id);
      } else if (this.$store.state.currentReportId) {
        this.$router.replace(`detail/${this.$store.state.currentReportId}`);
      }

      if (this.id) {
        try {
          const response = await this.$Services.reports.getReport(this.id);

          this.report = response;
          this.selectedField = this.report.fields[0];
        } catch (error) {
          console.error(error);
        }
      }
    }
  },
  mounted: function() {
    this.fetchData();
  }
};
</script>

<style scoped>
#information {
  max-height: 10.1em;
  line-height: 1em;
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>