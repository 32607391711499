<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <v-row>
          <v-col>
            <v-card>
              <v-card-title v-if="measurementField && measurementField.name">
                {{ measurementField.name }}
              </v-card-title>

              <v-skeleton-loader v-else type="card-heading"></v-skeleton-loader>

              <v-card-text v-if="measurementField">
                <v-list dense>
                  <v-list-item>
                    <v-row>
                      <v-col cols="3">
                        Data type
                      </v-col>
                      <v-col class="text-right">
                        {{ measurementField.dataType }}
                      </v-col>
                    </v-row>
                  </v-list-item>

                  <v-list-item>
                    <v-row>
                      <v-col cols="3">
                        Unit
                      </v-col>
                      <v-col class="text-right">
                        {{ measurementField.unit }}
                      </v-col>
                    </v-row>
                  </v-list-item>

                  <v-list-item>
                    <v-row>
                      <v-col cols="3">
                        Version
                      </v-col>
                      <v-col class="text-right">
                        {{ measurementField.version }}.{{ measurementField.revision }}
                      </v-col>
                    </v-row>
                  </v-list-item>

                  <v-list-item>
                    <v-row>
                      <v-col cols="9">
                        Required sample interval (seconds)
                      </v-col>
                      <v-col class="text-right">
                        {{ measurementField.requiredSampleIntervalInSeconds || '-' }}
                      </v-col>
                    </v-row>
                  </v-list-item>

                  <v-list-item>
                    <v-row>
                      <v-col>
                        Documentation
                      </v-col>
                      <v-col class="text-right">
                        {{ measurementField.documentation || '-' }}
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list>
              </v-card-text>

              <v-card-text v-else>
                <v-skeleton-loader type="list-item@3"></v-skeleton-loader>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                <v-row align="center" no-gutters>
                  <v-col>
                    Configured channels
                  </v-col>

                  <v-col class="text-right" cols="3">
                    <v-btn to="channels" text>
                      Edit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>

              <v-card-text class="mt-n6">
                <v-list v-if="dataSources.length" dense>
                  <v-list-item-group v-for="source in dataSources" :key="source.id">
                    <v-subheader>
                      {{ source.name }}
                    </v-subheader>

                    <v-list-item v-for="channel in source.channels" :key="channel.id">
                      <v-chip color="secondary" small label>
                        {{ channel.fullName }}
                      </v-chip>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>

                <v-skeleton-loader v-else type="list-item@5"></v-skeleton-loader>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col>
        <MeasurementFieldEditor v-if="measurementField" :measurementFieldId="measurementField.id" :channels="this.dataSources.map(source => source.channels).flat()" />

        <v-card v-else>
          <v-skeleton-loader type="card" transition="fade"></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MeasurementFieldEditor from '@/components/MeasurementFieldEditor.vue';

export default {
  name: 'MeasurementFieldDetail',
  components: {
    MeasurementFieldEditor
  },
  data() {
    return {
      measurementField: null,
      dataSources: []
    };
  },
  methods: {
    async loadChannels() {
      try {
        const facilityId = this.$route.params.facilityId;

        const sources = await this.$Services.facilities.getDataSources(facilityId);

        for (const source of sources) {
          source.channels = await this.$Services.channels.getMapped(source.id);
        }

        this.dataSources = sources;
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      }
    }
  },
  async created() {
    this.$store.commit('setMeasurementFieldId', this.$route.query.id);

    try {
      this.loadChannels();
      this.measurementField = await this.$Services.measurementFields.getById(this.$route.query.id);
    } catch (error) {
      console.error(error);
      this.$store.dispatch('showDefaultError');
    }
  }
};
</script>