<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <v-card>
          <v-card-title>
            Report field information
          </v-card-title>

          <v-card-subtitle>
            {{ field.name || 'No field selected' }}
          </v-card-subtitle>

          <v-divider></v-divider>

          <v-card-title>
            Revisions
          </v-card-title>

          <v-card-text>
            <v-list elevation="1">
              <v-list-item v-for="rev in revisions" :key="rev.version" @click="selectedRev = rev">
                <v-list-item-content>
                  Revision {{ rev.version }}
                </v-list-item-content>

                <div class="text-caption text-right">
                  {{ rev.lastSaved }}
                </div>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-card>
          <v-card-title>
            Revision {{ selectedRev.version }}
          </v-card-title>

          <v-card-subtitle>
            Last saved: {{ selectedRev.lastSaved }}
          </v-card-subtitle>

          <v-card-text>
            <VueAceEditor ref="editor" :content="selectedRev.code" :options="editorOptions" :fontSize="14" lang="csharp" theme="solarized_dark" @init="editorInit" />
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn icon @click="undo">
              <v-icon>
                mdi-undo
              </v-icon>
            </v-btn>

            <v-btn icon @click="redo">
              <v-icon>
                mdi-redo
              </v-icon>
            </v-btn>

            <v-btn text>
              Compile
            </v-btn>

            <v-btn text>
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { VueAceEditor } from 'vue2x-ace-editor'

export default {
  components: {
    VueAceEditor
  },
  props: {
    field: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      code: '',
      // field: {},
      selectedRev: {},
      revisions: [],
      editorOptions: {
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        tabSize: 2
      }
    }
  },
  methods: {
    editorInit() {
      require('brace/ext/language_tools');
      require('brace/mode/csharp');
      require('brace/snippets/csharp');
      require('brace/theme/solarized_dark');

      // Editor config
      this.$refs.editor.setCompleteData([
        {
          name: 'Louise',
          value: 'So2Co2',
          caption: 'SO2CO2',
          meta: 'measurement',
          type: 'local',
          score: 1000
        }
      ]);
    },
    undo() {
      this.$refs.editor.undo();
    },
    redo() {
      this.$refs.editor.redo();
    }
  },
  created() {
    this.revisions = [
      {
        version: 1,
        code: 'Console.WriteLine("Hello revision 1!")',
        lastSaved: new Date('2021-05-01').toLocaleString()
      },
      {
        version: 2,
        code: 'Console.WriteLine("Hello revision 2!")',
        lastSaved: new Date('2021-05-03').toLocaleString()
      }
    ].sort((a, b) => b.version - a.version);

    this.selectedRev = this.revisions[0];
  }
}
</script>

<style>
.vue-ace-editor {
  min-height: 400px!important;
}
</style>