export default {
  data() {
    return {
      availableRoles: [
        {
          name: 'SystemAdmin',
          displayName: 'System Administrator',
          summary: 'Can do everything to any organization.'
        },
        {
          name: 'UserAdmin',
          displayName: 'Administrator',
          summary: 'Manage users and ships for the organization.'
        },
        {
          name: 'User',
          displayName: 'Regular user',
          summary: 'View and upload files to assigned ships.'
        }
      ]
    }
  },
  methods: {
    getRole(role) {
      return this.availableRoles.find(x => x.name === role) || role;
    }
  }
}