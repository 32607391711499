<template>
  <v-snackbar
    app
    v-model="$store.state.offlineMode"
    timeout="-1"
    color="warning darken-1"
    bottom
    right
    multi-line
  >
    <div class="d-flex">
      <v-icon class="mr-4">
        mdi-server-network-off
      </v-icon>
      <div>
        <div>
          There seems to be a connection issue. Please check your internet access.
        </div>
        <div class="text-caption">
          Any unsaved changed will be lost.
        </div>
      </div>
    </div>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex';

let checkInterval = null;

export default {
  name: 'Offline',
  computed: mapState([ 'offlineMode' ]),
  methods: {
    async checkConnection() {
      checkInterval = setInterval(() => {
        console.debug('Trying to reestablish connection');

        this.$Services.api.get('version').then(() => {
          this.$store.dispatch('showDefaultSuccess', 'Connection established.');
          this.$router.go();
        });
      }, 10000);
    }
  },
  watch: {
    offlineMode(newValue) {
      console.debug('Offline mode is', newValue);
      if (newValue === true) {
        this.checkConnection();
      } else {
        clearInterval(checkInterval)
      }
    }
  }
}
</script>