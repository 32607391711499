<template>
  <v-datetime-picker
    :value="value"
    :label="label"
    @input="onInput($event)"
    :datePickerProps="{ min, max }"
    :timePickerProps="{ format: '24hr' }"
    :textFieldProps="{ rules }"
  >
    <template slot="dateIcon">
      <v-icon>mdi-calendar</v-icon>
    </template>
    <template slot="timeIcon">
      <v-icon>mdi-clock</v-icon>
    </template>
  </v-datetime-picker>
</template>

<script>
export default {
  name: 'DatetimePicker',
  props: {
    value: [String, Date],
    label: String,
    rules: Array,
    min: String,
    max: String,
    utc: {
      type: Boolean,
      default: () => true
    }
  },
  methods: {
    onInput (timestamp) {
      // Since Vuetify Datetime Picker does not have UTC mode, treat what the user selected as UTC by accounting for the offset
      const offset = this.utc ? this.moment(timestamp).utcOffset() : 0;

      this.$emit('input', this.moment.utc(timestamp).add(offset, 'minutes').toISOString());
    }
  }
}
</script>