<template>
  <v-card :loading="loading">
    <v-card-title>
      <div class="d-flex align-center">
        <div>
          Version {{ calculationVersion }}
        </div>

        <div class="ml-2">
          <v-menu v-if="calculation.version" offset-y bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" :disabled="calculations.length < 2" color="secondary" icon>
                <v-icon>
                  mdi-history
                </v-icon>
              </v-btn>
            </template>

            <v-list min-width="300">
              <v-list-item v-for="item in calculations" :key="item.id" :disabled="item.id === calculation.id" @click="calculation = item">
                <v-list-item-content>
                  <div class="d-flex align-center justify-space-between">
                    <div>
                      Version {{ item.version }}.{{ item.revision }}
                    </div>
                    <div>
                      <v-rating v-model="item.qualityScore" v-if="item.qualityScore" color="primary" background-color="secondary" readonly small dense></v-rating>
                      <div class="text--secondary" v-else>
                        No rating
                      </div>
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div class="ml-2">
          <v-slide-x-transition>
            <v-chip class="text--secondary" v-if="!loading && calculation.id && isApproved" label>
              <v-icon left small>
                mdi-information-outline
              </v-icon>
              Approved on {{ calculation.approvedOn | formatDate }}
            </v-chip>
          </v-slide-x-transition>
        </div>

        <div class="ml-2">
          <v-slide-x-transition>
            <v-chip v-if="!!calculation.recalculationQueuedOn" color="primary" label>
              <v-icon left small>
                mdi-information-outline
              </v-icon>
              Recalculation queued on {{ calculation.recalculationQueuedOn | formatDateTime }}
            </v-chip>

            <v-chip v-else-if="!!calculation.recalculationRequestedOn" color="secondary" label>
              <v-icon left small>
                mdi-information-outline
              </v-icon>
              Recalculation requested on {{ calculation.recalculationRequestedOn | formatDateTime }}
            </v-chip>
          </v-slide-x-transition>
        </div>
      </div>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col>
          <v-expansion-panels v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-header disable-icon-rotate>
                <template v-slot:default="{ open }">
                  <v-row no-gutters align="center">
                    <v-col cols="2">
                      Calculation settings
                    </v-col>
                    <v-col class="text--secondary">
                      <v-fade-transition leave-absolute hide-on-leave>
                        <v-row v-if="!open">
                          <v-col cols="2">
                            <v-rating v-if="calculation.qualityScore" v-model="calculation.qualityScore" color="primary" background-color="secondary" readonly small dense></v-rating>

                            <span v-else>
                              No rating set
                            </span>
                          </v-col>
                          <v-col cols="3">
                            {{ calculation.inputIds && calculation.inputIds.length || 'No' }} measurement fields selected
                          </v-col>
                          <v-col>
                            {{ calculation.comment || 'No comment' }}
                          </v-col>
                        </v-row>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:actions>
                  <v-icon>mdi-settings-outline</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col>
                    <v-text-field v-model="calculation.comment" label="Comment"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-textarea v-model="calculation.documentation" label="Documentation"></v-textarea>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field v-model="calculation.requiredSampleIntervalInSeconds" label="Required sample interval" hint="In seconds" persistent-hint></v-text-field>
                  </v-col>

                  <v-col>
                    <v-text-field v-model="calculation.preloadIntervalInSeconds" label="Preload interval" hint="In seconds" persistent-hint></v-text-field>
                  </v-col>

                  <!-- <v-col>
                    <v-menu ref="validFromMenu" v-model="validFromMenu" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="calculation.validFrom" label="Valid from" prepend-icon="mdi-calendar-outline" v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="calculation.validFrom" no-title @input="validFromMenu = false"></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col>
                    <v-menu ref="validToMenu" v-model="validToMenu" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="calculation.validTo" label="Valid to" prepend-icon="mdi-calendar-outline" v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="calculation.validTo" no-title @input="validToMenu = false"></v-date-picker>
                    </v-menu>
                  </v-col> -->
                </v-row>

                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-model="calculation.inputIds"
                      :items.sync="$store.getters.measurementFields"
                      item-text="name"
                      item-value="id"
                      label="Available measurement fields"
                      placeholder="Start typing..."
                      hint="These measurement fields are available in the algorithm"
                      persistent-hint
                      @input="search = null; updateAutocomplete($event);"
                      :search-input.sync="search"
                      multiple
                      chips
                      deletable-chips>
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <div class="d-flex align-center justify-end">
                      <div class="text-body-1 mr-4">
                        Rate this calculation
                      </div>

                      <v-rating v-model.number="calculation.qualityScore" color="primary" background-color="secondary"></v-rating>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- <v-expansion-panel>
              <v-expansion-panel-header>
                Test data
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row v-for="(point, index) in testData" :key="index">
                  <v-col class="shrink">
                    {{ index + 1 }}
                  </v-col>

                  <v-col>
                    <v-text-field v-model="point.timestamp" label="Timestamp" outlined dense></v-text-field>
                  </v-col>

                  <v-col>
                    <v-text-field v-model="point.key" label="Key" outlined dense></v-text-field>
                  </v-col>

                  <v-col>
                    <v-text-field v-model="point.value" label="Value" outlined dense></v-text-field>
                  </v-col>
                </v-row>

                <v-btn @click="addTestDataPoint">
                  <v-icon left>mdi-plus</v-icon>
                  Add data point
                </v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel> -->
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-title>
      Algorithm Editor
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col>
          <v-hover v-slot="{ hover }" :disabled="!isLocked">
            <VueAceEditor ref="editor" :content="code" :options="editorOptions" :fontSize="14" lang="csharp" theme="solarized_dark" @onChange="editorChange" @onInput="hasChanged = true">
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out secondary darken-2 v-card--reveal text-h4 white--text"
                  style="height: 100%;"
                >
                  Recalculation queued - Editing is locked
                </div>
              </v-expand-transition>
            </VueAceEditor>
          </v-hover>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn color="primary" :disabled="!calculation.transformAlgorithm" :loading="validating" @click="validate" text>
        <v-icon left>mdi-bug-check-outline</v-icon>
        Validate
      </v-btn>

      <v-btn color="primary" :disabled="!calculation.transformAlgorithm" :loading="testing" @click="test" text>
        <v-icon left>mdi-package-variant-closed</v-icon>
        Test
      </v-btn>

      <v-dialog v-model="approveDialog" width="500">
        <template v-slot:activator="{ attrs, on }">
          <v-btn color="primary" v-bind="attrs" v-on="on" :disabled="isApproved" text>
            <v-icon left>mdi-check</v-icon>
            {{ calculation.approvedOn ? 'Approved' : 'Approve' }}
          </v-btn>
        </template>

        <v-card>
          <v-card-title>
            Set validity period
          </v-card-title>

          <v-card-text>
            <p>
              Specify the period in which this calculation is valid.
            </p>

            <p>
              If no end date is set, the calculation will be valid forever unless another calculation is approved.
            </p>

            <v-form ref="approveForm" v-model="approveForm">
              <v-menu ref="validFromMenu" v-model="validFromMenu" transition="scale-transition" offset-y min-width="auto" :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="validFrom" label="Valid from" prepend-icon="mdi-calendar-outline" :rules="validFromRules" v-bind="attrs" v-on="on" clearable></v-text-field>
                </template>
                <v-date-picker v-model="validFrom" no-title @input="validFromMenu = false"></v-date-picker>
              </v-menu>

              <v-menu ref="validToMenu" v-model="validToMenu" transition="scale-transition" offset-y min-width="auto" :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="validTo" label="Valid to (optional)" prepend-icon="mdi-calendar-outline" v-bind="attrs" v-on="on" clearable></v-text-field>
                </template>
                <v-date-picker v-model="validTo" no-title @input="validToMenu = false" :min="validFrom"></v-date-picker>
              </v-menu>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn text @click="approveDialog = false">
              Cancel
            </v-btn>

            <v-btn color="primary" :loading="approving" @click="approve" text>
              Confirm approval
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-btn color="primary" :loading="saving" @click="save" text>
        <v-icon left>mdi-content-save-outline</v-icon>
        Save
      </v-btn>

      <v-btn color="secondary" :loading="saving" @click="save(false)" text>
        <v-icon left>
          mdi-content-save-outline
        </v-icon>
        Save without queuing
      </v-btn>
    </v-card-actions>

    <v-card-text>
      <v-card-subtitle v-if="output.errors && output.errors.length">
        {{ output.errors.length }} errors
      </v-card-subtitle>

      <v-slide-y-transition group leave-absolute>
        <template v-for="(error, index) in output.errors">
          <v-alert :key="index" type="error" text border="left">
            {{ error.startLine ? `Line ${error.startLine}` : '' }}{{ error.startCharacter ? `, Column ${error.startCharacter}` : '' }} <b>{{ error.message }}</b>
          </v-alert>
        </template>
      </v-slide-y-transition>

      <v-slide-y-transition leave-absolute>
        <v-alert v-if="output.success" type="success" text border="left">
          {{ output.success }}
        </v-alert>
      </v-slide-y-transition>

      <v-slide-y-transition group leave-absolute>
        <template v-for="(result, index) in output.result">
          <v-alert :key="index" type="info" text border="left">
            <code>
              {{ result }}
            </code>
          </v-alert>
        </template>
      </v-slide-y-transition>
    </v-card-text>

    <v-card-title>
      Test Data Editor
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col>
          <v-hover v-slot="{ hover }" :disabled="!isLocked">
            <VueAceEditor ref="testDataEditor" :content="testData" :options="editorOptions" :fontSize="14" lang="json" theme="solarized_dark" @onChange="editorChange" @onInput="hasChanged = true">
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out secondary darken-2 v-card--reveal text-h4 white--text"
                  style="height: 100%;"
                >
                  Recalculation queued - Editing is locked
                </div>
              </v-expand-transition>
            </VueAceEditor>
          </v-hover>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field v-model="constants.Facility_IMO" label="Facility_IMO (string)" outlined hide-details=""></v-text-field>
        </v-col>

        <v-col>
          <v-text-field v-model.number="constants.Facility_NumberOfScrubbers" type="number" label="Facility_NumberOfScrubbers (integer)" outlined hide-details=""></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field v-model="constants.Facility_ScrubberSystemType" label="Facility_ScrubberSystemType (string)" outlined hide-details=""></v-text-field>
        </v-col>

        <v-col>
          <v-text-field v-model.number="constants.Facility_InstalledPower" type="number" label="Facility_InstalledPower (integer)" outlined hide-details=""></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment';
import { VueAceEditor } from 'vue2x-ace-editor'

export default {
  name: 'FieldEditor',
  components: {
    VueAceEditor
  },
  props: {
    id: {
      type: String,
      required: true,
      default() {
        return '';
      }
    }
  },
  data() {
    return {
      approveForm: false,
      approveDialog: false,
      calculation: {},
      calculations: [],
      hasChanged: false,
      search: null,
      panel: [],
      code: '',
      testData: `{
  "inputData": [
    {
      "timestamp": "2021-05-07T03:24:14.641Z",
      "inputValues": {
        "SO2": 0.7,
        "CO2": 1.8
      }
    },
    {
      "timestamp": "2021-05-07T04:24:14.641Z",
      "inputValues": {
        "SO2": 0.9,
        "CO2": 1.4
      }
    }
  ]
}`,
      loading: false,
      saving: false,
      validating: false,
      approving: false,
      output: {
        errors: []
      },
      testing: false,
      testResult: {},
      validFrom: null,
      validTo: null,
      validFromMenu: false,
      validToMenu: false,
      selectedMeasurements: [],
      editorOptions: {
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        tabSize: 2,
        showPrintMargin: false
      },
      constants: {
        Facility_IMO: '',
        Facility_NumberOfScrubbers: 0,
        Facility_ScrubberSystemType: '',
        Facility_InstalledPower: 0
      },
      showOutput: false,
      validFromRules: [
        value => !!value || 'Valid from date must be filled out'
      ]
    }
  },
  computed: {
    calculationVersion() {
      const { version, revision } = this.calculation;

      return typeof version === 'number' && typeof revision === 'number' ? `${version}.${revision}` : 'unknown'
    },
    isApproved() {
      return this.calculation && this.calculation.approvedOn !== null;
    },
    isLocked() {
      return !!this.calculation?.recalculationQueuedOn;
    }
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        this.calculations = [];
        this.output = {};
        const reportId = this.$route.params.id;

        const calculations = await this.$Services.reports.getReportFieldCalculations(reportId, this.id);

        if (!calculations.list) {
          throw new Error('Could not get calculations for report field');
        }

        for await (const calculation of calculations.list) {
          const response = await this.$Services.reports.getReportFieldCalculation(reportId, this.id, calculation.id);

          this.calculations.push(response);
        }

        this.calculation = this.calculations[0]; // TODO: When using versions, this needs to go
        this.code = this.calculation.transformAlgorithm ?? '';
        this.$refs.editor.setValue(this.code);
        this.updateAutocomplete(this.calculation.inputIds);
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.loading = false;
      }
    },
    async save(recalculate = true) {
      await this.validate();

      this.saving = true;
      let text = '';
      let color = '';

      try {
        const reportId = this.$route.params.id;

        const response = await this.$Services.reports.saveReportFieldCalculation(reportId, this.id, this.calculation.id, recalculate, this.calculation);

        text = 'Changes were saved';
        color = 'success';
        this.closePanels();
      } catch (error) {
        text = 'An error occurred when saving';
        color = 'error';
        console.error(error);
      } finally {
        this.saving = false;
        this.$store.commit('showNotification', { text, color });
      }
    },
    async validate() {
      this.validating = true;
      this.closePanels();
      this.output = {};

      try {
        const response = await this.$Services.calculations.validateReportField(this.calculation.transformAlgorithm, this.calculation.inputIds);

        this.output = response;

        if (!response.errors || !response.errors.length) {
          this.output.success = 'Validation successful';
        }
      } catch (error) {
        console.error(error);
        this.$store.commit('showNotification', { text: 'Something went wrong! Please try again', color: 'error' });
      } finally {
        this.validating = false;
        this.showOutput = true;
      }
    },
    async test() {
      // Validate before testing the code with data
      await this.validate();

      if (this.output.errors && this.output.errors.length) {
        // Validation did not succeed. Don't go through with testing.
        return;
      }

      this.testing = true;
      this.closePanels();
      this.output = {};

      const testData = JSON.parse(this.$refs.testDataEditor.getValue());

      try {
        const { transformAlgorithm, inputIds, preloadIntervalInSeconds } = this.calculation;
        const data = {
          transformAlgorithm,
          inputIds,
          algorithmSecondsSpan: preloadIntervalInSeconds,
          inputData: testData.inputData,
          constants: this.constants
        };

        const response = await this.$Services.calculations.testReportField(data);

        this.output = response;

        if (!response.errors || !response.errors.length) {
          this.output.success = 'Test successful';
        }
      } catch (error) {
        console.error(error);

        this.output.errors = [
          {
            message: error
          }
        ];
      } finally {
        this.testing = false;
      }
    },
    async approve() {
      if (!this.$refs.approveForm.validate()) {
        return;
      }

      // Validate before testing the code with data
      await this.validate();

      if (this.output.errors && this.output.errors.length) {
        // Validation did not succeed. Don't go through with testing.
        return;
      }

      this.approving = true;
      this.closePanels();
      this.output = {};
      const reportId = this.$route.params.id;

      try {
        const data = {
          validFrom: this.validFrom,
          validTo: this.validTo
        };

        await this.$Services.reports.approveReportFieldCalculation(reportId, this.id, this.calculation.id, data);

        this.approveDialog = false;
        this.$store.dispatch('showDefaultSuccess');
        this.fetchData();
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.approving = false;
      }
    },
    editorChange() {
      this.calculation.transformAlgorithm = this.$refs.editor.getValue();
    },
    closePanels() {
      this.panel = [];
    },
    addTestDataPoint() {
      const point = {
        timestamp: moment().utc(),
        key: null,
        value: null
      }

      this.testData.push(point);
    },
    updateAutocomplete(ids) {
      // Inject the input IDs into editor auto completion
      const inputs = this.$store.getters.measurementFieldsByIds(ids);
      const mappedInputs = inputs.map(input => {
        const { name, dataType } = input;
        return {
          name,
          value: name,
          caption: name,
          meta: dataType,
          type: 'local',
          score: 100
        }
      });

      this.$refs.editor.setCompleteData(mappedInputs);
    }
  },
  watch: {
    id: {
      handler: async function(newValue) {
        if (newValue) {
          await this.fetchData();

          if (this.calculation.transformAlgorithm) {
            this.validate();
          } else {
            this.showOutput = false;
            this.validateResult = {
              errors: []
            }
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style>
.vue-ace-editor {
  min-height: 400px!important;
  line-height: 1.2rem;
}

.v-chip.v-size--default {
  border-radius: 4px!important;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .7;
  position: absolute;
  width: 100%;
  z-index: 10;
}
</style>