<template>
  <v-card
    elevation="2"
    :loading="loading ? '#757575' : false"
  >
    <v-card-title>
      <div>
        SO<sub class="pr-2">2</sub> / CO<sub class="pr-2">2</sub> RATIO RELATIVE TO LIMITS
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex align-center justify-space-between" style="gap: .5rem">
        <ExportShortcut
          reportCode="FacilityGroupSO2CO2Ratio"
        />
        <WidgetInfo
          v-if="report.information"
          title="SO2/CO2 ratio relative to limits"
          :content="report.information"
        />
      </div>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col align="center" cols="4" xs="12" sm="12" md="12" lg="5" class="align-self-end text-carbon">
          <div style="height: 235px; width:100%; margin-bottom: 10px;">
          <vue-speedometer
            class="so2co2-speedo"
            :forceRender="true"
            :width="400"
            :height="250"
            :paddingVertical="4"
            :paddingHorizontal="6"
            labelFontSize="16px"
            valueTextFontSize="30px"
            :minValue="100"
            :maxValue="-30"
            :segments="4"
            :customSegmentStops='[100,50,10,0,-30]'
            :segmentColors='["#757575","#00B697","#F2BF0D", "#f00"]'
            :customSegmentLabels="[
                {
                  text: 'Over-scrubbing',
                  position: 'OUTSIDE',
                  color: '#000000de',
                },
                {
                  text: 'Optimal',
                  position: 'OUTSIDE',
                  color: '#000000de',
                },
                {
                  text: 'Risk',
                  position: 'OUTSIDE',
                  color: '#000000de',
                },
                {
                  text: 'Under-scrubbing',
                  position: 'OUTSIDE',
                  color: '#000000de',
                },
              ]"
            needleColor="#9E9E9E"
            :value="speedometer.needle"
            :currentValueText="loading ? ' ' : `${speedometer.actual ? `${speedometer.actual}%` : 'No data'}`"
            />
          </div>
        </v-col>
        <v-col cols="8" xs="12" sm="12" md="12" lg="7">
          <FleetSO2CO2RatioChart :ships="chartData" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" xs="12" sm="12" md="12" lg="5">
          <p class="text-center">
            Average SO<sub>2</sub>/CO<sub>2</sub> ratio relative to limits
          </p>
        </v-col>
        <v-col cols="8" xs="12" sm="12" md="12" lg="7">
          <p class="text-center">
            SO<sub>2</sub>/CO<sub>2</sub> ratio relative to applicable limits (4.3 or 21.7) for each vessel.
          </p> 
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import VueSpeedometer from "vue-speedometer";
import FleetSO2CO2RatioChart from '@/components/FleetSO2CO2RatioChart.vue';
import WidgetBanner from '@/components/WidgetBanner.vue';
import WidgetInfo from '@/components/WidgetInfo.vue';
import ExportShortcut from '../components/ExportShortcut.vue';

const COLORS = ['#003893', '#60afdd', '#926ec2', '#c9a2fb'];

export default {
  name: 'FleetSO2CO2RatioLarge',
  components: {
    FleetSO2CO2RatioChart,
    VueSpeedometer,
    WidgetBanner,
    WidgetInfo,
    ExportShortcut
  },
  props: {
    period: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      report: {}
    }
  },
  computed: {
    speedometer() {
      const value = this.report?.fields?.ActualAverage?.value;
      const format = this.report?.fields?.ActualAverage?.format;

      const data = { actual: 0, needle: 100 };

      if (!this.loading && value && format) {
        data.actual = Number(this.$options.filters.formatKPI(value, format));

        // Set needle value to cap overflow
        if (data.actual < -30) {
          data.needle = -30;
        }
        else if (data.actual > 100) {
          data.needle = 100;
        }
        else{
          data.needle = data.actual;
        }
      }

      return data;
    },
    chartData() {
      const format = this.report?.fields?.Facilities?.format || 'Percent1Digits';
      let values = this.report?.fields?.Facilities?.values || [];

      const ships = values.map(x => {
        return {
          value: Number(this.$options.filters.formatKPI(x.value, format)),
          name: this.$store.getters.facilityById(x.facilityId).name
        };
      });

      ships.sort((a, b) => {
        return String(a.name).localeCompare(b.name);
      });

      return ships;
    },
    ...mapState([ 'filteredFacilities' ])
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.report = {};

      try {
        this.report = await this.$Services.reports.getReportValues('FacilityGroupSO2CO2Ratio', this.period.from, this.period.to);
        
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    period() {
      this.fetchData();
    },
    filteredFacilities() {
      this.fetchData();
    }
  }
}
</script>