<template>
  <v-card>
    <v-card-title>
      {{ field.name || 'No field selected' }}
    </v-card-title>

    <!-- <v-divider></v-divider> -->

    <v-card-text>
      <v-list dense>
        <v-list-item v-for="(value, key) in details" :key="key">
          <v-row>
            <v-col>
              {{ key }}
            </v-col>
            <v-col class="text-right">
              <v-icon v-if="typeof value === 'boolean'">
                mdi-{{ !!value ? 'check' : 'close' }}
              </v-icon>

              <span v-else>
                {{ value || '-' }}
              </span>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ReportField',
  props: {
    id: {
      type: String,
      required: true,
      default() {
        return '';
      }
    }
  },
  data() {
    return {
      field: {}
    }
  },
  computed: {
    details() {
      const { asLifetime, asSeries, byFacility, facilityAggregateType, timeAggregateType, format, formatNote, interfaceType, note, documentation } = this.field;
      return {
        'As lifetime': asLifetime,
        'As series': asSeries,
        'By ship': byFacility,
        'Ship aggregate type': facilityAggregateType,
        'Time aggregate type': timeAggregateType,
        'Format': format,
        'Format note': formatNote,
        'Shown as': interfaceType,
        'Note': note,
        'Documentation': documentation
      }
    }
  },
  watch: {
    async id(newValue, oldValue) {
      if (newValue) {
        try {
          const response = await this.$Services.reports.getReportField(this.$route.params.id, newValue);

          this.field = response;
          this.$emit('update:revision', this.field.calculations[0])
        } catch (error) {
          console.error(error);
        }
      }
    }
  }
};
</script>