import { render, staticRenderFns } from "./KPIWidgetPreview.vue?vue&type=template&id=6c3c6354&scoped=true"
import script from "./KPIWidgetPreview.vue?vue&type=script&lang=js"
export * from "./KPIWidgetPreview.vue?vue&type=script&lang=js"
import style0 from "./KPIWidgetPreview.vue?vue&type=style&index=0&id=6c3c6354&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c3c6354",
  null
  
)

export default component.exports