<template>
  <v-card elevation="2">
    <v-row no-gutters>
      <v-col>
        <v-toolbar class="mb-n3" flat>
          <v-toolbar-title>
            <v-icon color="primary" left>
              mdi-ferry
            </v-icon>
            {{facility.name}}
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn
            v-if="$store.getters.hasAnyRole('SystemAdmin')"
            :disabled="!facility.id"
            text
            :to="`/facilities/${facility.id}/config/info`"
          >
            <v-icon left>mdi-pencil-outline</v-icon>
            Edit
          </v-btn>

          <v-btn
            v-if="type === 'fleet'"
            :to="`/ship/${facility.id}`"
            :disabled="!facility.id"
            text
          >
            <v-icon left>mdi-view-dashboard-outline</v-icon>
            Ship dashboard
          </v-btn>
          <!-- Removed until further notice -->
          <!-- <span v-else>
            <v-btn :to="`/ship/${facility.id}/reports`" text>
              <v-icon left>mdi-library-books</v-icon>
              Ship Reports
            </v-btn>
            <v-btn :to="`/ship/${facility.id}/documentation`" text>
              <v-icon left>mdi-file-document</v-icon>
              Ship Documentation
            </v-btn>
          </span> -->
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col>
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <th>Scrubber system type</th>
                      <td>{{facility.scrubberSystemType}}</td>
                    </tr>
                    <tr>
                      <th>Earliest data</th>
                      <td v-if="facility.dataTimeStampMin">{{ facility.dataTimeStampMin | formatDate }}</td>
                      <td class="text--secondary" v-else>No data</td>
                    </tr>
                    <tr>
                      <th>IMO</th>
                      <td>{{facility.imo}}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col>
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <th>Number of scrubber towers</th>
                      <td>{{facility.numberOfScrubbers}}</td>
                    </tr>
                    <tr>
                      <th>Latest data</th>
                      <td v-if="facility.dataTimeStampMax">{{facility.dataTimeStampMax | formatDate}}</td>
                      <td class="text--secondary" v-else>No data</td>
                    </tr>
                    <tr>
                      <th>Installed power</th>
                      <td>{{ facility.totalEffectKW || 'Not specified' }} kW</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>

      <v-col cols="2">
        <v-img v-if="facility.imageBase64" :src="facility.imageBase64" :alt="facility.name" :title="facility.name+' - '+ facility.imo" height="100%" cover></v-img>

        <v-sheet class="d-flex align-center justify-center" v-else color="grey lighten-4" height="100%">
          <v-icon color="grey lighten-1" x-large>
            mdi-camera-off
          </v-icon>
        </v-sheet>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'ShipInfo',
  props: {
    type: {
      type: String,
      required: true,
      validator: function (value) {
        return ['fleet', 'ship'].indexOf(value) !== -1;
      }
    },
    facility: {
      type: Object,
      required: true
    }
  }
};
</script>