<template>
  <VueAceEditor ref="editor" :content="content" :options="editorOptions" :fontSize="14" :lang="lang" theme="solarized_dark" @onInput="onChange" />
</template>

<script>
import { VueAceEditor } from 'vue2x-ace-editor'
import 'brace/ext/language_tools';
import 'brace/mode/json';
import 'brace/mode/csharp';
import 'brace/snippets/json';
import 'brace/snippets/csharp';
import 'brace/theme/solarized_dark';

export default {
  name: 'EditorWindow',
  components: {
    VueAceEditor
  },
  props: {
    lang: {
      type: String,
      required: true
    },
    content: {
      type: String
    }
  },
  data() {
    return {
      code: '',
      editorOptions: {
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        tabSize: 2,
        showPrintMargin: false
      }
    }
  },
  methods: {
    onChange() {
      this.$emit('input', this.$refs.editor.getValue());
    }
  }
}
</script>