<template>
  <div id="matrix-wrapper" class="wrapper">
    <div id="y-axis">
      <div>
        {{ mode === 'hourly' ? 0 : 1 }}
      </div>

      <div class="unit">
        {{ mode === 'hourly' ? 'Hours' : 'Days' }}
      </div>

      <div>
        {{ mode === 'hourly' ? 24 : 31 }}
      </div>
    </div>

    <div id="matrix">
      <div class="date" v-for="(date, index) of table" :key="index">
        <div class="text-center text-caption">
          <div class="mb-n1">{{ getDateString(date.timestampMoment).upper }}</div>
          <div>{{ getDateString(date.timestampMoment).lower }}</div>
        </div>

        <div class="timeslot" v-for="(slot, index) of date.values" :key="index" :style="{ backgroundColor: getColor(slot.value) }" @click="setActiveSlot($event, slot)"></div>
      </div>
    </div>

    <v-menu v-model="showDetails" :close-on-content-click="false" :close-on-click="false" :position-x="tooltipPosition.x" :position-y="tooltipPosition.y">
      <v-card min-width="250">
        <v-btn class="float-right" @click="closeDetails" icon small>
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>

        <v-card-text v-if="activeSlot">
          <div>
            {{ getStatus(activeSlot.value) }}
          </div>
          <div v-if="mode === 'hourly'">
            {{ activeSlot.timestamp | formatDateTime }}
          </div>
          <div v-else>
            {{ activeSlot.timestamp | formatDate }}
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'UptimeMatrix',
  props: {
    data: Array,
    mode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      minified: false,
      activeSlot: null,
      showDetails: false,
      tooltipPosition: { x: 0, y: 0 },
      activeTarget: null,
      table: []
    }
  },
  computed: {
    period() {
      return this.$store.state.period
    }
  },
  methods: {
    setActiveSlot(event, slot) {
      const delay = this.showDetails ? 100 : 0;

      this.showDetails = false;
      this.tooltipPosition = { x: event.clientX, y: event.clientY };
      this.activeSlot = slot;

      // Highlight selected slot
      if (this.activeTarget) {
        this.activeTarget.style.boxShadow = 'none';
      }

      this.activeTarget = event.target;
      event.target.style.boxShadow = '0px 0px 5px 0px #003893';

      setTimeout(() => {
        this.showDetails = true;
      }, delay);
    },
    closeDetails() {
      this.showDetails = false;
      this.activeTarget.style.boxShadow = 'none';
    },
    getDateString: function(date) {
      const m = moment(date).utc();

      if (this.mode === 'daily') {
        return {
          upper: m.format('MMM'),
          lower: m.format('YYYY'),
        };
      } else {
        return {
          upper: m.format('DD'),
          lower: m.format('MMM'),
        };
      }
    },
    async fetchData() {
      this.loading = true;
      this.showDetails = false;

      // Create one label per day or month in the period
      const currentDate = this.period.from.clone().startOf(this.mode === 'hourly' ? 'day' : 'month');
      const lastDate = this.period.to.clone().endOf(this.mode === 'hourly' ? 'day' : 'month');

      do {
        this.table.push({ timestampMoment: currentDate.clone() });
      }
      while (currentDate.add(1, this.mode === 'hourly' || this.minified ? 'day' : 'month').diff(lastDate) < 0)

      // Add default values based on whether the period is above threshold
      this.table.forEach(item => {
        item.values = [];

        if (!this.minified && this.mode === 'daily') {
          for (let i = 0; i < 31; i++) {
            const timestamp = moment.utc(item.timestampMoment).date(i + 1);

            if (timestamp.isSame(item.timestampMoment, 'month') && timestamp.isBetween(this.$store.state.period.from, this.$store.state.period.to)) {
              item.values.push({
                value: 'NO_DATA',
                timestamp
              });
            } else {
              item.values.push({
                value: 'INVALID_DATE'
              });
            }
          }
        } else {
          for (let i = 0; i < 24; i++) {
            item.values.push({
              value: 'NO_DATA',
              timestamp: moment.utc(item.timestampMoment).hour(i).minute(0).seconds(0).millisecond(0)
            });
          }
        }
      });

      this.injectData();

      this.loading = false;
    },
    injectData() {
      // Match real data with created table
      this.data.forEach(point => {
        const m = moment.utc(point.timestamp);

        // Start by finding the column to look in
        const column = this.table.findIndex(x => x.timestampMoment.isSame(moment.utc(point.timestamp), this.mode === 'daily' ? 'month' : 'day'));

        // Find the row, again depending on mode
        const row = this.mode === 'daily' ? m.date() - 1 : m.hour();

        // Set the value
        this.table[column].values[row].value = point.value;
      });

      this.$forceUpdate();
    },
    getColor(value) {
      if (typeof value === 'string') {
        if (value === 'NO_DATA') {
          return 'lightgrey';
        }
        else if (value === 'INVALID_DATE') {
          return 'white';
        }
      }

      else if (typeof value === 'number') {
        if (value === 100) {
          return '#003893';
        }
        else if (value < 100) {
          return '#eb9c66';
        }
        else {
          return 'yellow';
        }
      }

      else {
        return 'white';
      }
    },
    getStatus(value) {
      if (typeof value === 'string') {
        if (value === 'NO_DATA') {
          return 'No scrubber data';
        }
        else if (value === 'INVALID_DATE') {
          return 'Date does not exist or not chosen';
        }
      }

      else if (typeof value === 'number') {
        if (value === 100) {
          return 'Scrubber system available';
        }
        else if (value < 100) {
          return `Scrubber system ${this.$options.filters.formatKPI(value, 'Percent1Digit')}% available`;
        }
        else {
          return 'Operational availability outside of range!'
        }
      }

      else {
        return 'Error getting system status';
      }
    }
  },
  async created() {
    await this.fetchData();
  },
  watch: {
    period: {
      handler(value) {
        this.table = [];
        this.fetchData();
      },
      deep: true
    },
    data() {
      this.injectData();
    }
  }
}
</script>

<style scoped>
.v-menu__content {
  z-index: 0 !important;
}

.wrapper {
  position: relative;
  display: grid;
  grid-template-columns: [y-axis] 2.5rem [matrix] 1fr [end];
  width: 100%;
  height: 100%;
  min-height: 300px;
}

#y-axis {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  grid-column: y-axis / matrix;
  text-align: center;
  height: 92%;
  margin-right: 6px;
}

#y-axis .unit {
  transform: rotate(-90deg);
}

#x-axis {
  display: flex;
}

#matrix {
  display: flex;
  grid-column: matrix / end;
  width: 100%;
  height: 100%;
  gap: 2px;
  overflow-x: auto;
}

.date {
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  gap: 1px;
  min-width: 25px;
}

.timeslot {
  cursor: pointer;
  flex-grow: 1;
  border: 1px solid grey;
  min-height: 5px;
}

.timeslot:hover {
  opacity: 0.9;
}

.timeslot:active {
  opacity: 0.5;
}

.timeslot:not(:last-child) {
  margin-top: 2px;
}
</style>