export default {
  setUser(state, payload) {
    state.packageVersion = process.env.PACKAGE_VERSION || '0';
    state.user = payload;
  },
  setCurrentFacility(state, facility) {
    if (!state.currentFacility || state.currentFacility.id !== facility.id) {
      state.currentFacility = facility;

      // Also clear current measurement field as we can't be sure it's there for the new current facility
      state.currentMeasurementFieldId = null;
    }
  },
  updatePeriod(state, { from, to }) {
    state.period = { from, to };
  },
  updateFacilities(state, facilities) {
    state.facilities = facilities;
  },
  setFilteredFacilities (state, payload) {
    state.filteredFacilities = payload;
  },
  setFleetFilter (state, filter) {
    state.fleetFilter = Object.keys(filter);
  },
  updateOrganizations(state, payload) {
    state.organizations = payload;
  },
  setOfflineMode(state, payload) {
    state.offlineMode = payload;
  },
  setReportId(state, payload) {
    state.currentReportId = payload;
  },
  setMeasurementFieldId(state, payload) {
    state.currentMeasurementFieldId = payload;
  },
  setCurrentOrganization(state, payload) {
    state.currentOrganization = payload;
  },
  updateMeasurementFields(state, payload) {
    state.measurementFields = payload;
  },
  updateExecutions(state, payload) {
    state.executions = payload;
  },
  showNotification(state, payload) {
    // Only show a notification if the app is not offline.
    if (state.offlineMode) {
      return;
    }

    state.showNotification = true;
    state.notification = payload;

    if (!payload.persist) {
      setTimeout(() => {
        state.showNotification = false;

        setTimeout(() => { state.notification = {}; }, 300);
      }, 5000);
    }
  },
  hideNotification(state) {
    state.showNotification = false;
    setTimeout(() => { state.notification = {}; }, 300);
  },
  setMaintenanceMode(state, payload) {
    state.maintenanceMode = payload;
  },
  setMessages (state, payload) {
    state.messages = payload;
  }
}