import { UserManager, WebStorageStateStore, Log } from 'oidc-client';
import store from '@/store';
import * as Sentry from '@sentry/vue';

const config = window.settings.oidc;

const loco = window.location
const appRootUrl = `${loco.protocol}//${loco.host}${process.env.BASE_URL}`
const appRootUrlWithoutTrailingSlash = appRootUrl.replace(/\/$/, '');
const userManager = new UserManager({
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    authority: config.authority,
    client_id: config.clientId,
    redirect_uri: `${appRootUrl}callback.html`,
    automaticSilentRenew: true,
    silent_redirect_uri: `${appRootUrl}silent-renew.html`,
    response_type: config.responseType,
    scope: config.scope,
    post_logout_redirect_uri: appRootUrlWithoutTrailingSlash, // necessary to match the registred uri in backend
    filterProtocolClaims: true,
    loadUserInfo: true
});

Log.logger = console;
Log.level = Log.INFO;

userManager.events.addAccessTokenExpired(() => {
    console.log('Token expired.');
});

userManager.events.addSilentRenewError(() => {
    Sentry.addBreadcrumb({
        type: 'error',
        category: 'auth',
        message: 'Silent renew failed',
        data: store.user
    });
});

userManager.events.addUserLoaded(async () => {
    /**
     * This event fires after a successful silent renew.
     * Axios intercepts each request and adds the user's access token, so we need to update the store state with the new token.
     */
    store.commit('setUser', await userManager.getUser());
});

const authService = {
    getUser: () => userManager.getUser(),
    login: () => userManager.signinRedirect(),
    logout: () => userManager.signoutRedirect(),
    getAccessToken: () => userManager.getUser().then(data => data.access_token)
};

export default authService;
