export default class DataSourceReaderService {
    constructor(api, store) {
        this._api = api;
        this._store = store;
    }

    async getAll() {
        const response = await this._api(`algorithm/datasourcereader`);
        return response.data.dataSourceReaders;
    }

    async getById(readerId) {
        const response = await this._api(`algorithm/datasourcereader/${readerId}`);
        return response.data;
    }
}
