<template>
  <v-menu v-if="$store.getters.activeMessages.length" v-model="showMessageList" bottom left nudge-bottom="40" :close-on-content-click="false">
    <template v-slot:activator="{ attrs, on }">
      <!-- <v-btn v-bind="attrs" v-on="on" text>
        <v-badge :content="messages.length" color="red">
          <v-icon left>
            mdi-bell-outline
          </v-icon>
          1 unread message
        </v-badge>
      </v-btn> -->
      <v-btn v-bind="attrs" v-on="on" text fab small>
        <v-badge :value="unread.length" :content="unread.length" color="error">
          <v-icon>
            mdi-bell-outline
          </v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="primary--text">
        Messages
      </v-card-title>
      <v-card-text>
        <template v-for="message in $store.getters.activeMessages">
          <v-row :key="message.id">
            <v-col>
              <v-badge :value="!message.isRead" content="New" color="error" overlap offset-x="50" style="display: block">
                <v-card max-width="500">
                  <v-card-title v-if="message.title">
                    {{ message.title }}
                  </v-card-title>
                  <v-card-text v-html="htmlFormatLinebreaks(message.text)">                    
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-slide-x-reverse-transition>
                      <v-btn v-if="!message.isRead" @click="markRead(message.id)" text>
                        Mark as read
                      </v-btn>
                    </v-slide-x-reverse-transition>
                  </v-card-actions>
                </v-card>
              </v-badge>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'MessageCenter',
  data () {
    return {
      showMessageList: false
    }
  },
  computed: {
    unread () {
      return this.$store.getters.activeMessages.filter(item => !item.isRead);
    }    
  },
  methods: {
    markRead (messageId) {
      this.$store.dispatch('markMessageAsRead', messageId);
    },
    htmlFormatLinebreaks(msg) {
      return msg.replaceAll('\n', '<br/>');
    }
  },
  watch: {
    unread (value) {
      if (value.some(item => item.notify)) {
        setTimeout(() => {
          this.showMessageList = true;
        }, 200);
      }
    }
  }
}
</script>