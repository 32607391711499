<template>
  <v-card elevation="2" :loading="loading ? '#2b055d' : false">
    <v-card-title class="text-uppercase">
      <div>SFOC Impact</div>
      <v-spacer></v-spacer>
      <div class="d-flex align-center justify-space-between" style="gap: .5rem">
        <ExportShortcut
          reportCode="FacilityGroupSFOCimpactPctlarge"
        />
        <WidgetInfo
          v-if="report.information"
          title="SFOC impact"
          :content="report.information"
        />
      </div>
    </v-card-title>
    <v-card-text>
      <v-row style="height:440px">
        <v-col align="center" cols="4" xs="12" sm="12" md="12" lg="5" class="align-self-end text-carbon">
          <div style="height: 100%; " >
            <div style="height: 250px; width:100%;">
              <vue-speedometer
              :forceRender="true"
              :width="400"
              :height="250"
              valueTextFontSize="30px"
              :minValue="0"
              :maxValue="4"
              :segments="8"
              needleColor="#9E9E9E"
              :segmentColors='["#2b055d","#452077","#522D84","#5F3A90","#F0B80F","#7954A9","#8661B6","#926ec2"]'
              :value="speedometer.needle"
              :currentValueText="loading ? ' ' : `${speedometer.actual ? `${speedometer.actual}%` : 'No data'}`"
              />
            </div>
          </div>
        </v-col>
        <v-col cols="8" xs="12" sm="12" md="12" lg="7">
          <div id="sfoc-chart-wrapper">
            <SFOCImpactChart id="sfoc-chart" :ships="chartData" />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" xs="12" sm="12" md="12" lg="5">
          <p class="text-center">
            Average SFOC impact by scrubbers <br/><v-icon color="#F0B80F">mdi-square</v-icon> Industry standard: 2.0 - 2.5%
          </p>
        </v-col>
        <v-col cols="8" xs="12" sm="12" md="12" lg="7">
          <div class="text-center">
            SFOC impact by scrubbers for each vessel
          </div>

          <p class="text-center">
            <!-- TODO: Change to line -->
            <v-icon color="#2b055d">mdi-window-minimize</v-icon> Best performing in fleet
          </p>
        </v-col>
        </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import VueSpeedometer from 'vue-speedometer';
import WidgetBanner from '@/components/WidgetBanner.vue';
import SFOCImpactChart from '@/components/SFOCImpactChart.vue';
import WidgetInfo from '@/components/WidgetInfo.vue';
import ExportShortcut from '../components/ExportShortcut.vue';

export default {
  name: 'FleetSFOCImpactLarge',
  components: {
    VueSpeedometer,
    WidgetBanner,
    SFOCImpactChart,
    WidgetInfo,
    ExportShortcut
  },
  props: {
    period: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      report: {}
    }
  },
  computed: {
    speedometer() {
      const value = this.report?.fields?.ActualAverage?.value;
      const format = this.report?.fields?.ActualAverage?.format;

      const data = { actual: 0, needle: 0 };

      if (!this.loading && value && format) {
        data.actual = Number(this.$options.filters.formatKPI(value, format));

        // Set needle value to cap overflow
        data.needle = data.actual <= 4 ? data.actual : 4;
      }

      return data;
    },
    chartData() {
      let values = this.report?.fields?.Facilities?.values || [];
      let format = this.report?.fields?.Facilities?.format || 'Percent1Digits';

      const ships = values.map(x => {
        return {
          value: Number(this.$options.filters.formatKPI(x.value, format)),
          name: this.$store.getters.facilityById(x.facilityId).name
        };
      });

      ships.sort((a, b) => {
        return String(a.name).localeCompare(b.name);
      });

      return ships;
    },
    ...mapState([ 'filteredFacilities' ])
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.report = {};

      try {
        this.report = await this.$Services.reports.getReportValues('FacilityGroupSFOCimpactPctlarge', this.period.from, this.period.to);
        
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    period() {
      this.fetchData();
    },
    filteredFacilities() {
      this.fetchData();
    }
  }
}
</script>