export default class ScriptsService {
  baseUrl = 'scripts';

  constructor(api, store) {
    this._api = api;
  }

  async getPSDU(facilityId) {
    if (!facilityId) {
      throw 'Missing facility ID';
    }

    const response = await this._api({
      url: `${this.baseUrl}/psdu/facilities/${facilityId}`,
      method: 'GET',
      responseType: 'blob'
    });

    return response.data;
  }
}
