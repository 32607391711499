import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: null,
    facilities: [],
    filteredFacilities: [],
    fleetFilter: [],
    currentFacility: null,
    packageVersion: null,
    currentReportId: null,
    currentMeasurementFieldId: null,
    currentOrganization: null,
    measurementFields: [],
    maintenanceMode: window.settings.maintenance,
    offlineMode: false,
    showNotification: false,
    executions: [],
    messages: [],
    dataLoaded: false,
    notification: {},
    period: {},
    channelDataTypes: ['Double', 'Integer', 'UTCDateTime', 'Boolean', 'String'], // TODO: Add endpoint for this
    scrubberSystemTypes: [ 'Hybrid', 'Open Loop', '' ],
    defaultAutocomplete: [
      {
        name: 'TARGETTIMESTAMP',
        value: 'TARGETTIMESTAMP',
        caption: 'TARGETTIMESTAMP',
        meta: 'timestamp',
        type: 'local',
        score: 200
      },
      {
        name: 'Facility_IMO',
        value: 'Facility_IMO',
        caption: 'Facility_IMO',
        meta: 'string',
        type: 'local',
        score: 200
      },
      {
        name: 'Facility_NumberOfScrubbers',
        value: 'Facility_NumberOfScrubbers',
        caption: 'Facility_NumberOfScrubbers',
        meta: 'int',
        type: 'local',
        score: 200
      },
      {
        name: 'Facility_ScrubberSystemType',
        value: 'Facility_ScrubberSystemType',
        caption: 'Facility_ScrubberSystemType',
        meta: 'string',
        type: 'local',
        score: 200
      },
      {
        name: 'Facility_TotalEffectKW',
        value: 'Facility_TotalEffectKW',
        caption: 'Facility_TotalEffectKW',
        meta: 'int',
        type: 'local',
        score: 200
      },
      {
        name: 'ISECAZONE',
        value: 'ISECAZONE',
        caption: 'ISECAZONE',
        meta: 'boolean',
        type: 'local',
        score: 200
      }
    ],
    organizations: []
  },
  mutations,
  getters,
  actions
});

export default store;