<template>
  <v-dialog v-model="dialog" max-width="800">
    <template v-slot:activator="{ attrs, on }">
      <v-btn class="ml-4" v-if="mode === 'edit'" v-bind="attrs" v-on="on" color="primary" text>
        <v-icon left>
          mdi-pencil
        </v-icon>

        Edit organization
      </v-btn>

      <v-btn class="ml-4" v-else v-bind="attrs" v-on="on" color="primary" text>
        <v-icon left>
          mdi-plus
        </v-icon>

        Add organization
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        {{ data.id ? 'Edit' : 'Add' }} organization
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field v-model="data.name" label="Name" :rules="nameRules" :disabled="!$store.getters.hasAnyRole('SystemAdmin')" required></v-text-field>

          <v-text-field v-model="data.email" type="email" :rules="emailRules" label="Contact email"></v-text-field>

          <v-text-field v-model="data.phone" type="tel" label="Contact phone number"></v-text-field>

          <v-switch
            v-if="$store.getters.hasAnyRole('SystemAdmin')"
            v-model="data.priority"
            label="Priority organization"
          ></v-switch>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <!-- Note: Deleting organizations is disabled for the time being -->

        <!-- <v-dialog v-model="confirmDialog" max-width="500">
          <template v-slot:activator="{ attrs, on }">
            <v-btn v-show="data.id" v-bind="attrs" v-on="on" color="warning" :loading="deleting" text>
              <v-icon left>
                mdi-delete-outline
              </v-icon>
              Delete
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              Delete organization?
            </v-card-title>

            <v-card-text>
              Are you sure you want to delete this organization?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn @click="confirmDialog = false" text>
                No
              </v-btn>

              <v-btn color="warning" text>
                Yes, delete organization
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> -->

        <v-spacer></v-spacer>

        <v-btn @click="close" text>
          Close
        </v-btn>

        <v-btn v-show="!data.id" color="primary" :disabled="!valid" :loading="saving" @click="create">
          <v-icon left>
            mdi-plus-circle-outline
          </v-icon>
          Add
        </v-btn>

        <v-btn v-show="data.id" color="primary" :disabled="!valid" :loading="saving" @click="save">
          <v-icon left>
            mdi-content-save-outline
          </v-icon>
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'OrganizationDialog',
  //organizations[{ id, navn, facilities[{ id, navn, allocationType }] }]
  props: {
    mode: {
      type: String,
      required: true,
      validator: function (value) {
        // The value must match one of these strings
        return ['add', 'edit'].indexOf(value) !== -1
      }
    },
    data: {
      type: Object,
      default: () => { return {
        name: '',
        email: '',
        phone: ''
      }; }
    }
  },
  data() {
    return {
      valid: false,
      dialog: false,
      loading: false,
      confirmDialog: false,
      saving: false,
      deleting: false,
      nameRules: [
        value => !!value || 'Name is required'
      ],
      emailRules: [
        value => !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'E-mail must be valid'
      ]
    }
  },
  methods: {
    close() {
      this.dialog = false;

      if (this.mode === 'add') {
        this.$refs.form.reset();
      }
    },
    async create() {
      this.loading = true;
      try {
        const { name, email, phone, priority } = this.data;

        await this.$Services.organizations.create({ name, email, phone, priority });

        this.close();
        this.$emit('success');
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultSuccess');
      } finally {
        this.loading = false;
      }
    },
    async save() {
      this.saving = true;

      try {
        const { name, email, phone, priority } = this.data;

        await this.$Services.organizations.update(this.$route.params.organizationId, { name, email, phone, priority });

        this.$store.dispatch('showDefaultSuccess');
        this.close();
        this.$emit('success');
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.saving = false;
      }
    }
  }
}
</script>