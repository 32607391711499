<template>
  <div class="wrapper" :class="{ 'wrapper-left': position === 'left', 'wrapper-right': position === 'right', 'wrapper-loading': loading }">
    <div class="grey darken-1 white--text text-body-2 text-start px-3 py-1 d-flex justify-space-between" >
      <div class="mr-3 font-weight-light">
        {{ label }}
      </div>


      <v-slide-x-reverse-transition v-if="!disabled" hide-on-leave>
        <div v-if="!loading">
          {{ value !== '' ? value : 'No data' }}
        </div>
      </v-slide-x-reverse-transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WidgetBanner',
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    position: {
      type: String,
      default: () => 'left'
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.wrapper {
  position: absolute;
  margin-top: 12px;
  transition: margin .3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.wrapper.wrapper-right {
  margin-top: 16px;
}

.wrapper-left {
  left: 0;
}

.wrapper-left.wrapper-loading {
  margin-left: -200px;
}

.wrapper-right {
  right: 0;
}

.wrapper-right.wrapper-loading {
  margin-right: -200px;
}
</style>