<script>
import { Line } from 'vue-chartjs';
import moment from 'moment';

var _canvasContext = null;

export default {
  extends: Line,
  props: {
    name: {
      type: String,
      default: function() {
        return 'Ship'
      }
    },
    data: {
      type: Object,
      required: true,
      default: function () {
        return {
          limits: [],
          actuals: []
        };
      }
    }
  },
  computed: {
    chartData() {
      return {
        labels: [ this.$store.state.period.from.clone().subtract(moment().utcOffset(), 'minutes'), this.$store.state.period.to.clone().subtract(moment().utcOffset(), 'minutes').add(1, 'second') ],
        datasets: [
          {
            label: 'ECA/World limit',
            type: 'line',
            pointRadius: 0,
            fill: true,
            cubicInterpolationMode: 'monotone',
            bezierCurve: false,
            tension: 0,
            backgroundColor: 'rgba(0,141,117,0.1)',
            borderColor: 'rgba(0,141,117,0.2)',
            borderWidth: 1,
            stepped: true,
            data: this.mapLimits(this.data.limits),
            options: {
              stepped: true
            }
          },
          {
            label: 'Ship',
            type: 'line',
            pointRadius: 2,
            cubicInterpolationMode: 'monotone',
            spanGaps: false,
            fill: false,
            data: this.mapActuals(this.data.actuals)
          }
        ]
      };
    },
    xAxesTimeFormat() {
      //TODO: skal variere efter datediff i dataserien er høj

      return {
        unit: 'day',
        unitStepSize: 1,
        displayFormats: {
          day: 'MMM DD'
        }
      };
    },
    chartOptions() {
      return {
        legend: {
          display: false
        },
        layout: {
          padding: {
            top: 40
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false
        },
        scales: {
          x: {

          },
          xAxes: [
            {
              type: 'time',
              time: this.xAxesTimeFormat,
              display: true,
              scaleLabel: {
                display: false,
                labelString: 'Date'
              }
            }
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'SO2 / CO2 ratio'
              },
              ticks: {
                max: 25,
                suggestedMin: 0,
                suggestedMax: 0.5,
                callback: (value, index, values) => {
                  if (value == 25) {
                    return `>${value}`;
                  } else {
                    return `${value}`;
                  }
                }
              }
            }
          ]
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: (tooltipItems, data) => {
              if (data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].note) {
                return (
                  this.name +
                  ': ' +
                  data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].note
                );
              } else {
                return this.name + ': ' + tooltipItems.yLabel;
              }
            },
            title: (tooltipItems, data) => {
              if (data.datasets[tooltipItems[0].datasetIndex].data[tooltipItems[0].index].title) {
                return data.datasets[tooltipItems[0].datasetIndex].data[tooltipItems[0].index]
                  .title;
              } else {
                let timestamp = '';
                let date = new Date(tooltipItems[0].xLabel);
                let m = new moment(date);

                if (this.$store.getters.periodDifference > 30) {
                  timestamp = m.minute(0).format('DD. MMM YYYY');
                } else {
                  timestamp = `${m.minute(0).format('DD. MMM YYYY HH:mm')} - ${m.clone().add(1, 'hour').format('HH:mm')}`;
                }

                return timestamp;
              }
            }
          }
        }
      };
    }
  },
  methods: {
    mapActuals(data) {
      data = this.capOverflow(data);
      data = this.insertGaps(data);

      return data;
    },
    mapLimits(data) {
      data = this.insertVerticalLimitTransfers(data);
      data = this.insertGaps(data);

      return data;
    },
    capOverflow(data) {
      const points = [];

      data.forEach((item, index) => {
        // Cap the data point if it's bigger than threshold
        if (item.y > 25) {
          points.push({
            x: item.x,
            y: 25,
            note: `Actual ${item.y}`
          });
        } else {
          points.push(item);
        }
      });

      return points;
    },
    insertGaps(data) {
      const points = [];

      data.forEach((item, index) => {
        /**
         * If the time diff from the previous item is larger than a threshold, insert a null point just before this item.
         * This also has to account for the period, meaning that if the user has selected a period of more than 31 days
         * the response will contain daily values. If the period is less, the response is hourly values.
         */

        const timeUnit = this.$store.state.period.to.diff(this.$store.state.period.from, 'days') > 31 ? 'days' : 'hours';

        if (index !== 0 && moment(item.x).diff(data[index - 1].x, timeUnit) > 1) {
          const newPoint = {
            x: moment.utc(item.x).subtract(1, 'millisecond'),
            y: null
          };

          points.push(newPoint, item);
        } else {
          points.push(item);
        }
      });

      return points;
    },
    insertVerticalLimitTransfers(data) {
      // If ship passes from one zone to another, insert an extra data point in order for the line to be vertical.
      // Note: ChartJS has this builtin, vue-chartjs does not :(

      const points = [];

      for (let i = 0; i < data.length; i++) {
        if (data[i - 1] && data[i - 1].y !== data[i].y) {
          const newPoint = { x: moment.utc(data[i].x).subtract(1, 'milisecond').format('YYYY-MM-DDTHH:MM:SS'), y: data[i - 1].y };

          // Insert the new point
          points.push(newPoint);
        }

        points.push(data[i]);
      }

      return points;
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.chartOptions);
  },
  watch: {
    data() {
      this.renderChart(this.chartData, this.chartOptions);
    }
  }
};
</script>