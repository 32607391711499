<template>
  <v-container fluid style="width: 100%; height: 100%;">
    <v-spacer></v-spacer>
    <img alt="PureteQ Pure-SPOT" style="height: 35px" src="../assets/logo.png" />
    <img
      alt="PureteQ Pure-SPOT" width="80%"
      style="position: absolute; bottom: 0; left: 0; width: 80%; max-width:1071px"
      src="../assets/pq_element-06.png"
    />
    <v-spacer style="height: 200px"></v-spacer>
    <v-card class="mx-auto" max-width="500" elevation="5">
      <v-toolbar color="primary" dark>
        <v-toolbar-title>PureteQ Pure-SPOT login</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-text-field v-model="username" label="Username"></v-text-field>
        <v-text-field
          v-model="password"
          type="password"
          label="Password"
          @keyup.enter="login"
        ></v-text-field>
        <v-spacer></v-spacer>
        <div class="error--text" align="end">{{ loginResult }}</div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn type="submit" color="primary" @click="login">Log in</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
    <v-spacer></v-spacer>
  </v-container>
</template>

<script>
// @ is an alias to /src
// style="background: url('../assets/pq_element-06_nav.png'); background-color: #003893; width: 100%; height:100%;"
export default {
  name: "Login",

  data() {
    return {
      model: null,
      //View only
      username: null,
      password: null,
      loginResult: null,
    };
  },
  computed: {
    userModel() {
      return this.$store.getters.currentUser;
    },
  },
  mounted() {},
  methods: {
    //todo: use the styling here to 
    login() {
      var vm = this;

      vm.loginResult = "";

      if (vm.username === "test" && vm.password === "test") {
        vm.$AppService.api.get("user.json").then((response) => {
          vm.$store.commit("setUser", response.data);

          var d = new Date();
          d.setTime(d.getTime() + (30*60*1000));
          var expires = "expires="+ d.toUTCString();
          document.cookie = "authtoken=" + vm.userModel.authToken + ";" + expires + ";path=/";

          var returnUrl = vm.$route.query.returnUrl ?? "/";
          vm.$router.replace(returnUrl);
        });
      } else {
        vm.loginResult = "User not found...";
      }
    },
  },
};
</script>