<template>
  <v-container fluid>
    <v-row>
      <v-col v-for="(widget) of headerWidgets" :key="widget.code" cols="12" md="6" lg="4">
        <KPIWidget v-bind="widget" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <FleetMarpolComplianceLarge :period="period" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <FleetSO2CO2RatioLarge :period="period" />
      </v-col>
    </v-row>

    <v-row>
      <v-col v-for="widget of subWidgets" :key="widget.code" cols="12" md="6" lg="4">
        <KPIWidget v-bind="widget" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <FleetUptimeLarge :period="period" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <FleetSFOCImpactLarge :period="period" />
      </v-col>
    </v-row>

    <!-- Ships -->
    <v-row v-for="(facility,i) in $store.state.filteredFacilities" :key="i">
      <v-col>
        <ShipInfo type="fleet" :facility="facility" />
      </v-col>
    </v-row>
    
  </v-container>
</template>

<script>
// @ is an alias to /src
import KPIWidget from "../components/KPIWidget.vue";
import MarpolComplianceChart from '../components/MarpolComplianceChart.vue';
import ShipInfo from '@/components/ShipInfo.vue';
import WidgetBanner from '@/components/WidgetBanner.vue';
import FleetSO2CO2RatioLarge from '@/components/FleetSO2CO2RatioLarge.vue';
import FleetMarpolComplianceLarge from '@/components/FleetMarpolComplianceLarge.vue';
import FleetUptimeLarge from '@/components/FleetUptimeLarge.vue';
import FleetSFOCImpactLarge from '@/components/FleetSFOCImpactLarge.vue';
import WidgetInfo from '@/components/WidgetInfo.vue'

export default {
  name: "Fleet",
  components: {
    KPIWidget,
    MarpolComplianceChart,
    ShipInfo,
    WidgetBanner,
    FleetSO2CO2RatioLarge,
    FleetMarpolComplianceLarge,
    FleetUptimeLarge,
    FleetSFOCImpactLarge,
    WidgetInfo
  },
  data() {
    return {
      loading: false,
      so2co2Ratio: {},
      uptime: {},
      sfocImpact: {},
      headerWidgets: [
        {
          title: 'Extra removal of sulfur',
          code: 'FacilityGroupExtraremovalofsulfur',
          text: 'Combined value for 0.1% and 0.5% areas',
          kpiunit: 'T',
          color: 'accent',
          remark: 'Lifetime:',
          remarkIcon: 'mdi-counter',
          placeholder: 'xxx'
        },
        {
          title: 'CO2 reduction',
          code: 'FacilityGroupCO2reduction',
          text: 'Compared to operation on compliant fuel',
          kpiunit: 'T',
          color: 'darkgreen',
          remark: 'Lifetime:',
          remarkIcon: 'mdi-counter',
          placeholder: 'xx.x'
        },
        {
          title: 'Total CO2 emission',
          code: 'FacilityGroupTotalCO2Emission',
          text: 'Total emission of CO2',
          kpiunit: 'T',
          color: 'success',
          remark: 'Lifetime:',
          remarkIcon: 'mdi-counter',
          placeholder: 'xx'
        }
      ],
      subWidgets: [
        // {
        //   title: 'MARPOL Compliance',
        //   code: 'FacilityGroupMarpolcompliance',
        //   text: 'During scrubber operation',
        //   kpiunit: '%',
        //   color: 'orange',
        //   remark: 'Lifetime avg.:',
        //   remarkIcon: 'mdi-counter'
        // },
        // {
        //   title: 'Operational availability',
        //   code: 'FacilityGroupOperationalavailability',
        //   text: 'Scrubber system in use or available for use',
        //   kpiunit: '%',
        //   color: 'primary',
        //   remark: 'Lifetime avg.:',
        //   remarkIcon: 'mdi-counter'
        // },
        // {
        //   title: 'SFOC Impact %',
        //   code: 'FacilityGroupSFOCimpactPct',
        //   text: 'Energy impact by scrubbers',
        //   kpiunit: '%',
        //   color: 'purple',
        //   remark: 'Lifetime avg.:',
        //   remarkIcon: 'mdi-counter',
        //   placeholder: 'x.x'
        // }
      ]
    };
  },
  computed: {
    uptimeValues() {
      return this.uptime.fields?.Values?.values || [];
    },
    uptimeSpeedometerData() {
      const value = this.uptime.fields?.Average?.value;
      const format = this.uptime.fields?.Average?.format;

      const data = { actual: 0, needle: 0 };

      if (!this.loading) {
        data.actual = value && format ? Number(this.$options.filters.formatKPI(value, format)) : 70;

        // Set needle value to cap overflow
        data.needle = data.actual < 90 ? 90 : data.actual;
      }

      return data;
    },
    period() {
      return this.$store.state.period;
    }
  }
};
</script>