<template>
  <v-container fluid v-if="facility">
    <v-row>
      <v-col>
        <ShipInfo type="ship" :facility="facility" />
      </v-col>
    </v-row>

    <v-row>
      <v-col v-for="widget of headerWidgets" :key="widget.code" cols="12" md="6" lg="4">
        <KPIWidget v-bind="widget" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card elevation="2" :loading="loading ? '#757575' : false">
          <v-card-title>
            <div>
              SO<sub class="pr-2">2</sub> / CO<sub class="pr-2">2</sub> RATIO
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center justify-space-between" style="gap: .5rem">
              <ExportShortcut
                :facilityId="$route.params.id"
                reportCode="FacilitySO2CO2Ratiolarge"
              />
              <WidgetInfo
                v-if="so2co2Ratio.information"
                title="SO2/CO2 ratio relative to limits"
                :content="so2co2Ratio.information"
              />
            </div>
          </v-card-title>
          <v-card-text>
            <WidgetBanner
              :loading="loading"
              label="Fleet avg."
              :value="so2co2BannerValue"
            />
            <v-row>
              <v-col align="center" cols="4" xs="12" sm="12" md="12" lg="5" class="align-self-end">
                <div style="height: 100%; margin-bottom: 10px;">
                  <div style="height: 250px; width:100%; " class="text-carbon">
                    <div >
                      <vue-speedometer
                        class="so2co2-speedo"
                        :forceRender="true"
                        :width="400"
                        :height="250"
                        :paddingVertical="4"
                        :paddingHorizontal="6"
                        labelFontSize="16px"
                        valueTextFontSize="30px"
                        :minValue="100"
                        :maxValue="-30"
                        :segments="4"
                        :customSegmentStops='[100,50,10,0,-30]'
                        :segmentColors='["#757575","#00B697","#F2BF0D", "#f00"]'
                        :customSegmentLabels="[
                            {
                              text: 'Over-scrubbing',
                              position: 'OUTSIDE',
                              color: '#000000de',
                            },
                            {
                              text: 'Optimal',
                              position: 'OUTSIDE',
                              color: '#000000de',
                            },
                            {
                              text: 'Risk',
                              position: 'OUTSIDE',
                              color: '#000000de',
                            },
                            {
                              text: 'Under-scrubbing',
                              position: 'OUTSIDE',
                              color: '#000000de',
                            },
                          ]"
                        needleColor="#9E9E9E"
                        :value="so2co2SpeedometerData.needle"
                        :currentValueText="loading ? ' ' : `${so2co2SpeedometerData.actual ? `${so2co2SpeedometerData.actual}%` : 'No data'}`"
                        />
                    </div>
                  </div>
                </div>
                </v-col>
                <v-col align="center" cols="8" xs="12" sm="12" md="12" lg="7">
                  <ship-so2co2ratio-chart :name="facility.name" :data="so2co2ChartData" />
                </v-col>
              </v-row>
              <v-row>
                <v-col align="center" cols="4" xs="12" sm="12" md="12" lg="5">
                  <p class="text-center">
                    Average SO<sub>2</sub>/CO<sub>2</sub> ratio relative to limits
                     <!-- <br/> Lower than zero means scrubber achieved a ratio below applicable limits. -->
                  </p>
                </v-col>
                <v-col align="center" cols="8" xs="12" sm="12" md="12" lg="7">
                  <p class="text-center">
                    SO<sub>2</sub> / CO<sub>2</sub> ratio
                    <br/>
                    Green area illustrates applicable limits (4.3 or 21.7)
                  </p>
                </v-col>
              </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-for="widget of subWidgets" :key="widget.code" cols="12" md="6" lg="4">
        <KPIWidget v-bind="widget" />
      </v-col>
    </v-row>

    <!-- UP TIME  -->
    <v-row>
      <v-col cols="12">
        <v-card elevation="2" :loading="loading ? '#003893' : false">
          <v-card-title class="text-uppercase">
            <div>
              Operational availability
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center justify-space-between" style="gap: .5rem">
              <ExportShortcut
                :facilityId="$route.params.id"
                reportCode="FacilityOperationalAvailabilitylarge"
              />
              <WidgetInfo
                v-if="uptime.information"
                title="Operational availability"
                :content="uptime.information"
              />
            </div>
          </v-card-title>
          <v-card-text>
            <WidgetBanner
              :loading="loading"
              label="Fleet avg."
              :value="uptimeBannerValue"
            />
            <v-row style="height:440px">
              <v-col align="center" cols="4" xs="12" sm="12" md="12" lg="5" class="align-self-end">
                <div style="height: 100%; " >
                  <div style="height: 250px; width:100%;" class="text-carbon">
                    <vue-speedometer
                    :forceRender="true"
                    :width="400"
                    :height="250"
                    valueTextFontSize="30px"
                    :minValue="90"
                    :maxValue="100"
                    :segments="4"
                    :customSegmentStops= '[90, 96, 98, 100]'
                    needleColor="#9E9E9E"
                    startColor="#60AFDD"
                    endColor="#003893"
                    :segmentColors='["#3074B8", "#1856a6", "#003893"]'
                    :value="uptimeSpeedometerData.needle"
                    :currentValueText="loading ? ' ' : `${uptimeSpeedometerData.actual ? `${uptimeSpeedometerData.actual}%` : 'No data'}`"
                    />
                  </div>
                </div>
              </v-col>
              <v-col cols="8" xs="12" sm="12" md="12" lg="7">
                <UptimeMatrix ref="uptime" :mode="uptimeMode" :data="uptimeValues" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" xs="12" sm="12" md="12" lg="5" class="uptimeSpeedoTextContainer">
                <p class="uptimeSpeedoText">
                  Average scrubber operational availability
                </p>
              </v-col>
              <v-col cols="8" xs="12" sm="12" md="12" lg="7">
                <p class="text-center">
                  Detailed scrubber operational availability
                </p>
              </v-col>
              </v-row>
            </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  
</template>

<script>
import { mapState } from 'vuex';
import ValueVsTargetOverTimeChart from '@/components/ValueVsTargetOverTimeChart';
import FleetUptimeChart from '../components/FleetUptimeChart.vue'
import UptimeMatrix from '../components/UptimeMatrix.vue'
import VueSpeedometer from 'vue-speedometer'
import ComplianceChart from "../components/ComplianceChart.vue";
import KPIWidget from "../components/KPIWidget.vue";
import ShipSO2CO2RatioChart from "../components/ShipSO2CO2RatioChart"
import ShipInfo from '@/components/ShipInfo.vue'
import moment from "moment";
import WidgetInfo from '../components/WidgetInfo.vue';
import WidgetBanner from '../components/WidgetBanner.vue';
import ExportShortcut from '../components/ExportShortcut.vue';

export default {
  name: 'Ship',
  components: {
    'ValueVsTargetOverTimeChart': ValueVsTargetOverTimeChart,
    'vue-speedometer': VueSpeedometer,
    'uptime-chart': FleetUptimeChart,
    "compliance-chart": ComplianceChart,
    KPIWidget,
    "ship-so2co2ratio-chart": ShipSO2CO2RatioChart,
    ShipInfo,
    UptimeMatrix,
    WidgetInfo,
    WidgetBanner,
    ExportShortcut
  },
  data() {
    return {
      loading: false,
      facility: {},
      so2co2Ratio: {},
      uptime: {},
      headerWidgets: [
        {
          title: 'MARPOL Compliance',
          code: 'FacilityMarpolcompliance',
          text: 'During scrubber operation',
          bannerLabel: 'Fleet avg.',
          kpiunit: '%',
          color: 'secondary',
          remark: 'Lifetime avg.:',
          remarkIcon: 'mdi-counter'
        },
        {
          title: 'Operational availability',
          code: 'FacilityOperationalavailability',
          text: 'Scrubber system in use or available for use',
          bannerLabel: 'Fleet avg.',
          kpiunit: '%',
          color: 'primary',
          remark: 'Lifetime avg.:',
          remarkIcon: 'mdi-counter'
        },
        {
          title: 'CO2 reduction',
          code: 'FacilityCO2reduction',
          text: 'Compared to operation on compliant fuel',
          bannerLabel: 'Fleet',
          kpiunit: 'T',
          color: 'darkgreen',
          remark: 'Lifetime:',
          remarkIcon: 'mdi-counter',
          placeholder: 'xx.x'
        }
      ],
      subWidgets: [
        {
          title: 'Extra removal of sulfur',
          code: 'FacilityExtraremovalofsulfur',
          text: 'Combined value for 0.1% and 0.5% areas',
          bannerLabel: 'Fleet.',
          kpiunit: 'T',
          color: 'accent',
          remark: 'Lifetime:',
          remarkIcon: 'mdi-counter',
          placeholder: 'xxx'
        },
        {
          title: 'SFOC Impact %',
          code: 'FacilitySFOCimpactPct',
          text: 'Energy impact by scrubbers',
          bannerLabel: 'Fleet avg.',
          kpiunit: '%',
          color: 'purple',
          remark: 'Lifetime avg.:',
          remarkIcon: 'mdi-counter',
          placeholder: 'x.x'
        },
        {
          title: 'Total CO2 emission',
          code: 'FacilityTotalCO2Emission',
          text: 'Total emission of CO2',
          bannerLabel: 'Fleet sum',
          kpiunit: 'T',
          color: 'success',
          remark: 'Lifetime sum:',
          remarkIcon: 'mdi-counter',
          placeholder: 'x.x'
        }
      ],
      threshold: moment.duration(3, 'months')
    }
  },
  computed: {
    shipparam() {
      return this.$route.params.id ?? "";
    },
    so2co2BannerValue() {
      if (this.so2co2Ratio.fields?.FleetActual?.value && this.so2co2Ratio.fields?.FleetActual?.format) {
        const { value, format } = this.so2co2Ratio.fields.FleetActual;

        return `${this.$options.filters.formatKPI(value, format)}%`;
      }

      else {
        return '';
      }
    },
    so2co2ChartData() {
      const data = {
        actuals: [],
        limits: []
      };

      if (this.so2co2Ratio.fields) {
        if (this.so2co2Ratio.fields.Facility && this.so2co2Ratio.fields.Facility.values && this.so2co2Ratio.fields.Facility.values[0].values) {
          data.actuals = this.so2co2Ratio.fields.Facility.values[0].values.map(value => ({ x: moment.utc(value.timestamp).format('YYYY-MM-DDTHH:MM:SS'), y: Number(value.value).toFixedNoRound(2) }));
          data.actuals.sort((a, b) => this.sortByTimestamp(a, b));
        }

        if (this.so2co2Ratio.fields.Limit && this.so2co2Ratio.fields.Limit.values && this.so2co2Ratio.fields.Limit.values[0].values) {
          data.limits = this.so2co2Ratio.fields.Limit.values[0].values.map(value => ({ x: moment.utc(value.timestamp).format('YYYY-MM-DDTHH:MM:SS'), y: value.value }));
          data.limits.sort((a, b) => this.sortByTimestamp(a, b));
        }
      }

      return data;
    },
    uptimeValues() {
      return this.uptime.fields?.Values?.values || [];
    },
    uptimeBannerValue() {
      if (this.uptime.fields?.FleetActual?.value && this.uptime.fields?.FleetActual?.format) {
        const { value, format } = this.uptime.fields.FleetActual;

        return `${this.$options.filters.formatKPI(value, format)}%`;
      }

      else {
        return '';
      }
    },
    uptimeSpeedometerData() {
      const value = this.uptime?.fields?.Average?.value;
      const format = this.uptime?.fields?.Average?.format;

      const data = { actual: 0, needle: 90 };

      if (!this.loading && value && format) {
        data.actual = Number(this.$options.filters.formatKPI(value, format));

        // Set needle value to cap overflow
        data.needle = data.actual < 90 ? 90 : data.actual;
      }

      return data;
    },
    so2co2SpeedometerData() {
      const value = this.so2co2Ratio?.fields?.ActualAverage?.value;
      const format = this.so2co2Ratio?.fields?.ActualAverage?.format;

      const data = { actual: null, needle: 100 };

      if (!this.loading && value && format) {
        data.actual = Number(this.$options.filters.formatKPI(value, format));

        // Set needle value to cap overflow
        if (data.actual < -30) {
          data.needle = -30;
        }
        else if (data.actual > 100) {
          data.needle = 100;
        }
        else{
          data.needle = data.actual;
        }
      }

      return data;
    },
    uptimeMode() {
      const periodDiff = moment.duration(this.period.to.diff(this.period.from, 'days'), 'days').asDays();

      return periodDiff > this.threshold.asDays() ? 'daily' : 'hourly';
    },
    ...mapState([ 'period', 'filteredFacilities' ])
  },
  async mounted() {
    this.$vuetify.goTo(0, { easing: 'easeInOutCubic', duration: 300 });
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        this.so2co2Ratio = {};
        this.uptime = {};

        const facilityId = this.$route.params.id;

        this.facility = await this.$Services.facilities.getFacility(facilityId);
        this.so2co2Ratio = await this.$Services.reports.getReportValues('FacilitySO2CO2Ratiolarge', this.period.from, this.period.to, facilityId);
        this.uptime = await this.$Services.reports.getReportValues('FacilityOperationalAvailabilitylarge', this.period.from, this.period.to, facilityId, this.uptimeMode);
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.loading = false;
      }
    },
    sortByTimestamp(a, b) {
      const current = moment(a.x).unix();
      const next = moment(b.x).unix();

      if (current > next) {
        return 1;
      }

      if (current < next) {
        return -1;
      }

      return 0;
    }
  },
  watch: {
    period(newValue) {
      this.fetchData();
    },
    filteredFacilities() {
      this.fetchData();
    }
  }
}
</script>

<style scoped>
td {
  text-align: right;
}
</style>