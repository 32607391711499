<script>
import { Bar } from "vue-chartjs";
import moment from "moment";

var _canvasContext = null;

export default {
  extends: Bar,
  props: {
    ships: {
      type: Array,
      required: true,
      default: function() {
        return {
          actuals: []
        }
      }
    }
  },
  data() {
    return {
      chart: null
    }
  },
  computed: {
    chartData() {
      return {
        labels: this.ships.map(ship => ship.name),
        datasets: [{
          label: 'SO2/CO2 ratio',
          color: '#ccc',
          data: this.ships.map(ship => ship.value),
          backgroundColor: this.ships.map(ship => this.getColor(ship.value))
        }]
      }
    },
    chartOptions() {
      return {
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        title:{
          display:false
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Average SO2/CO2 ratio relative to limits (in %)'
            },
            suggestedMin: 0,
            max: 100,
            ticks: {
              max: 100,
              suggestedMin: 0,
              stepSize: 5,
              callback: function(value) {
                switch (value) {
                  case 100:
                    return '100';
                  case 50:
                    return '50';
                  case 10:
                    return '10';
                  case 0:
                    return '0';
                  case -30:
                    return '-30';
                }
              }
            }
          }]
        }
      }
    }
  },
  methods: {
    mapActuals(data) {
      data = this.capOverflow(data);
      data = this.insertGaps(data);

      return data;
    },
    getColor(value) {
      if (value >= 50) {
        return '#757575';
      }

      else if (value >= 10) {
        return '#00B697';
      }

      else if (value >= 0) {
        return '#F2BF0D';
      }

      else {
        return '#f00'
      }
    },
    capOverflow(data) {
      return data.map(item => {
        return {
          x: item.x,
          y: item.y > 20 ? 20 : item.y,
          note: item.y > 20 ? `Actual ${item.y}` : null
        }
      });
    }
  },
  
  mounted() {
    this.renderChart(this.chartData, this.chartOptions);
  },
  watch: {
    ships() {
      this.renderChart(this.chartData, this.chartOptions);
    }
  }
}

</script>