<template>
  <div>
    <v-tabs background-color="primary" dark centered>
      <v-tab to="/report-config/data-export">
        Export data
      </v-tab>

      <v-tab to="/report-config/calculation-queue">
        Calculation queue
      </v-tab>

      <v-tab to="/report-config/overview">
        Reports
      </v-tab>

      <v-tab :disabled="!hasChosenReport" to="/report-config/detail">
        Editor
      </v-tab>
    </v-tabs>

    <router-view></router-view>
  </div>
</template>

<script>
import ConfiguratorList from './ConfiguratorList'
import ConfiguratorDetail from './ConfiguratorDetail'
import ConfiguratorEditor from './ConfiguratorEditor'

export default {
  components: {
    ConfiguratorList,
    ConfiguratorDetail,
    ConfiguratorEditor
  },
  data() {
    return {
      tab: null,
      items: [
        { tab: 'Overview', component: 'ConfiguratorList' },
        { tab: 'Detail', component: 'ConfiguratorDetail' },
        { tab: 'Editor', component: 'ConfiguratorEditor' }
      ]
    }
  },
  computed: {
    hasChosenReport() {
      return !!this.$store.state.currentReportId;
    }
  },
  async mounted() {
    this.$store.dispatch('getMeasurementFields');
  }
}
</script>