<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row align="center">
              <v-col>
                <v-select v-if="this.$store.getters.hasAnyRole(['SystemAdmin'])" v-model="organizationId" :items="organizations" item-value="id" item-text="name" dense solo hide-details=""></v-select>
              </v-col>

              <v-col>
                <div class="d-flex align-center justify-end">
                  <!-- <v-text-field label="Search for organizations" solo hide-details=""></v-text-field> -->
                  <v-fade-transition>
                    <OrganizationDialog mode="edit" :data="organization" @success="$store.dispatch('getOrganizations')" />
                  </v-fade-transition>

                  <LinkOrganizationDialog @update="fetchData" />

                  <v-btn class="ml-4" color="primary" @click="openUserDialog({ roles: ['User'] })" text>
                    <v-icon left>
                      mdi-account-plus-outline
                    </v-icon>
                    Add user
                  </v-btn>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <div v-if="!loading && ships && users" role="region">
                  <table>
                    <thead>
                      <tr>
                        <th>Ship</th>
                        <th v-for="user in users" :key="user.id">
                          <div class="d-flex align-center justify-center">
                            {{ user.username }}
                            <v-btn @click="openUserDialog(user)" icon small>
                              <v-icon small>
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                          </div>
                        </th>
                        <th v-for="organization in organization.linkedOrganizations" :key="organization.id">
                          <div class="d-flex align-center justify-center">
                            <v-icon left small>
                              mdi-account-group-outline
                            </v-icon>
                            {{ organization.name }}
                            <v-btn @click="openConfirmUnlinkDialog(organization)" icon small>
                              <v-icon small>
                                mdi-delete-outline
                              </v-icon>
                            </v-btn>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(ship) in ships" :key="ship.id">
                        <th>
                          <v-row no-gutters align="center">
                            <v-col>
                              {{ ship.name }}
                            </v-col>
                            <v-col class="d-flex align-center" v-if="ship.primaryOrganizationId !== $route.params.organizationId">
                              <span class="text--secondary mr-2">{{ ship.primaryOrganizationName }}</span>
                              <v-btn @click="openAccessDialog(ship)" icon>
                                <v-icon>
                                  mdi-delete-outline
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </th>
                        <td class="text-center" v-for="user in users" :key="user.id">
                          <v-simple-checkbox :value="user.facilityIds.includes(ship.id)" @input="toggleAccess(user.id, ship.id, $event)"></v-simple-checkbox>
                        </td>
                        <td class="text-center" v-for="organization in organization.linkedOrganizations" :key="organization.id">
                          <v-simple-checkbox :value="organization.facilityIds.includes(ship.id)" @input="toggleOrganizationAccess(organization.id, ship.id, $event)"></v-simple-checkbox>
                        </td>
                      </tr>
                      <tr v-if="!ships.length">
                        <td>
                          No ships in the organization
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div v-else-if="!loading && (!ships || !users)">
                  <v-icon>
                    mdi-alert
                  </v-icon>
                </div>

                <v-skeleton-loader v-else type="table"></v-skeleton-loader>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="userDialog" max-width="800">
      <UserDialog :user="userDialogData" @close="userDialog = false" @update="fetchData" />
    </v-dialog>

    <v-dialog v-model="removeAccessDialog" max-width="500">
      <RemoveLinkedFacilityDialog :ship="removeAccessDialogData" :toggle="toggleOrganizationAccess" @close="removeAccessDialog = false" @update="fetchData" />
    </v-dialog>

    <v-dialog v-model="confirmUnlinkDialog" max-width="500">
      <ConfirmUnlinkDialog :organization="confirmUnlinkDialogData" :toggle="toggleOrganizationAccess" @close="confirmUnlinkDialog = false" @update="fetchData" />
    </v-dialog>
  </div>
</template>

<script>
import UserDialog from '@/components/UserDialog.vue'
import LinkOrganizationDialog from '@/components/LinkOrganizationDialog.vue'
import OrganizationDialog from '@/components/OrganizationDialog.vue'
import RemoveLinkedFacilityDialog from '@/components/RemoveLinkedFacilityDialog.vue'
import ConfirmUnlinkDialog from '@/components/ConfirmUnlinkDialog.vue'

export default {
  name: 'ShipAccess',
  components: {
    UserDialog,
    LinkOrganizationDialog,
    OrganizationDialog,
    RemoveLinkedFacilityDialog,
    ConfirmUnlinkDialog
  },
  data() {
    return {
      loading: false,
      organization: {},
      users: null,
      ships: [],
      userDialog: false,
      userDialogData: {},
      removeAccessDialog: false,
      removeAccessDialogData: {},
      confirmUnlinkDialog: false,
      confirmUnlinkDialogData: {}
    }
  },
  computed: {
    headers() {
      const headers = this.users.map(user => ({
        text: user.name,
        value: user.id,
        align: 'center'
      }));

      return [ { text: 'Ships' }, ...headers ];
    },
    organizations() {
      return this.$store.getters.organizations;
    },
    organizationId: {
      get() {
        return this.organization.id;
      },
      set(value) {
        this.organization = this.organizations.find(item => item.id === value);
      }
    }
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        const organizationId = this.$route.params.organizationId;

        this.organization = await this.$Services.organizations.getById(organizationId);
        this.users = await this.$Services.organizations.getUsers(organizationId);
        this.ships = await this.$Services.facilities.getFacilities(organizationId, true);

        // Update current organizationId in store
        this.$store.commit('setCurrentOrganization', this.organization);
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.loading = false;
      }
    },
    async toggleAccess(userId, facilityId, value) {
      const organizationId = this.$route.params.organizationId;

      try {
        await this.$Services.organizations.toggleFacilityAccess(userId, facilityId, value);

        this.users = await this.$Services.organizations.getUsers(organizationId);
        this.$store.dispatch('showDefaultSuccess');
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      }
    },
    async toggleOrganizationAccess(organizationId, facilityId, value) {
      try {
        await this.$Services.organizations.toggleOrganizationFacilityAccess(organizationId, facilityId, value);

        this.fetchData();
        this.$store.dispatch('showDefaultSuccess');
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      }
    },
    openUserDialog(user = {}) {
      this.userDialogData = user;
      this.userDialog = true;
    },
    openAccessDialog(ship = {}) {
      this.removeAccessDialogData = ship;
      this.removeAccessDialog = true;
    },
    openConfirmUnlinkDialog(organization) {
      this.confirmUnlinkDialogData = organization;
      this.confirmUnlinkDialog = true;
    },
    onScroll(event) {
      console.debug(event.target.scrollTop);
    }
  },
  created() {
    this.fetchData();
  },
  watch: {
    organizationId(newValue, oldValue) {
      if (oldValue && oldValue !== newValue) {
        this.$router.replace(`/admin/${newValue}/manage`);
      }
    }
  }
}
</script>

<style scoped>
table {
  white-space: nowrap;
  border: none;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  min-width: 100%;
}
table tbody td,
table tbody th {
  border-top: 1px solid #ddd;
  padding: 0.5rem;
}
table thead th {
  padding-left: 1rem;
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
  border-bottom: 2px solid #ddd;
}
table td {
  background: #fff;
  padding: .5rem;
}

table thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  border-right: 2px solid #ddd;
  text-align: left;
  padding: .5rem;
}
table tbody th {
  position: sticky;
  left: 0;
  background: white;
  text-align: left;
  z-index: 1;
  font-weight: normal;
  border-right: 2px solid #ddd;
}

[role="region"] {
  width: 100%;
  max-height: 80vh;
  overflow: auto;
}
</style>