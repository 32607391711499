import axios from 'axios'

export default class AppService {
  constructor() {
    this.api = axios.create({
      baseURL: '/tmpdata/'
    });
  }
  
  login = async (username, password, store) => {
    
  }

  logoff = async () => {
    
  }
}
