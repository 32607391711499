export default class ChannelService {
  baseUrl = 'algorithm/facilitydatasource';

  constructor(api, store) {
    this._api = api;
    this._store = store;
  }

  async getAll(dataSourceId) {
    const response = await this._api(`${this.baseUrl}/${dataSourceId}/samplechannels`);
    return response.data.list;
  }

  async getMapped(dataSourceId) {
    const response = await this._api(`${this.baseUrl}/${dataSourceId}/facilitydatasourcechannels`);
    return response.data.list;
  }

  async create(dataSourceId, data) {
    const response = await this._api.post(`${this.baseUrl}/${dataSourceId}/facilitydatasourcechannels`, data);
    return response;
  }

  async update(dataSourceId, channelId, data) {
    const response = await this._api.put(`${this.baseUrl}/${dataSourceId}/facilitydatasourcechannels/${channelId}`, data);
    return response;
  }

  async delete(dataSourceId, channelId) {
    const response = await this._api.delete(`${this.baseUrl}/${dataSourceId}/facilitydatasourcechannels/${channelId}`);
    return response;
  }
}
