<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <h3 class="warning--text">Documents relevant for you</h3>
            <h1>Documents</h1>
          </v-col>
          <v-col cols="6">
            <p>
              Expand each ship to see which reports are available for you to
              download.
            </p>
            Use the fleet filter in the left hand navigation to narrow down
            the view of available documents.
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            class="mt-0 pt-0"
            single-line
            hide-details
          ></v-text-field>
          <v-data-table
            class="mt-5"
            :search="search"
            :headers="headers"
            :items="facilities"
            :items-per-page="-1"
            group-by="shipName"
            sort-by="visitOrActionDate"
            sort-desc           
            show-group-by
            disable-pagination
            hide-default-footer
          >
            <template #group.header="{group, isOpen, toggle, remove}">
               <td colspan="6" class="text-start">
                  <v-btn icon @click="toggle">
                    <v-icon> {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }} </v-icon>
                  </v-btn>
                  {{ group }}
                  <v-btn small icon @click="remove">
                    <v-icon> mdi-minus </v-icon>
                  </v-btn>
               </td>
            </template>
            <template #item.visitOrActionDate="{ value }">
              {{ value || "No date" }}
            </template>
            <template #item.docDate="{ value }">
              {{ value || "No date" }}
            </template>
            <template #item.expiry="{ value }">
              {{ value || "N/A" }}
            </template>
            <template #item.equipmentTag="{ value }">
              {{ value || "N/A" }}
            </template>
            <template #item.fileName="{ item }">
                <v-btn
                  :loading="fileLoading === item.fileId"
                  small
                  icon
                  :title="`Download ${item.fileName}`"
                  color="primary"
                  @click="downloadFile(item.fileRef, item.fileId)"
                >
                  <v-icon> mdi-download-outline </v-icon>
                </v-btn>
                {{ item.fileName }}
              </template>
          </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ServiceReports",
  data() {
    return {
      search: '',
      loading: false,
      fileLoading: null,
      headers: [
        {
          text: "Ship",
          value: "shipName"
        },
        {
          text: "Document type",
          value: "docType"
        },
        {
          text: "Visit/Action date",
          value: "visitOrActionDate",
          groupable: false
        },
        {
          text: "Document date",
          value: "docDate",
          groupable: false
        },
        {
          text: "Expiry date",
          value: "expiry",
          groupable: false
        },
        {
          text: "Equipment tag",
          value: "equipmentTag",
          groupable: false
        },
        {
          text: "File name",
          value: "fileName",
          groupable: false
        }
      ]
    };
  },
  computed: {
    facilities: function () {
      const facdocs = this.$store.state.filteredFacilities.map((facility) => {
        return facility.documents?.map(document => ({
          ...document,
          shipName: facility.name
        })) ?? [];
      });

      let docs = [];

      facdocs.forEach(element => {
        docs = [...docs, ...element];
      });

      return docs;
    },
  },
  mounted() {
    this.$Services.users.logVisit(this.$store.getters.currentUserId);
    this.$store.getters.activeMessages.filter(x => x.type === 'FacilityDocuments').forEach(message => {
      this.$store.dispatch('markMessageAsRead', message.id);
    });
  },
  methods: {
    async downloadFile(fileRef, fileId) {
      try {
        this.fileLoading = fileId;

        await this.$Services.facilities
          .downloadFacilityDocument({ fileRef })
          .then(async (response) => {
            let buffer = await response.arrayBuffer();
            const objUrl = URL.createObjectURL(new Blob([buffer]));

            const link = document.createElement("a");
            link.href = objUrl;
            link.download = fileRef.split("/").pop();
            link.click();

            // Clean virtual DOM and release memory.
            URL.revokeObjectURL(objUrl);
            buffer = null;
            link.remove();
          });
      } catch (error) {
        console.warn(error);
      } finally {
        this.fileLoading = null;
      }
    },
  },
};
</script>