<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              <div class="d-flex align-bottom">
                <h1>Messages</h1>

                <v-spacer></v-spacer>

                <v-fade-transition>
                  <v-btn v-if="!message" color="primary" @click="initNewMessage" text>
                    <v-icon left>mdi-plus</v-icon>
                    Add message
                  </v-btn>
                </v-fade-transition>
              </div>
            </v-col>
          </v-row>

          <v-slide-y-transition>
            <v-card v-if="message" class="my-6">
              <v-card-title>
                {{ !message.id ? 'Compose new' : 'Edit' }} message

                <v-spacer></v-spacer>
                
                <v-btn text @click="insertDefaultMessage">
                  <v-icon left>mdi-tooltip-plus-outline</v-icon>
                  Insert default text
                </v-btn>
              </v-card-title>

              <v-card-text>
                <v-form v-model="valid">
                  <v-text-field
                    v-model="message.title"
                    label="Title"
                  ></v-text-field>
                  <v-textarea
                    v-model="message.text"
                    label="Message"
                    placeholder="This is the text users will see as the message body."
                    :rules="rules.text"
                  ></v-textarea>

                  <v-row>
                    <v-col>
                      <DatetimePicker v-model="message.showFrom" label="Show from (UTC)" :max="message.showTo" :rules="rules.showFrom" />
                    </v-col>
                    <v-col>
                      <DatetimePicker v-model="message.showTo" label="Show until (UTC)" :min="message.showFrom" :rules="rules.showTo" />
                    </v-col>
                  </v-row>

                  <v-checkbox
                    v-model="message.notify"
                    label="Notify users"
                  ></v-checkbox>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn @click.stop="cancelMessage" text>
                  Cancel
                </v-btn>

                <v-btn color="primary" :loading="saving" :disabled="!valid" @click="createMessage" text>
                  <v-icon left>mdi-send-outline</v-icon>
                  Save
                </v-btn>

                <v-dialog v-model="confirmCancelDialog" width="500">
                  <v-card>
                    <v-card-title>
                      Discard message?
                    </v-card-title>

                    <v-card-text>
                      Are you sure you want to discard your new message?
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn @click="confirmCancelDialog = false" text>
                        No, keep editing
                      </v-btn>

                      <v-btn color="warning" @click="confirmCancelDialog = false; message = null" text>
                        Yes, discard message
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card-actions>
            </v-card>
          </v-slide-y-transition>

          <v-row>
            <v-col>
              <h2>
                Active messages ({{ activeMessages.length }})
              </h2>
            </v-col>
          </v-row>

          <v-row>
            <v-col v-if="activeMessages.length">
              <MessageListItem v-for="item in activeMessages" :key="item.id" v-bind="item" type="active" @edit="message = item" />
            </v-col>
            <v-col v-else>
              No upcoming messages.
            </v-col>
          </v-row>

          <v-row class="mt-8">
            <v-col>
              <h2>
                Upcoming messages ({{ upcomingMessages.length }})
              </h2>
            </v-col>
          </v-row>

          <v-row>
            <v-col v-if="upcomingMessages.length">
              <MessageListItem v-for="item in upcomingMessages" :key="item.id" v-bind="item" type="upcoming" @edit="message = item" />
            </v-col>
            <v-col v-else>
              No upcoming messages.
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-right">
              <v-btn @click="onToggleHistory" text>
                {{ showPastMessages ? 'Hide' : 'Show' }} past messages
              </v-btn>
            </v-col>
          </v-row>

          <v-scroll-y-transition>
            <v-row v-if="showPastMessages">
              <v-col>
                <MessageListItem v-for="item in pastMessages" :key="item.id" v-bind="item" type="past" @edit="message = item" />
              </v-col>
            </v-row>
          </v-scroll-y-transition>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DatetimePicker from '@/components/DatetimePicker'
import MessageListItem from '@/components/MessageListItem'

export default {
  name: 'Messages',
  components: {
    DatetimePicker,
    MessageListItem
  },
  data () {
    return {
      valid: false,
      message: null,
      loading: false,
      loadingHistory: false,
      saving: false,
      confirmCancelDialog: false,
      showPastMessages: false,
      messages: this.$store.state.messages,
      defaultTitle: 'Maintenance update',
      defaultText: 'Pure-SPOT is undergoing a scheduled update and maintenance check.\nThe system is still functional, but you may experience longer response time. We expect to be back in normal operation at ?? am/pm UTC.',
      rules: {
        text: [
          v => !!v || 'Message is required'
        ],
        showFrom: [
          v => !!v || 'Specify when the message is shown from',
          v => !this.message.showTo || this.moment.utc(v).isBefore(this.message.showTo) || 'Must be before "Show to"'
        ],
        showTo: [
          v => !!v || 'Specify when the message is shown to',
          v => !this.message.showFrom || this.moment.utc(v).isAfter(this.message.showFrom) || 'Must be after "Show from"'
        ]
      }
    }
  },
  computed: {
    activeMessages () {
      return this.$store.getters.activeMessages.filter(message => !message.isPersonal);
    },
    upcomingMessages () {
      return this.$store.state.messages.filter(message => this.moment.utc().isBefore(message.showFrom));
    },
    pastMessages () {
      return this.$store.state.messages.filter(message => this.moment.utc().isAfter(message.showTo));
    },
  },
  methods: {
    initNewMessage () {
      this.message = {
        title: '',
        text: '',
        showFrom: '',
        showTo: '',
        notify: null
      };
    },
    insertDefaultMessage () {
      this.message.title = this.defaultTitle;
      this.message.text = this.defaultText;
    },
    cancelMessage () {
      if (this.message.title || this.message.text || this.message.showFrom || this.message.showTo) {
        this.confirmCancelDialog = true;
      }

      else {
        this.message = null;
      }
    },
    async createMessage () {
      this.saving = true;

      await this.$store.dispatch('addMessage', this.message);

      this.message = null;
      this.saving = false;
    },
    async onToggleHistory () {
      if (this.pastMessages.length) {
        this.showPastMessages = !this.showPastMessages;
      }
      else {
        this.loadingHistory = true;

        try {
          await this.$store.dispatch('getMessages', false);

          this.showPastMessages = this.pastMessages.length;
        } catch (error) {
          console.error(error);
          this.$store.dispatch('showDefaultError', 'Error getting message history');
        } finally {
          this.loadingHistory = false;
        }
      }
    }
  }
}
</script>