<template>
  <div>
    <v-toolbar color="secondary" extended dark>
      <v-btn :disabled="slug === 'overview'" to="/facilities" exact icon>
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-btn>

      <v-toolbar-title class="d-flex align-center">
        <v-icon left>
          mdi-{{ slug === 'overview' ? 'view-list' : 'ferry' }}
        </v-icon>

        <span>
          {{ slug === 'overview' ? 'Facility overview' : `${facility.name}${facility.imo ? ' - IMO: ' + facility.imo : ''}` }}
        </span>

        <v-chip v-if="$store.state.filteredFacilities.length !== $store.state.facilities.length" class="ml-4" color="secondary">
          Showing {{ $store.state.filteredFacilities.length }} of {{ $store.state.facilities.length }}
        </v-chip>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-toolbar-items v-if="navItems.map(x => x.path).includes(slug) || slug === 'detail'">
        <template v-for="(item, index) of navItems">
          <v-btn :key="index" :to="item.path" :disabled="item.disabled" text>
            {{ item.name }}
          </v-btn>
        </template>

        <v-btn :to="`detail?id=${currentMeasurementFieldId}`" :disabled="!currentMeasurementFieldId" text>
          Editor
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <transition name="slide-right" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Facilities',
  data() {
    return {
      navItems: [
        {
          name: 'Info',
          path: 'info'
        },
        {
          name: 'Data sources',
          path: 'channels'
        },
        {
          name: 'Calculation queue',
          path: 'calculation-queue'
        },
        {
          name: 'Measurement fields',
          path: 'list'
        }
      ]
    }
  },
  computed: {
    facility() {
      return this.$route.params.facilityId ? this.$store.getters.facilityById(this.$route.params.facilityId) : {}
    },
    slug() {
      const parts = this.$route.path.split('/');
      return parts[parts.length - 1];
    },
    currentMeasurementFieldId() {
      return this.$store.state.currentMeasurementFieldId;
    }
  }
}
</script>