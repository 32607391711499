<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-text>
          <v-row align="center">
            <v-col>
              <div class="d-flex align-center">
                <v-text-field v-model="search" label="Search for organizations" solo hide-details=""></v-text-field>

                <OrganizationDialog mode="add" @success="$store.dispatch('getOrganizations')" />
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-list elevation="2">
                <v-list-item v-for="(organization, index) of filteredOrganizations" :key="index" two-line :to="`/admin/${organization.id}/manage`">
                  <v-list-item-avatar>
                    <v-icon>
                      mdi-account-group-outline
                    </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ organization.name }}
                    </v-list-item-title>

                    <v-list-item-subtitle>
                      {{ organization.email }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-row class="organization-info text--secondary" justify="end">
                      <v-col class="d-flex align-center justify-end">
                        <span>{{ organization.facilityCount }}</span>
                        <v-icon right>
                          mdi-ferry
                        </v-icon>
                      </v-col>
                      <v-col class="d-flex align-center justify-end">
                        <span>{{ organization.userCount }}</span>
                        <v-icon right>
                          mdi-account
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import OrganizationDialog from '@/components/OrganizationDialog.vue'

export default {
  name: 'Organizations',
  components: {
    OrganizationDialog
  },
  data() {
    return {
      filteredOrganizations: [],
      search: ''
    }
  },
  computed: {
    organizations() {
      return this.$store.getters.organizations;
    }
  },
  watch: {
    search: {
      handler(value) {
        if (value) {
          this.filteredOrganizations = this.organizations.filter(item => {
            return item.name.toLowerCase().includes(value.toLowerCase());
          });
        } else {
          this.filteredOrganizations = this.organizations;
        }
      }
    },
    organizations: {
      handler(value) {
        this.filteredOrganizations = value;
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.organization-info {
  margin-right: .5rem;
  min-width: 200px;
}
</style>