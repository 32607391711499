<template>
  <v-card
    class="mx-auto"
    height="235"
    max-width="500"
    elevation="4"
    :loading="loading ? color : false"
    :disabled="disabled"
    style="overflow: hidden;"
  >
    <WidgetBanner
      v-if="bannerLabel"
      :loading="loading"
      :position="'right'"
      :label="bannerLabel"
      :disabled="disabled"
      :value="bannerDisplayValue"
    />
    <v-card-title class="d-flex align-center pb-0">
      <div class="overline mr-1">
        {{ title }}
      </div>
    </v-card-title>
    <v-card-text class="py-1">
      <v-row class="mb-0">
        <v-col>
          <div v-if="speedometer" align="center" class="purple--text'" style="height: 104px; width: 340px">
            <vue-speedometer
              :forceRender="true"
              :width="speedometerWidth"
              :minValue="speedometerMinValue"
              :maxValue="speedometerMaxValue"
              :value="displayValue.int"
              :ringWidth="25"
              :needleHeightRatio="0.8"
              :needleColor="speedometerNeedleColor"
              :endColor="speedometerEndColor"
              :startColor="speedometerStartColor"
              :segments="speedometerSegments"
              :segmentColors="speedometerSegmentColors"
              :customSegmentLabels="speedometerCustomSegmentLabels"
              :customSegmentStops="speedometerCustomSegmentStops"
              :currentValueText="speedometerCurrentValueText"
              :id="id"
            />
          </div>
          <v-sheet
            v-else
            height="96"
            class="d-flex align-center justify-center"
            :class="color+'--text'"
          >
            <v-scroll-y-transition>
              <div v-if="!loading">
                <div v-if="value !== ''">
                  <span class="kpitext text-carbon">{{ displayValue.int }}</span>
                  <span class="kpiunit text-carbon">
                    <span class="text-uppercase" v-if="displayValue.decimal">.{{ displayValue.decimal }}</span>{{ displayValue.unit }}
                  </span>
                </div>
                <h1 v-else>
                  No data
                </h1>
              </div>
            </v-scroll-y-transition>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          class="d-flex align-center"
          style="gap: .5rem;"
        >
          <div class="flex-grow-1">
            <div v-if="text">{{ text }}</div>
            <div v-if="remark">
              <v-divider class="my-1"></v-divider>
              <div class="d-flex align-center">
                <v-icon :color="remarkIconColor">{{ remarkIcon }}</v-icon>
                <span class="ml-1">
                  {{ remark }}
                </span>
                <v-scroll-x-transition>
                  <span
                    v-if="!loading"
                    class="ml-1"
                  >
                    {{ remarkDisplayValue }}
                  </span>
                </v-scroll-x-transition>
              </div>
            </div>
          </div>

          <div v-if="true">
            <ExportShortcut
              :facilityId="$route.params.id"
              :reportCode="code"
            />
          </div>

          <div v-if="information">
            <WidgetInfo :title="title" :content="information" />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions :class="color"></v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import VueSpeedometer from 'vue-speedometer';
import WidgetBanner from '@/components/WidgetBanner.vue';
import * as d3 from 'd3';
import WidgetInfo from '@/components/WidgetInfo.vue';
import ExportShortcut from '@/components/ExportShortcut.vue';

export default {
  name: 'KPIWidget',
  components: {
    VueSpeedometer,
    WidgetBanner,
    WidgetInfo,
    ExportShortcut
  },
  props: {
    id: {
      default: 'widget',
      type: String
    },
    title: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: () => { return false; }
    },
    code: {
      type: String,
      required: true,
      default: ''
    },
    valueKey: {
      type: String,
      default: () => {
        return 'Actual';
      }
    },
    remarkValueKey: {
      type: String,
      default: 'Lifetime'
    },
    bannerValueKey: {
      type: String,
      default: 'FleetActual'
    },
    placeholder: {
      type: String,
      default: 'XX.X'
    },
    color: {
      type: String,
      default: 'secondary'
    },
    kpi: {
      default: 'XX',
      type: String
    },
    kpidecimals: {
      default: 'XX',
      type: String
    },
    kpiunit: {
      default: '%',
      type: String
    },
    bannerLabel: {
      default: '',
      type: String
    },
    text: {
      default: '',
      type: String
    },
    speedometer: {
      type: Boolean,
      default: false
    },
    speedometerValueInMiddle: {
      type: Boolean,
      default: false
    },
    speedometerValueInMiddleUnit: {
      type: Boolean,
      default: false
    },
    speedometerWidth: {
      default: 200,
      type: Number
    },
    speedometerMinValue: {
      default: 0,
      type: Number
    },
    speedometerMaxValue: {
      default: 5,
      type: Number
    },
    speedometerNeedleColor: {
      default: '#9E9E9E',
      type: String
    },
    speedometerStartColor: {
      default: '#3c0b7d',
      type: String
    },
    speedometerEndColor: {
      default: '#926ec2',
      type: String
    },
    speedometerSegments: {
      default: 4,
      type: Number
    },
    speedometerCurrentValueText: {
      default: '${value}',
      type: String
    },
    speedometerSegmentColors: {
      default: () => {
        return [];
      },
      type: Array
    },
    speedometerCustomSegmentLabels: {
      default: () => {
        return [];
      },
      type: Array
    },
    speedometerCustomSegmentStops: {
      default: () => {
        return [];
      },
      type: Array
    },
    speedometerLines: {
      default: () => {
        return [];
      },
      type: Array
    },
    remark: {
      default: '',
      type: String
    },
    remarkIcon: {
      default: 'mdi-information-outline',
      type: String
    },
    remarkIconColor: {
      default: '',
      type: String
    },
    overline: {
      default: '',
      type: String
    },
    iconbackcolor: {
      default: 'info',
      type: String
    },
    icondark: {
      default: true,
      type: Boolean
    },
    icon: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      loading: false,
      value: '',
      remarkValue: '',
      bannerValue: '',
      information: ''
    };
  },
  computed: {
    displayValue() {
      let int = String(this.value).split('.')[0];
      let decimal = String(this.value).split('.')[1];
      let unit = this.kpiunit;

      if (this.kpiunit === 'T') {
        if (this.value < 1.0) {
          const calculatedValue = Number(this.value * 1000).toFixed(0);
          int = String(calculatedValue).split('.')[0];
          decimal = null;
          unit = 'kg';
        }

        else if (this.value >= 100000.0) {
          const calculatedValue = Number(this.value / 1000).toFixed(1);
          int = String(calculatedValue).split('.')[0];
          decimal = String(calculatedValue).split('.')[1];
          unit = 'MT';
        }
      }

      return { int, decimal, unit };
    },
    bannerDisplayValue() {
      if (this.bannerValue === '') return '';

      let value = this.bannerValue;
      let unit = this.kpiunit;

      if (this.kpiunit === 'T') {
        if (this.bannerValue < 1.0) {
          value = Number(this.bannerValue * 1000).toFixed(0);
          unit = 'kg';
        }

        else if (this.bannerValue >= 100000.0) {
          value = Number(this.bannerValue / 1000).toFixed(1);
          unit = 'MT';
        }
      }

      return `${value}${unit}`;
    },
    remarkDisplayValue() {
      if (!this.remarkValue) return 'No data';

      let value = this.remarkValue;
      let unit = this.kpiunit;

      if (this.kpiunit === 'T') {
        if (this.remarkValue < 1.0) {
          value = Number(this.remarkValue * 1000).toFixed(0);
          unit = 'kg';
        }

        else if (this.remarkValue >= 100000.0) {
          value = Number(this.remarkValue / 1000).toFixed(1);
          unit = 'MT';
        }
      }

      return `${value}${unit}`;
    },
    ...mapState([
      'period',
      'filteredFacilities'
    ])
  },
  created: async function () {
    if (this.speedometer) {
      this.speedometerLines.forEach((line) => {
        addLine(this.id, line, this);
      });
    }
    if (this.speedometerValueInMiddle) {
      addValueInMiddle(this.id, this);
    }

    this.fetchData();
  },
  methods: {
    async fetchData() {
      if (this.disabled) return;

      this.loading = true;

      try {
        const response = await this.$Services.reports.getReportValues(this.code, this.period.from, this.period.to, this.$route.params.id);

        this.information = response.information;

        if (response.fields[this.valueKey] && Object.prototype.hasOwnProperty.call(response.fields[this.valueKey], 'value')) {
          this.value = this.$options.filters.formatKPI(
            response.fields[this.valueKey].value,
            response.fields[this.valueKey].format
          );
        } else {
          this.value = '';
        }

        if (response.fields[this.remarkValueKey] && Object.prototype.hasOwnProperty.call(response.fields[this.remarkValueKey], 'value')) {
          this.remarkValue = this.$options.filters.formatKPI(
            response.fields[this.remarkValueKey].value,
            response.fields[this.remarkValueKey].format
          );
        } else {
          this.remarkValue = '';
        }

        if (response.fields[this.bannerValueKey] && Object.prototype.hasOwnProperty.call(response.fields[this.bannerValueKey], 'value')) {
          this.bannerValue = this.$options.filters.formatKPI(
            response.fields[this.bannerValueKey].value,
            response.fields[this.bannerValueKey].format
          );
        } else {
          this.bannerValue = '';
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    getInt(number) {
      if (number) {
        return String(number).split('.')[0];
      }
    },
    getDecimals(number) {
      if (number) {
        return String(number).split('.')[1];
      }
    },
    getUnit(value) {
      if (!value) return this.kpiunit;

      if (this.kpiunit === 'T') {
        if (value < 1.0) {
          return 'kg';
        }

        else if (value >= 100000.0) {
          return 'MT';
        }

        else {
          return this.kpiunit;
        }
      } else {
        return this.kpiunit;
      }
    }
  },
  watch: {
    period(newValue) {
      this.fetchData();
    },
    filteredFacilities() {
      this.fetchData();
    }
  }
};

function addLine(id, line, props) {
  let percent = (line.value / (props.speedometerMaxValue - props.speedometerMinValue)) * 100;
  let c1 = props.speedometerWidth / 2;
  let c2 = props.speedometerWidth / 2;
  let r = props.speedometerWidth / 2 - 20;
  let x1 = c1 - r - 5; //+spWidth);
  let y1 = c2;
  let degrees = percent * 1.8 - 180;

  x1 = c1 + Math.cos((degrees * Math.PI) / 180) * r;
  y1 = c2 + Math.sin((degrees * Math.PI) / 180) * r;

  //adjust start line
  if (props.speedometerValueInMiddle) {
    c2 = c2 - 10;
  }

  d3.select('#' + id + ' svg')
    .append('line')
    .style('stroke', line.color)
    .style('stroke-width', line.thickness)
    .attr('x1', x1)
    .attr('y1', y1)
    .attr('x2', c1)
    .attr('y2', c2);

  y1 -= 20;
  if (degrees < -90) {
    x1 -= 30;
  } else {
    x1 += 0;
  }
  d3.select('#' + id + ' svg')
    .append('text')
    .style('fill', line.color)
    .attr('x', x1)
    .attr('y', y1 + 10)
    .attr('font-weight', 'bold')
    .attr('dy', '.25em')
    .text(line.label);
}

function addValueInMiddle(id, props) {
  if (props.speedometerValueInMiddle) {
    // add speedometer value in the middle
    d3.select('#' + id + ' svg')
      .append('rect')
      .attr('x', 60)
      .attr('y', 78)
      //.style("stroke", 'grey')
      // .style("fill", "none")
      //.style("stroke-width", 2)
      .attr('width', 80)
      .attr('height', 26)
      .attr('fill', 'white');

    var middleValue = props.kpi;
    if (props.speedometerValueInMiddleUnit) {
      middleValue = middleValue + props.kpiunit;
    }

    d3.select('#' + id + ' svg')
      .append('text')
      .attr('class', 'kpispeedo-value')
      .style('font-size', '34px')
      .attr('text-anchor', 'middle')
      .attr('x', 100)
      .attr('y', 100)
      .text(middleValue);
  }
}
</script>

<style scoped>
.kpitext {
  font-size: 6rem;
  /* color: #4892cb; */
  line-height: 1;
  text-transform: uppercase;
}
.kpiunit {
  font-size: 2rem !important;
  /* color: #4892cb; */
}
.kpispeedo-value {
  color: #4892cb;
  font-size: 18px;
}
</style>
