<template>
  <v-container fluid>
    <v-card elevation="2">
      <v-card-title class="text-lg-h4">
        <h4>KPIWidgetPreview</h4>
      </v-card-title>
      <v-card-subtitle class="text-lg-h6">Types of KPI widgets</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col>
            <!-- KPI PREVIEW  -->
            <v-card class="mx-auto" max-width="380" width="380" elevation="4">
              <v-card-title class="mb-0 pb-0">
                <v-icon large color="green">mdi-leaf</v-icon>
                <div class="overline mb-0">Increase in SFOC (total)</div>
                <v-list-item-title align="center" class="green--text">
                  <span class="kpitext text-carbon">20</span>
                  <span class="kpiunit text-carbon">%</span>
                </v-list-item-title>
              </v-card-title>
              <v-card-text class="pb-1">
                <span>
                  Specific fuel oil consumption= Mass of fuel consumed per
                  hour/break power in that particular hour
                </span>
                <span>
                  <v-divider></v-divider>
                  <v-spacer class="mb-1"></v-spacer>
                  10 % up from last time
                </span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <!-- KPI PREVIEW  -->
            <v-card class="mx-auto" max-width="380" width="380" elevation="4">
              <div class="pt-3 " style=" width:100%; position: absolute;" align="ends">
                <div style=" width: 125px;" class="pt-1 pr-1 font-weight-light darken-1 grey white--text float-right text-end" >
                  Fleet average <span class=" font-weight-bold">18%</span>
                </div>
              </div>
              <v-card-title class="mb-0 pb-0">
                <div class="overline mb-0">Increase in SFOC (#1)</div>
                <v-list-item-title align="center" class="green--text">
                  <span class="kpitext text-carbon">20</span>
                  <span class="kpiunit text-carbon">%</span>
                </v-list-item-title>
              </v-card-title>
              <v-card-text class="pb-1">
                <span>
                  Specific fuel oil consumption= Mass of fuel consumed per
                  hour/break power in that particular hour
                </span>
                <span>
                  <v-divider></v-divider>
                  <v-spacer class="mb-1"></v-spacer>
                  10 % up from last time
                </span>
              </v-card-text>
            </v-card>
          </v-col>          
          <v-col>
            <!-- KPI PREVIEW  -->
            <v-card class="mx-auto" max-width="380" width="380" elevation="4">
              <div class="pt-3 " style=" width:100%; position: absolute;" align="ends">
                <div style=" width: 128px;" class="pt-1 pr-1 font-weight-light green white--text float-right text-end rounded-l-xl" >
                  Fleet average <span class=" font-weight-bold">+5%</span>
                </div>
              </div>
              <v-card-title class="mb-0 pb-0">
                <div class="overline mb-0">Reduction of CO2 (#1b)</div>
                <v-list-item-title align="center" class="green--text">
                  <span class="kpitext text-carbon">20</span>
                  <span class="kpiunit text-carbon">%</span>
                </v-list-item-title>
              </v-card-title>
              <v-card-text class="pb-1">
                <span>
                  Specific fuel oil consumption= Mass of fuel consumed per
                  hour/break power in that particular hour
                </span>
                <span>
                  <v-divider></v-divider>
                  <v-spacer class="mb-1"></v-spacer>
                  10 % up from last time
                </span>
              </v-card-text>
            </v-card>
          </v-col>          
          <v-col>
            <!-- KPI PREVIEW  -->
            <v-card class="mx-auto" max-width="380" width="380" elevation="4">
              <div class="pt-3 " style=" width:100%; position: absolute;" align="ends">
                <div style=" width: 95px;" class="pt-1 pr-1 font-weight-light purple white--text float-right text-end" >
                  Fleet average <br>
                  <span class=" font-weight-bold">
                  <v-icon color="white">mdi-arrow-up-thick</v-icon>18%</span>
                </div>
              </div>
              <v-card-title class="mb-0 pb-0">
                <div class="overline mb-0">Increase in SFOC (#2)</div>
                <v-list-item-title align="center" class="purple--text">
                  <span class="kpitext text-carbon">20</span>
                  <span class="kpiunit text-carbon">%</span>
                </v-list-item-title>
              </v-card-title>
              <v-card-text class="pb-1">
                <span>
                  Specific fuel oil consumption= Mass of fuel consumed per
                  hour/break power in that particular hour
                </span>
                <span>
                  <v-divider></v-divider>
                  <v-spacer class="mb-1"></v-spacer>
                  10 % up from last time
                </span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <!-- KPI PREVIEW  -->
            <v-card class="mx-auto" max-width="380" width="380" elevation="4">
              <div class="pt-3  " style=" width:100%; position: absolute;" align="ends">
                <div style=" width: 95px;" class="pt-1 pr-1 font-weight-light green white--text float-right text-end rounded-l-lg" >
                  Fleet average <br>
                  <span class=" font-weight-bold">
                  <v-icon color="white">mdi-arrow-up-thick</v-icon>+5%</span>
                </div>
              </div>
              <v-card-title class="mb-0 pb-0">
                <div class="overline mb-0">Reduction of CO2 (#2b)</div>
                <v-list-item-title align="center" class="purple--text">
                  <span class="kpitext text-carbon">20</span>
                  <span class="kpiunit text-carbon">%</span>
                </v-list-item-title>
              </v-card-title>
              <v-card-text class="pb-1">
                <span>
                  Specific fuel oil consumption= Mass of fuel consumed per
                  hour/break power in that particular hour
                </span>
                <span>
                  <v-divider></v-divider>
                  <v-spacer class="mb-1"></v-spacer>
                  10 % up from last time
                </span>
              </v-card-text>
            </v-card>
          </v-col>          

          <v-col>
            <!-- KPI PREVIEW  -->
            <v-card class="mx-auto" max-width="380" width="380" elevation="4">
              <div style=" width:100%; position: absolute;" align="end">
                <div class="pt-1 pr-1 font-weight-light" >
                  Fleet average <span class="purple--text font-weight-bold">25%</span>
                </div>
              </div>
              <v-card-title class="mb-0 pb-0">
                <div class="overline mb-0">Reduction of CO2 (#3)</div>
                <v-list-item-title align="center" class="purple--text">
                  <span class="kpitext text-carbon">20</span>
                  <span class="kpiunit text-carbon">%</span>
                </v-list-item-title>
              </v-card-title>
              <v-card-text class="pb-1">
                <span>
                  Specific fuel oil consumption= Mass of fuel consumed per
                  hour/break power in that particular hour
                </span>
                <span>
                  <v-divider></v-divider>
                  <v-spacer class="mb-1"></v-spacer>
                  10 % up from last time
                </span>
              </v-card-text>
            </v-card>
          </v-col>          
          <v-col>
            <!-- KPI PREVIEW  -->
            <v-card class="mx-auto" max-width="380" width="380" elevation="4">
              <div style=" width:100%; position: absolute;" align="end">
                <div class="pr-1 font-weight-light" >
                  Fleet average <br><v-icon color="green">mdi-arrow-up-bold-hexagon-outline</v-icon> 
                  <span class="green--text font-weight-bold">+5%</span>
                </div>
              </div>
              <v-card-title class="mb-0 pb-0">
                <div class="overline mb-0">Reduction of CO2 (#4)</div>
                <v-list-item-title align="center" class="purple--text">
                  <span class="kpitext text-carbon">20</span>
                  <span class="kpiunit text-carbon">%</span>
                </v-list-item-title>
              </v-card-title>
              <v-card-text class="pb-1">
                <span>
                  Specific fuel oil consumption= Mass of fuel consumed per
                  hour/break power in that particular hour
                </span>
                <span>
                  <v-divider></v-divider>
                  <v-spacer class="mb-1"></v-spacer>
                  10 % up from last time
                </span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <!-- KPI PREVIEW  -->
            <v-card class="mx-auto" max-width="380" width="380" elevation="4">
              <div style=" width:100%; position: absolute;" align="end">
                <div class="pr-1 font-weight-light">
                  Fleet average <br><v-icon color="red">mdi-arrow-down-bold</v-icon>
                  <span class="red--text font-weight-bold">-5%</span>
                </div>
              </div>
              <v-card-title class="mb-0 pb-0">
                <div class="overline mb-0">Reduction of CO2 (#5)</div>
                <v-list-item-title align="center" class="purple--text">
                  <span class="kpitext text-carbon">20</span>
                  <span class="kpiunit text-carbon">%</span>
                </v-list-item-title>
              </v-card-title>
              <v-card-text class="pb-1">
                <span>
                  Specific fuel oil consumption= Mass of fuel consumed per
                  hour/break power in that particular hour
                </span>
                <span>
                  <v-divider></v-divider>
                  <v-spacer class="mb-1"></v-spacer>
                <v-icon>mdi-hexagon-multiple</v-icon>  Total: 13.234
                </span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <!-- KPI PREVIEW  -->
            <v-card class="mx-auto" max-width="380" width="380" elevation="4">
              <div class="pt-12 " style=" width:100%; position: absolute;" align="ends">
                <div style=" width: 95px;" class="pt-1 pr-1 font-weight-light green--text float-right text-end " >
                  Fleet average <br>
                  <span class=" font-weight-bold">
                  <v-icon color="white">mdi-arrow-up-thick</v-icon>+5%</span>
                </div>
              </div>
              <v-card-title class="mb-0 pb-0">
                <div class="overline mb-0">Reduction of CO2 (#7)</div>
                <v-list-item-title align="center" class="purple--text">
                  <span class="kpitext text-carbon">20</span>
                  <span class="kpiunit text-carbon">%</span>
                </v-list-item-title>
              </v-card-title>
              <v-card-text class="pb-1">
                <span>
                  Specific fuel oil consumption= Mass of fuel consumed per
                  hour/break power in that particular hour
                </span>
                <span>
                  <v-divider></v-divider>
                  <v-spacer class="mb-1"></v-spacer>
                  10 % up from last time
                </span>
              </v-card-text>
            </v-card>
          </v-col>          

        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="ma-6"></v-divider>

    <v-row>
      <v-col>
        <kpi-widget
          kpi="20"
          kpiunit="%"
          overline="Increase in SFOC (total)"
          text="10 % up from last time"
          icondark="false"
          iconbackcolor="warning"
          icon="mdi-alert"
        />
      </v-col>
      <v-col>
        <kpi-widget
          kpi="12"
          kpiunit="kg"
          overline="Reduction of CO2 (total)"
          text="up from 2kg"
          icon="mdi-checkbox-marked"
          iconbackcolor="success"
        />
      </v-col>
      <v-col>
        <kpi-widget
          kpi="3"
          kpiunit="kg"
          overline="Extra Removal of Sulfur (total)"
          remark="Base line is 0,1 % and 0,5% Sulfur"
          icon="mdi-information-outline"
        />
      </v-col>
    </v-row>

    <v-divider class="ma-6"></v-divider>
    <v-row>
      <v-col>
        <v-card class="mx-auto" max-width="380" width="380" elevation="4">
          <v-card-title class="mb-0 pb-0">
            <v-icon x-large color="green">mdi-leaf</v-icon>
            <div class="overline mb-0">Increase in SFOC (total)</div>
            <v-list-item-title align="center" class="green--text">
              <span class="kpitext text-carbon">20</span>
              <span class="kpiunit text-carbon">%</span>
            </v-list-item-title>
          </v-card-title>
          <v-card-text class="pb-1">
            <span>10 % up from last time</span>
            <span>
              <!-- <v-divider></v-divider>
        <v-spacer class="mb-1"></v-spacer>
         10 % up from last time -->
            </span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="mx-auto" max-width="380" width="380" elevation="4">
          <v-card-title class="mb-0 pb-0">
            <v-icon color="">mdi-ferry</v-icon>
            <div class="overline mb-0">Increase in SFOC (total)</div>
            <v-list-item-title align="center" class="green--text">
              <span class="kpitext text-carbon">20</span>
              <span class="kpiunit text-carbon">%</span>
            </v-list-item-title>
          </v-card-title>
          <v-card-text class="pb-1">
            <v-divider></v-divider>

            <span
              ><v-icon color="">mdi-arrow-up-bold</v-icon> 10 % up from last
              time</span
            >
            <span>
              <!-- <v-divider></v-divider>
        <v-spacer class="mb-1"></v-spacer>
         10 % up from last time -->
            </span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="mx-auto" max-width="380" width="380" elevation="4">
          <v-card-title
            class="mb-0 pb-0"
            style="
              text-shadow: -2px -2px 2px #ddd, 1px 1px 1px #ddd;
              position: relative;
              z-index: 100;
            "
          >
            <div class="overline mb-0">Increase in SFOC (leaf)</div>
            <v-list-item-title align="center" class="teal--text">
              <span class="kpitext text-carbon">20</span>
              <span class="kpiunit text-carbon">%</span>
            </v-list-item-title>
          </v-card-title>
          <div align="center" style="position: absolute; top: 0; left: 0">
            <v-icon style="font-size: 170px" color="teal lighten-3"
              >mdi-leaf</v-icon
            >
          </div>
          <v-card-text class="pb-1"> </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="mx-auto" max-width="380" width="380" elevation="4">
          <v-card-title class="mb-0 pb-0">
            <v-icon large color="primary">mdi-flask-outline</v-icon>
            <div class="overline mb-0">Increase in SFOC (total)</div>
            <v-list-item-title align="center" class="primary--text">
              <span class="kpitext text-carbon">20</span>
              <span class="kpiunit text-carbon">%</span>
            </v-list-item-title>
          </v-card-title>
          <v-card-text class="pb-1">
            <span>10 % up from last time</span>
            <span>
              <!-- <v-divider></v-divider>
        <v-spacer class="mb-1"></v-spacer>
         10 % up from last time -->
            </span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-divider class="ma-6"></v-divider>
    <v-row>
      <v-col>
        <kpi-widget
          kpi="20"
          kpiunit="%"
          overline="Increase in SFOC (total)"
          text="10 % up from last time"
          icondark="false"
          iconbackcolor="light-green"
        />
      </v-col>
      <v-col>
        <kpi-widget
          kpi="12"
          kpiunit="kg"
          overline="Reduction of CO2 (total)"
          text="up from 2kg"
          iconbackcolor="teal"
        />
      </v-col>
      <v-col>
        <kpi-widget
          kpi="12"
          kpiunit="kg"
          overline="Reduction of CO2 (total)"
          text="up from 2kg"
          iconbackcolor="purple"
        />
      </v-col>
      <v-col>
        <kpi-widget
          kpi="12"
          kpiunit="kg"
          overline="Reduction of CO2 (total)"
          text="up from 2kg"
        />
      </v-col>
    </v-row>
    <v-divider class="ma-6"></v-divider>
    <v-row>
      <v-col>
        <kpi-widget
          kpi="20"
          kpiunit="%"
          overline="Increase in SFOC (total)"
          text="10 % up from last time"
          remarkicon="mdi-leaf"
          remark="Total CO2 saved"
          iconbackcolor="grey"
        />
      </v-col>
      <v-col>
        <kpi-widget
          kpi="12"
          kpiunit="kg"
          overline="Reduction of CO2 (total)"
          text="up from 2kg"
          remarkicon="mdi-gas-station"
          remark="Total fuel consumption"
          iconbackcolor="primary"
        />
      </v-col>
      <v-col>
        <kpi-widget
          kpi="12"
          kpiunit="kg"
          overline="Reduction of CO2 (total)"
          text="up from 2kg"
          remarkicon="mdi-fuel"
          remark="Base line is 0,1 % and 0,5% Sulfur"
          iconbackcolor="secondary"
        />
      </v-col>
      <v-col>
        <kpi-widget
          kpi="3"
          kpiunit="kg"
          overline="Extra Removal of Sulfur (total)"
          text="up from 2kg"
          remarkicon="mdi-counter"
          remark="Base line is 0,1 % and 0,5% Sulfur"
          iconbackcolor="purple"
        />
      </v-col>
    </v-row>

    <v-divider class="ma-6"></v-divider>
    <h2 class="mb-2">Environmental performance</h2>
    <v-row>
      <v-col cols="3" xs="12" sm="12" md="6" lg="3">
        <kpi-widget
          kpi="20"
          kpiunit="kg"
          overline="Extra removal of Sulfur (period)"
          text="ECA"
          remarkicon="mdi-counter"
          remark="Total: 400 kg "
          iconbackcolor="secondary"
        />
      </v-col>
      <v-col cols="3" xs="12" sm="12" md="6" lg="3">
        <kpi-widget
          kpi="12"
          kpiunit="kg"
          overline="Extra removal of Sulfur (period)"
          text="WORLD"
          remarkicon="mdi-counter"
          remark="Total: 1400 kg "
          iconbackcolor="grey"
        />
      </v-col>
      <v-col cols="3" xs="12" sm="12" md="6" lg="3">
        <kpi-widget
          kpi="12"
          kpiunit="kg"
          overline="Reduction of CO2 (period)"
          text=""
          remarkicon="mdi-counter"
          remark="Total: 123 kg "
          iconbackcolor="success"
        />
      </v-col>
      <v-col cols="3" xs="12" sm="12" md="6" lg="3">
        <kpi-widget
          kpi="145"
          kpiunit=""
          overline="Increase in SFOC (period)"
          text="Specific Fuel Oil Consumption"
          remarkicon="mdi-counter"
          remark="Total: 13.234 "
          iconbackcolor="purple"
        />
      </v-col>
    </v-row>
    <v-spacer class="ma-3"></v-spacer>
  </v-container>
</template>
<style scoped>
.kpitext {
  font-size: 9rem !important;
  /* color: #4892cb; */
  line-height: 0.7;
}
.kpiunit {
  font-size: 2rem !important;
  /* color: #4892cb; */
}
.background1 {
  z-index: 90;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 10 10"><path fill="%232196F3" d="M 5,3L 19,3L 20.7359,6L 20.7304,6C 20.9018,6.29149 21,6.63428 21,7L 21,19C 21,20.1046 20.1046,21 19,21L 5,21C 3.89543,21 3,20.1046 3,19L 3,7C 3,6.638 3.09618,6.29846 3.26437,6L 3.26135,6L 5,3 Z M 5.57294,4L 5,5L 5,5L 19,5L 18.4303,4L 5.57294,4 Z M 7,12L 12,17L 17,12L 14,12L 14,10L 10,10L 10,12L 7,12 Z "></path></svg>');
}
</style>

<script>
import KPIWidget from "../components/KPIWidget.vue";

export default {
  name: "KPIWidgetPreview",
  components: {
    "kpi-widget": KPIWidget,
  },
};
</script>