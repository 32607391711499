<template>
  <v-card>
    <v-card-title>
      Remove ship access
    </v-card-title>

    <v-card-text>
      Are you sure you want to remove your access to <b>{{ ship.name }}</b>?
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn @click="$emit('close')" text>
        No
      </v-btn>

      <v-btn color="warning" :loading="deleting" @click="removeAccess" text>
        Yes, remove access
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'RemoveLinkedFacilityDialog',
  props: {
    toggle: {
      type: Function,
      required: true
    },
    ship: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      deleting: false
    }
  },
  methods: {
    async removeAccess() {
      this.deleting = true;

      try {
        await this.toggle(this.$route.params.organizationId, this.ship.id, false);

        this.$emit('close');
        this.$emit('update');
      } catch (error) {
        console.error(error);
      } finally {
        this.deleting = false;
      }
    }
  }
}
</script>