<template>
  <v-card>
    <v-card-title> {{ id ? "Edit" : "Add" }} user </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col>
            <v-text-field v-model="username" label="Username" hint="Used for login" :rules="nameRules"
              required></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div>
              <v-btn class="mr-4" v-if="id" @click="showNewPassword = !showNewPassword">
                {{ showNewPassword ? "Cancel" : "Set new password" }}
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-slide-y-transition>
          <v-row v-if="showNewPassword || !id">
            <v-col>
              <div class="mb-1">
                The password must meet the following criteria:
              </div>

              <div class="d-flex flex-column mb-4" style="gap: .25rem">
                <div>
                  <v-fade-transition leave-absolute group>
                    <v-icon v-if="newPasswordHasCorrectLength" key="valid" left color="success"> mdi-check-circle-outline
                    </v-icon>
                    <v-icon v-else key="invalid" left> mdi-circle-small </v-icon>
                  </v-fade-transition>

                  Minimum 8 characters
                </div>
                <div>
                  <v-fade-transition leave-absolute group>
                    <v-icon v-if="newPassword.match(/\d/)" key="valid" left color="success"> mdi-check-circle-outline
                    </v-icon>
                    <v-icon v-else key="invalid" left> mdi-circle-small </v-icon>
                  </v-fade-transition>

                  Minimum 1 number
                </div>
                <div>
                  <v-fade-transition leave-absolute group>
                    <v-icon v-if="newPassword.match(/\D/)" key="valid" left color="success"> mdi-check-circle-outline
                    </v-icon>
                    <v-icon v-else key="invalid" left> mdi-circle-small </v-icon>
                  </v-fade-transition>

                  Minimum 1 letter
                </div>
              </div>

              <v-text-field v-model="newPassword" type="password" label="New password"
                :rules="rules.newPassword"></v-text-field>

              <v-text-field v-model="confirmPassword" type="password" label="Confirm password"
                :rules="rules.confirmPassword" autocomplete="new-password"></v-text-field>
            </v-col>
          </v-row>
        </v-slide-y-transition>

        <v-divider class="my-4"></v-divider>

        <v-radio-group v-model="rolesModel">
          <template #label> Assign roles </template>
          <v-radio v-for="role of roles" :key="role" :value="role">
            <template #label>
              <div class="d-flex flex-column ml-4">
                <div>
                  {{ getRole(role).displayName }}
                </div>
                <div class="text-body-2">
                  {{ getRole(role).summary }}
                </div>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-dialog v-model="confirmDialog" max-width="500">
        <template v-slot:activator="{ attrs, on }">
          <v-btn v-show="id" v-bind="attrs" v-on="on" color="warning" :loading="deleting" text>
            <v-icon left> mdi-delete-outline </v-icon>
            Delete
          </v-btn>
        </template>

        <v-card>
          <v-card-title> Delete user? </v-card-title>

          <v-card-text> Are you sure you want to delete this user? </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn @click="confirmDialog = false" text> No </v-btn>

            <v-btn @click="deleteUser" color="warning" text> Yes, delete user </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-spacer></v-spacer>

      <v-btn @click="close" text> Close </v-btn>

      <v-btn v-show="!id" color="primary" :disabled="!valid" :loading="saving" @click="createUser">
        <v-icon left> mdi-plus-circle-outline </v-icon>
        Add
      </v-btn>

      <v-btn v-show="id" color="primary" :disabled="!valid" :loading="saving" @click="updateUser">
        <v-icon left> mdi-content-save-outline </v-icon>
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import randomString from "@/mixins/randomString";
import getRole from "@/mixins";

export default {
  name: "UserDialog",
  mixins: [randomString, getRole],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      confirmDialog: false,
      valid: false,
      id: null,
      username: null,
      newPassword: '',
      confirmPassword: '',
      roles: this.$store.getters.roles,
      saving: false,
      deleting: false,
      showNewPassword: false,
      nameRules: [(value) => !!value || "Username is required"],
      rules: {
        newPassword: [
          v => !!v || 'A new password is required',
          v => v?.length >= 8 || 'Must be minimum 8 characters',
          v => !!v && !!String(v).match(/\d/) || 'Password must contain at least 1 number',
          v => !!v && !!String(v).match(/\D/) || 'Password must contain at least 1 letter',
          v => !!v && !String(v).includes(" ") || 'No spaces allowed'
        ],
        confirmPassword: [
          v => !!v || 'Please confirm by writing your new password again',
          v => !!v && !!this.newPassword && v === this.newPassword || 'Passwords must match'
        ]
      }
    };
  },
  computed: {
    rolesModel: {
      get() {
        return this.user?.roles[0];
      },
      set(value) {
        this.user.roles = [value];
      },
    },
    newPasswordHasCorrectLength() {
      return this.newPassword?.length >= 8;
    }
  },
  methods: {
    close() {
      this.$emit("close");
      this.showNewPassword = false;
      this.newPassword = '';
      this.confirmPassword = '';
    },
    async createUser() {
      if (!this.$refs.form.validate()) return

      try {
        this.saving = true;

        const organizationId = this.$route.params.organizationId;
        const data = {
          username: this.username,
          password: this.newPassword || this.randomString(8),
          roles: [this.rolesModel],
        };

        await this.$Services.users.create(organizationId, data);

        this.$emit("update");
        this.$store.commit("showNotification", {
          text: "User created successfully",
          color: "success",
          icon: "checkbox-marked-circle-outline",
        });
        this.close();
      } catch (error) {
        console.error(error);
        this.$store.dispatch("showDefaultError");
      } finally {
        this.saving = false;
      }
    },
    async updateUser() {
      if (!this.$refs.form.validate()) return

      try {
        this.saving = true;

        const data = {
          username: this.username,
        };

        if (this.newPassword) {
          data.password = this.newPassword;
        }

        data.roles = [this.rolesModel];

        await this.$Services.users.update(this.id, data);

        this.$emit("update");
        this.$store.commit("showNotification", {
          text: "User updated successfully",
          color: "success",
          icon: "checkbox-marked-circle-outline",
        });
        this.close();
      } catch (error) {
        console.error(error);
        this.$store.dispatch("showDefaultError");
      } finally {
        this.saving = false;
      }
    },
    async deleteUser() {
      this.deleting = true;

      try {
        await this.$Services.users.remove(this.id);

        this.$emit("update");
        this.$store.commit("showNotification", {
          text: "User deleted successfully",
          color: "success",
          icon: "checkbox-marked-circle-outline",
        });
        this.close();
      } catch (error) {
        console.error(error);
        this.$store.dispatch("showDefaultError");
      } finally {
        this.deleting = false;
      }
    },
  },
  watch: {
    user: {
      handler(value) {
        if (value) {
          this.id = value.id;
          this.username = value.username;
          this.rolesModel = value.roles?.toString();
        }
      },
      immediate: true,
    },
  },
};
</script>