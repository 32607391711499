<template>
  <div id="wrapper">
    <div id="logo">
      <img src="img/logo.png" width="200" alt="PureteQ" />
    </div>

    <img id="graphic" src="img/pq_element-06.png" alt="PureteQ" />

    <h1>
      PureteQ Pure-SPOT is currently unavailable
    </h1>

    <h3 style="margin-top: 1rem;">
      Please check back later
    </h3>
  </div>
</template>

<script>
export default {
  name: 'NotAvailable',
  mounted() {
    this.$store.commit('setMaintenanceMode', true);

    // Maintenance mode
    setInterval(() => {
      this.$store.dispatch('checkMaintenanceMode');
    }, 300 * 1000);
  }
}
</script>

<style>
html {
  background-color: #e7e8e8;
}

#logo {
  position: absolute;
  top: 2rem;
  left: 2rem;
}

#wrapper {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
}

#graphic {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.5;
}
</style>