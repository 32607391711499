<template>
  <v-container fluid>
    <v-card class="mt-n16">
      <v-toolbar flat>
        <v-spacer></v-spacer>

        <v-fade-transition>
          <FacilityCreateDialog
            v-if="$store.getters.hasAnyRole('SystemAdmin')"
          />
        </v-fade-transition>
      </v-toolbar>
      <v-card-text>
        <v-data-iterator
          :items="facilities"
          item-key="name"
          :items-per-page="15"
        >
          <template #default="{ items }">
            <v-list>
              <v-list-item disabled>
                <v-list-item-avatar></v-list-item-avatar>
                <v-list-item-content>
                  <v-row no-gutters class="text-center">
                    <v-col class="text-left">
                      Ship name
                    </v-col>
                    <v-col>
                      Earliest data
                    </v-col>
                    <v-col>
                      Latest data
                    </v-col>
                    <v-col>
                      Data coverage
                    </v-col>
                    <v-col>
                      Status
                    </v-col>
                  </v-row>
                </v-list-item-content>
                <v-list-item-action>
                  <v-sheet width="160"></v-sheet>
                </v-list-item-action>
                <v-list-item-action v-if="$store.getters.hasAnyRole(['SystemAdmin'])">
                  <v-sheet width="142"></v-sheet>
                </v-list-item-action>
              </v-list-item>
              <v-list-item v-for="(item, index) of items" :key="index" two-line>
                <v-list-item-avatar>
                  <v-icon>
                    mdi-ferry
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-row
                    :set="status = getStatus(item.id)"
                    align="center"
                    no-gutters
                    class="text-center"
                  >
                    <v-col class="text-left">
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        IMO: {{ item.imo || 'Unknown' }}
                      </v-list-item-subtitle>                      
                    </v-col>
                    <v-col>
                      {{ item.dataTimeStampMin | formatDate }}
                    </v-col>
                    <v-col>
                      {{ item.dataTimeStampMax | formatDate }} 
                    </v-col>
                    <v-col>
                      <span v-if="item.dataCoverage !== null">
                        {{ item.dataCoverage }}%
                      </span>
                    </v-col>
                    <v-col>
                      <v-tooltip v-if="status" bottom>
                        <template #activator="{ attrs, on }">
                          <v-chip
                            color="secondary"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-progress-circular class="mr-2" indeterminate size="16" width="2"></v-progress-circular>
                            Processing
                          </v-chip>
                        </template>

                        Started {{ getElapsedTime(status.startedOn) }}
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn color="primary" @click="goTo('files', item)" text>
                    <v-icon left>
                      mdi-file-upload-outline
                    </v-icon>
                    Upload data
                  </v-btn>
                </v-list-item-action>
                <v-list-item-action v-if="$store.getters.hasAnyRole(['SystemAdmin'])">
                  <v-btn color="primary" @click="goTo('config', item)" text>
                    <v-icon left>
                      mdi-settings-outline
                    </v-icon>
                    Configure
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </template>
        </v-data-iterator>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment'
import FacilityCreateDialog from '@/components/FacilityCreateDialog.vue'

export default {
  name: 'Facilities',
  components: {
    FacilityCreateDialog
  },
  computed: {
    facilities() {
      return this.$store.state.filteredFacilities;
    },
    statuses() {
      return this.$store.state.executions;
    }
  },
  methods: {
    goTo(path, facility) {
      this.$store.commit('setCurrentFacility', facility);
      this.$router.push({ path: `${facility.id}/${path}` });
    },
    getStatus(facilityId) {
      const status = this.statuses.find(x => x.facilityId === facilityId);
      return status;
    },
    getElapsedTime(startTime) {
      return moment(startTime).from(moment());
    }
  },
  mounted() {
    this.$store.dispatch('getExecutions');
  }
}
</script>

<style scoped>
.v-list-item:hover {
  background-color: #f0f0f0;
}
</style>