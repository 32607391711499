export default class OrganizationService {
  baseUrl = 'organizations';

  constructor(api, store) {
      this._api = api;
      this._store = store;
  }

  async getAll() {
    const response = await this._api(this.baseUrl);

    return response.data?.list;
  }

  async getById(organizationId) {
      const response = await this._api(`${this.baseUrl}/${organizationId}/organization`);

      if (!response?.data) {
        throw new Error('Ship not found');
      }

      return response.data;
  }

  async getUsers(organizationId) {
    const response = await this._api(`${this.baseUrl}/${organizationId}/users`);

    let users = [];

    users = response.data?.list;

    return users.sort((a, b) => {
      return String(a.username).localeCompare(b.username);
    });
  }

  async link(organizationId, linkKey) {
    const response = await this._api.post(`${this.baseUrl}/${organizationId}/${linkKey}/organization-link`);

    return response.data;
  }

  async unlink(primaryOrganizationId, secondaryOrganizationId) {
    const response = await this._api.delete(`${this.baseUrl}/${primaryOrganizationId}/${secondaryOrganizationId}`);

    return response.data;
  }

  async toggleFacilityAccess(userId, facilityId, value) {
    let response = {};

    if (value === true) {
      response = await this._api.post(`${this.baseUrl}/facilities/${userId}/${facilityId}/user-facility-link`);
    } else {
      response = await this._api.delete(`${this.baseUrl}/facilities/${userId}/${facilityId}/user-facility-link`);
    }

    return response.data;
  }

  async toggleOrganizationFacilityAccess(organizationId, facilityId, value) {
    let response = {};

    if (value === true) {
      response = await this._api.post(`${this.baseUrl}/${organizationId}/${facilityId}/organization-facility-link`);
    } else {
      response = await this._api.delete(`${this.baseUrl}/${organizationId}/${facilityId}/organization-facility-link`);
    }

    return response.data;
  }

  async create(data) {
    // Create a link key (7 characters, letters and numbers)
    data.linkKey = Math.random().toString(16).substr(2, 7);

    const response = this._api.post(this.baseUrl, data);
    return response.data;
  }

  async update(organizationId, data) {
    const response = this._api.put(`${this.baseUrl}/${organizationId}/update-organization`, data);

    return response.data;
  }

  /**
   * Migrate / transfer a facility from one organization to another. The current primary organizations users access is removed.
   * @param {string} organizationId The new primary organization
   * @param {string} facilityId the facility to be moved
   * @returns 
   */
  async migrateFacility(organizationId, facilityId) {
    const response = await this._api.post(`${this.baseUrl}/${organizationId}/${facilityId}/migrate-facility`);

    return response.data;
  }

  /**
   * Check whether a facility has secondary organizations.
   * @param {string} facilityId ID of the facility to check
   * @returns {bool}
   */
  async checkSecondaryOrganizations(facilityId) {
    const response = await this._api(`${this.baseUrl}/facilities/${facilityId}/has-secondary-organizations`);

    return response.data;
  }
}
