<template>
  <div>
    <v-list-item-title>
      <span class="text-subtitle-2">
        Period
      </span>
      <br>
      <span class="font-weight-bold" style="font-size: 1.13rem">
        {{ moment($store.state.period.from).format('YYYY.MM.DD') }} - {{ moment($store.state.period.to).format('YYYY.MM.DD') }}
      </span>
    </v-list-item-title>

    <v-radio-group v-model="periodType" @change="onPeriodTypeChange($event)" column>
      <template>
        <v-radio v-for="(item, i) in dateSearchTerms" :key="i" :label="item.title" :value="item.value"></v-radio>
      </template>
    </v-radio-group>
    <v-menu ref="dialog" v-model="customPeriodModal" :close-on-content-click="false" transition="scale-transition" offset-y nudge-right="30" :return-value.sync="dates" min-width="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field v-model="periodQueryCustomPeriodDatesString" v-show="periodType === 'custom'" label="Choose dates" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
      </template>
      <template>
        <v-date-picker no-title v-model="dates" :min="dates[0]" range>
          <v-btn text @click="customPeriodModal = false">Close</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="secondary" @click="dates = []">Clear</v-btn>
          <v-btn text color="primary" :disabled="dates.length !== 2" @click="saveCustomPeriod(dates)">Set</v-btn>
        </v-date-picker>
      </template>
    </v-menu>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ReportPeriodPicker',
  data() {
    return {
      customPeriodModal: false,
      dates: [],
      periodType: 'lastMonth',
      dateSearchTerms: [
        {
          title: 'Last month',
          value: 'lastMonth'
        },
        // {
        //   title: 'Latest 90 days',
        //   value: 'latest90'
        // },
        {
          title: 'Custom period',
          value: 'custom'
        }
      ]
    };
  },
  computed: {
    periodQueryCustomPeriodDatesString() {
      return this.dates.map(date => moment(date).format('YYYY.MM.DD')).join(' - ');
    }
  },
  methods: {
    saveCustomPeriod(dates) {
      const customPeriod = { from: dates[0], to: dates[1] };

      this.$refs.dialog.save(dates);
      localStorage.setItem('customPeriod', JSON.stringify(customPeriod));
      this.$store.commit('updatePeriod', { from: moment.utc(customPeriod.from).startOf('day'), to: moment.utc(customPeriod.to).endOf('day') });
    },
    onPeriodTypeChange(value) {
      localStorage.setItem('periodType', value);

      switch (value) {
        case 'lastMonth':
          this.$store.commit('updatePeriod', { from: moment.utc().subtract(1, 'month').startOf('month'), to: moment.utc().subtract(1, 'month').endOf('month') });
          break;

        case 'latest90':
          this.$store.commit('updatePeriod', { from: moment.utc().subtract(91, 'days').startOf('day'), to: moment.utc().subtract(1, 'day').endOf('day') });
          break;

        case 'custom':
          if (localStorage.getItem('customPeriod')) {
            const period = JSON.parse(localStorage.getItem('customPeriod'));
            this.dates = Object.keys(period).map(key => period[key]);

            this.$store.commit('updatePeriod', { from: moment.utc(period.from).startOf('day'), to: moment.utc(period.to).endOf('day') });
          }
          break;

        default:
          break;
      }
    }
  },
  mounted() {
    // Check if there is a custom period in localstorage
    const type = localStorage.getItem('periodType');

    if (type) {
      // If there is a type but not one of the options, it might be from an earlier build.
      // We will change it to one we know.
      this.periodType = this.dateSearchTerms.map(item => item.value).includes(type) ? type : this.dateSearchTerms[0].value;

      this.onPeriodTypeChange(this.periodType);
    } else {
      this.onPeriodTypeChange('lastMonth');
    }
  }
};
</script>