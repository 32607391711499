<template>
  <v-menu
    v-model="createMenu"
    v-if="$store.getters.hasAnyRole('SystemAdmin')"
    :close-on-click="false"
    :close-on-content-click="false"
    min-width="500"
    bottom
    left
    offset-y
  >
    <template #activator="{ attrs, on }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="primary"
        text
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        New ship
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <v-form
          ref="createForm"
          v-model="valid"
        >
          <v-select
            v-model="organizationId"
            :items="$store.getters.organizations"
            item-text="name"
            item-value="id"
            :loading="!$store.getters.organizations.length"
            :rules="[ v => !!v || 'Please select an organization' ]"
            label="Organization"
          />
          <v-text-field
            v-model="name"
            label="Name"
            :rules="[ v => !!v || 'Ship name is required' ]"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          @click="clear"
        >
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          :loading="creating"
          :disabled="!valid"
          text
          color="primary"
          @click="create()"
        >
          Create
        </v-btn>
        <v-btn
          :loading="creating"
          :disabled="!valid"
          text
          color="primary"
          @click="create(true)"
        >
          <v-icon left>
            mdi-open-in-app
          </v-icon>
          Create and open
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'FacilityCreateDialog',
  data() {
    return {
      createMenu: false,
      creating: false,
      name: '',
      organizationId: '',
      valid: false
    }
  },
  mounted() {
    this.$store.dispatch('getOrganizations');
  },
  methods: {
    clear() {
      this.organizationId = '';
      this.name = '';
      this.$refs.createForm.resetValidation();
      this.createMenu = false;
    },

    async create(navigateAfterCreate = false) {
      const data = {
        organizationId: this.organizationId,
        name: this.name,
      };

      try {
        this.creating = true;

        const response = await this.$Services.facilities.create(data);

        this.$store.dispatch('showDefaultSuccess');
        this.clear();

        await this.$store.dispatch('getFacilities');

        if (navigateAfterCreate) {
          this.$router.push(`/facilities/${response.id}/config/info`);
        }
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.creating = false;
      }
    }
  }
}
</script>