<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        Select a report
      </v-card-title>

      <v-card-text>
        <v-list>
          <v-list-item dense>
            <v-row class="text--secondary">
              <v-col>
                Name
              </v-col>

              <v-col>
                Target
              </v-col>

              <v-col cols="1">
                Progress
              </v-col>
            </v-row>
          </v-list-item>

          <v-list-item v-for="(report, i) in reports" :key="i" :to="`detail/${report.id}`">
            <v-row>
              <v-col>
                <div>
                  {{ report.name }}
                </div>
              </v-col>

              <v-col align-self="center">
                <div class="text-caption">
                  {{ report.target }}
                </div>
              </v-col>

              <v-col cols="1">
                <v-progress-linear :value="getProgress(report)" :color="getProgress(report) === 100 ? 'success' : 'secondary'" height="100%" rounded>
                  <template v-slot:default>
                    <div :class="getProgress(report) === 100 ? 'white--text' : 'black--text'">
                      {{ report.approvedFieldsCount }} / {{ report.totalFieldsCount }}
                    </div>
                  </template>
                </v-progress-linear>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      reports: []
    }
  },
  created: async function () {
    try {
      this.reports = await this.$store.dispatch('getReports');
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    getRandomInt() {
      const min = Math.ceil(0);
      const max = Math.floor(4);
      return Math.floor(Math.random() * (max - min) + min);
    },
    getProgress(report) {
      return (report.approvedFieldsCount / report.totalFieldsCount) * 100;
    },
    sortByTarget(a, b) {
      if (a.target < b.target) {
        return 1;
      }

      if (a.target > b.target) {
        return -1;
      }

      else {
        return a.name < b.name ? -1 : 1;
      }
    }
  }
}
</script>