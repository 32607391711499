<script>
import { Bar } from "vue-chartjs";

var _canvasContext = null;

export default {
  extends: Bar,
  props: {
    ships: {
      type: Array,
      required: true,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      chart: null
    }
  },
  computed: {
    average() {
      if (!this.ships.length) return;

      const avg = this.ships.map(x => x.value).reduce((a, b) => a + b) / this.ships.length;

      return avg >= 99.5 && avg < 100 ? avg.toFixedNoRound(1) : avg.toFixed(1);
    },
    chartData() {
      return {
        labels: this.ships.map(ship => ship.name),
        datasets: [
          {
            type: 'line',
            label: 'Fleet average',
            // data: Array(this.data.ships.length).fill(this.average),
            data: Array(this.ships.length).fill(this.average),
            borderColor: '#003893',
            pointStyle: 'line',
            pointBorderWidth: 2,
            fill: false,
          },
          {
            label: 'Operational availability',
            color: '#ccc',
            data: this.ships.map(ship => ship.value),
            backgroundColor: '#3074B8'
          }
        ]
      }
    },
    chartOptions() {
      return {
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        title:{
          display: false
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Operational availability %'
            },
            suggestedMin: 90,
            suggestedMax: 100,
            ticks: {
              beginAtZero: false,
              suggestedMax: 100,
              suggestedMin: 90,
              stepSize: 5
            }
          }]
        }
      }
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.chartOptions);
  },
  watch: {
    ships() {
      this.renderChart(this.chartData, this.chartOptions);
    }
  }
}

</script>