<template>
  <div>
    <!-- <v-tabs background-color="primary" dark right>
      <v-tab to="/channel-config/channels">
        Data sources
      </v-tab>

      <v-tab to="/channel-config/list">
        Measurement fields
      </v-tab>

      <v-tab :disabled="!hasChosenField" to="/channel-config/detail">
        Editor
      </v-tab>
    </v-tabs> -->

    <!-- <v-toolbar color="secondary" extended dark>
      <v-btn to="/facilities" exact icon>
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-btn>

      <v-toolbar-title>
        <v-icon left>
          mdi-ferry
        </v-icon>
        {{ facility.name }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-toolbar-items>
        <template v-for="(item, index) of navItems">
          <v-btn :key="index" :to="item.path" text>
            {{ item.name }}
          </v-btn>
        </template>
      </v-toolbar-items>
    </v-toolbar> -->

    <router-view class="mt-n16"></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: null,
      facility: {},
      navItems: [
        {
          name: 'Info',
          path: 'info'
        },
        {
          name: 'Data sources',
          path: 'channels'
        },
        {
          name: 'Calculation queue',
          path: 'calculation-queue'
        },
        {
          name: 'Measurement fields',
          path: 'list'
        }
      ]
    }
  },
  computed: {
    hasChosenField() {
      return !!this.$store.state.currentMeasurementFieldId;
    }
  },
  created() {
    this.facility = this.$store.getters.facilityById(this.$route.params.facilityId);
    this.$store.commit('setCurrentFacility', this.facility);
  }
}
</script>