<template>
  <v-dialog v-model="dialog" width="800" scrollable>
    <template v-slot:activator="{ attrs, on }">
      <v-btn class="text-button" color="primary" v-bind="attrs" v-on="on" text>
        <v-icon left>
          mdi-pencil
        </v-icon>
        Edit
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Edit report
      </v-card-title>

      <v-card-text>
        <div class="my-2" v-if="editor">
          <v-btn :color="editor.isActive('bold') ? 'primary' : 'grey'" @click="editor.chain().focus().toggleBold().run()" text small>
            <v-icon>
              mdi-format-bold
            </v-icon>
          </v-btn>

          <v-btn :color="editor.isActive('italic') ? 'primary' : 'grey'" @click="editor.chain().focus().toggleItalic().run()" text small>
            <v-icon>
              mdi-format-italic
            </v-icon>
          </v-btn>

          <v-btn :color="editor.isActive('underline') ? 'primary' : 'grey'" @click="editor.chain().focus().toggleUnderline().run()" text small>
            <v-icon>
              mdi-format-underline
            </v-icon>
          </v-btn>

          <v-btn :color="editor.isActive('heading', { level: 1 }) ? 'primary' : 'grey'" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" text small>
            <v-icon>
              mdi-format-header-1
            </v-icon>
          </v-btn>

          <v-btn :color="editor.isActive('heading', { level: 2 }) ? 'primary' : 'grey'" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" text small>
            <v-icon>
              mdi-format-header-2
            </v-icon>
          </v-btn>

          <v-btn :color="editor.isActive('heading', { level: 3 }) ? 'primary' : 'grey'" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" text small>
            <v-icon>
              mdi-format-header-3
            </v-icon>
          </v-btn>

          <v-btn :color="editor.isActive('bulletList') ? 'primary' : 'grey'" @click="editor.chain().focus().toggleBulletList().run()" text small>
            <v-icon>
              mdi-format-list-bulleted
            </v-icon>
          </v-btn>

          <v-btn :color="editor.isActive('orderedList') ? 'primary' : 'grey'" @click="editor.chain().focus().toggleOrderedList().run()" text small>
            <v-icon>
              mdi-format-list-numbered
            </v-icon>
          </v-btn>

          <v-btn :color="editor.isActive('horizontalRule') ? 'primary' : 'grey'" @click="editor.chain().focus().setHorizontalRule().run()" text small>
            <v-icon>
              mdi-minus-box-outline
            </v-icon>
          </v-btn>
        </div>

        <v-sheet class="pa-4" outlined rounded="">
          <editor-content :editor="editor" />
        </v-sheet>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn @click="close" text>
          Close
        </v-btn>

        <v-btn color="primary" :loading="saving" @click="update" text>
          <v-icon left>
            mdi-content-save
          </v-icon>
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';

export default {
  name: 'EditReportDialog',
  components: {
    EditorContent
  },
  props: {
    report: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      saving: false,
      editor: null
    }
  },
  methods: {
    close() {
      this.dialog = false;
    },
    async update() {
      this.saving = true;

      try {
        const data = {
          information: this.editor.getHTML()
        };

        await this.$Services.reports.update(this.$route.params.id, data);

        this.$store.dispatch('showDefaultSuccess');
        this.dialog = false;
        this.$emit('update');
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showDefaultError');
      } finally {
        this.saving = false;
      }
    }
  },
  mounted() {
    this.editor = new Editor({
      content: this.report.information,
      autoFocus: 'start',
      extensions: [
        StarterKit,
        Placeholder
      ]
    });
  },
  beforeDestroy() {
    this.editor.destroy();
  }
}
</script>

<style>
/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
  content: 'Give the user some information about this report and its fields...';
  float: left;
  color: #aeb1b4;
  pointer-events: none;
  height: 0;
}
</style>