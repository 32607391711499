export default class ExportService {
  baseUrl = 'algorithm/exports';

  constructor(api) {
    this._api = api;
  }

  async get (data, filename = 'export.xlsx') {
    const response = await this._api({
      url: this.baseUrl,
      method: 'POST',
      responseType: 'blob',
      data
    });

    const blob = response.data;

    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = window.URL.createObjectURL(blob);
      a.download = filename;
      a.target = '_blank';
      a.click();
      a.remove();
      window.URL.revokeObjectURL(this.baseUrl);
    }
  }
}
