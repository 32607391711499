import Vue from 'vue'
import VueRouter from 'vue-router'
import Service from '@/views/Service/Service.vue'
import Login from '../views/Login.vue'
import Fleet from '../views/Fleet.vue'
import Facilities from '../views/Facilities/Facilities.vue'
import FacilityOverview from '../views/Facilities/FacilityOverview.vue'
import FacilityFiles from '../views/Facilities/FacilityFiles.vue'
import Ship from '../views/Ship.vue'
import KPIWidgetPreview from '../views/KPIWidgetPreview.vue'
import Configurator from '../views/Configurator.vue'
import ConfiguratorList from '../views/ConfiguratorList.vue'
import ConfiguratorDetail from '../views/ConfiguratorDetail.vue'
import FacilityConfig from '../views/FacilityConfig/FacilityConfig.vue'
import ChannelList from '../views/FacilityConfig/ChannelList.vue'
import MeasurementFields from '../views/FacilityConfig/MeasurementFields.vue'
import MeasurementFieldDetail from '../views/FacilityConfig/MeasurementFieldDetail.vue'
import FacilityEdit from '../views/FacilityConfig/FacilityEdit.vue'
import CalculationQueue from '../views/FacilityConfig/CalculationQueue.vue'
import Admin from '../views/Admin/Admin.vue'
import Organizations from '../views/Admin/Organizations.vue'
import ShipAccess from '../views/Admin/ShipAccess.vue'
import Messages from '../views/Admin/Messages.vue'
import PureServ from '../views/Service/PureServ.vue'
import Documents from '../views/Service/Documents.vue'
import FullRemoteAccess from '../views/Service/FullRemoteAccess.vue'
import TailorMadeServiceAgreements from '../views/Service/TailorMadeServiceAgreements.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Fleet',
    component: Fleet
  },
  {
    path: '/service',
    name: 'Service',
    component: Service,
    children: [
      {
        path: '',
        redirect: 'pureserv'
      },
      {
        path: 'pureserv',
        component: PureServ
      },
      {
        path: 'full-remote-access',
        component: FullRemoteAccess
      },
      {
        path: 'tailor-made-service-agreements',
        component: TailorMadeServiceAgreements
      },
      {
        path: 'documents',
        component: Documents
      }
    ]
  },
  {
    path: '/facilities',
    name: 'Facilities',
    component: Facilities,
    children: [
      {
        path: '',
        redirect: 'overview'
      },
      {
        path: 'overview',
        name: 'Overview',
        component: FacilityOverview
      },
      {
        path: ':facilityId/files',
        name: 'FacilityFiles',
        component: FacilityFiles
      },
      {
        path: ':facilityId/config',
        name: 'FacilityConfig',
        component: FacilityConfig,
        children: [
          {
            path: '',
            redirect: 'channels'
          },
          {
            path: 'info',
            component: FacilityEdit
          },
          {
            path: 'channels',
            component: ChannelList
          },
          {
            path: 'calculation-queue',
            component: CalculationQueue,
            props: {
              mode: 'measurementField'
            }
          },
          {
            path: 'list',
            component: MeasurementFields
          },
          {
            path: 'detail',
            component: MeasurementFieldDetail
          }
        ]
      }
    ]
  },
  // {
  //   path: '/facility/:id/config',
  //   name: 'FacilityConfig',
  //   component: FacilityConfig,
  //   children: [
  //     {
  //       path: '',
  //       redirect: 'channels'
  //     },
  //     {
  //       path: 'channels',
  //       component: ChannelList
  //     },
  //     {
  //       path: 'list',
  //       component: MeasurementFields
  //     },
  //     {
  //       path: 'detail/:id?',
  //       component: MeasurementFieldDetail
  //     }
  //   ]
  // },
  {
    path: '/report-config',
    name: 'ReportConfigurator',
    component: Configurator,
    children: [
      {
        path: '',
        redirect: 'overview'
      },
      {
        path: 'calculation-queue',
        component: CalculationQueue,
        props: {
          mode: 'reportField'
        }
      },
      {
        path: 'overview',
        component: ConfiguratorList
      },
      {
        path: 'data-export',
        component: () => import('../views/FacilityConfig/DataExport.vue')
      },
      {
        path: 'detail/:id?',
        component: ConfiguratorDetail
      }
    ]
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    children: [
      {
        path: '',
        redirect: 'organizations'
      },
      {
        path: 'messages',
        name: 'Messages',
        component: Messages
      },
      {
        path: 'organizations',
        name: 'Organizations',
        component: Organizations
      },
      {
        path: ':organizationId/manage',
        name: 'ManageOrganization',
        component: ShipAccess
      }
    ]
  },
  {
    path: '/ship/:id',
    name: 'Ship',
    component: Ship
  },
  {
    path: '/KPIWidgetPreview',
    name: 'KPIWidgetPreview',
    component: KPIWidgetPreview
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import('../views/Help.vue')
  },
  {
    path: '/legal',
    name: 'Legal',
    component: () => import('../views/Legal.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router